import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import i18n from '../../i18n';
import { authToken } from '../../reducers/Auth';
import { languages } from '../../utils/utilities';

import { CircularProgress } from '@material-ui/core';
import { clearJourneyAll } from 'utils/utilities'

const Gate = (props) => {
  const params = new URLSearchParams(window.location.search);
  const token = params.get('token') || '';
  const lang = params.get('lang') || 'en-gb';

  const userAuth = async (token) => {
    const res = await props.authToken({ token });
    if (res.success) {
      clearJourneyAll();
      props.history.push('/dashboard');
    } else {
      props.history.push('/login');
    }
  };

  useEffect(() => {
    if (lang) {
      const findLang = languages.find(e => e.key === lang)
      if (!findLang) {
        lang = 'en-gb'
      }
      i18n.changeLanguage(lang);
      localStorage.setItem('lang', lang);
    }
    if (token) {
      userAuth(token);
    } else {
      props.history.push('/Login');
    }
  }, []);

  return (
    <>
      <div
        style={{
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <CircularProgress />
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    Auth: state.Auth
  };
};
export default connect(mapStateToProps, { authToken })(Gate);
