export const countryCallingCode = [
  {
    country: 'Afghanistan',
    calling_code: 93
  },
  {
    country: 'Albania',
    calling_code: 355
  },
  {
    country: 'Algeria',
    calling_code: 213
  },
  {
    country: 'American Samoa',
    calling_code: 1684
  },
  {
    country: 'Andorra',
    calling_code: 376
  },
  {
    country: 'Angola',
    calling_code: 244
  },
  {
    country: 'Anguilla',
    calling_code: 1264
  },
  {
    country: 'Antarctica',
    calling_code: null
  },
  {
    country: 'Antigua and Barbuda',
    calling_code: 1268
  },
  {
    country: 'Argentina',
    calling_code: 54
  },
  {
    country: 'Armenia',
    calling_code: 374
  },
  {
    country: 'Aruba',
    calling_code: 297
  },
  {
    country: 'Australia',
    calling_code: 61
  },
  {
    country: 'Austria',
    calling_code: 43
  },
  {
    country: 'Azerbaijan',
    calling_code: 994
  },
  {
    country: 'Bahamas',
    calling_code: 1242
  },
  {
    country: 'Bahrain',
    calling_code: 973
  },
  {
    country: 'Bangladesh',
    calling_code: 880
  },
  {
    country: 'Barbados',
    calling_code: 1246
  },
  {
    country: 'Belarus',
    calling_code: 375
  },
  {
    country: 'Belgium',
    calling_code: 32
  },
  {
    country: 'Belize',
    calling_code: 501
  },
  {
    country: 'Benin',
    calling_code: 229
  },
  {
    country: 'Bermuda',
    calling_code: 1441
  },
  {
    country: 'Bhutan',
    calling_code: 975
  },
  {
    country: 'Bolivia',
    calling_code: 591
  },
  {
    country: 'Bosnia and Herzegovina',
    calling_code: 387
  },
  {
    country: 'Botswana',
    calling_code: 267
  },
  {
    country: 'Bouvet Island',
    calling_code: null
  },
  {
    country: 'Brazil',
    calling_code: 55
  },
  {
    country: 'British Indian Ocean Territory',
    calling_code: 246
  },
  {
    country: 'Brunei',
    calling_code: null
  },
  {
    country: 'Bulgaria',
    calling_code: 359
  },
  {
    country: 'Burkina Faso',
    calling_code: 226
  },
  {
    country: 'Burundi',
    calling_code: 257
  },
  {
    country: 'Cambodia',
    calling_code: 855
  },
  {
    country: 'Cameroon',
    calling_code: 237
  },
  {
    country: 'Canada',
    calling_code: 1
  },
  {
    country: 'Cape Verde',
    calling_code: null
  },
  {
    country: 'Cayman Islands',
    calling_code: 1345
  },
  {
    country: 'Central African Republic',
    calling_code: 236
  },
  {
    country: 'Chad',
    calling_code: 235
  },
  {
    country: 'Chile',
    calling_code: 56
  },
  {
    country: 'China',
    calling_code: 86
  },
  {
    country: 'Christmas Island',
    calling_code: 61
  },
  {
    country: 'Cocos (Keeling) Islands',
    calling_code: 61
  },
  {
    country: 'Colombia',
    calling_code: 57
  },
  {
    country: 'Comoros',
    calling_code: 269
  },
  {
    country: 'Congo',
    calling_code: 242
  },
  {
    country: 'Cook Islands',
    calling_code: 682
  },
  {
    country: 'Costa Rica',
    calling_code: 506
  },
  {
    country: 'Croatia',
    calling_code: 385
  },
  {
    country: 'Cuba',
    calling_code: 53
  },
  {
    country: 'Cyprus',
    calling_code: 357
  },
  {
    country: 'Czech Republic',
    calling_code: 420
  },
  {
    country: 'Denmark',
    calling_code: 45
  },
  {
    country: 'Djibouti',
    calling_code: 253
  },
  {
    country: 'Dominica',
    calling_code: 1767
  },
  {
    country: 'Dominican Republic',
    calling_code: 1849
  },
  {
    country: 'East Timor',
    calling_code: 670
  },
  {
    country: 'Ecuador',
    calling_code: 593
  },
  {
    country: 'Egypt',
    calling_code: 20
  },
  {
    country: 'El Salvador',
    calling_code: 503
  },
  {
    country: 'England',
    calling_code: null
  },
  {
    country: 'Equatorial Guinea',
    calling_code: 240
  },
  {
    country: 'Eritrea',
    calling_code: 291
  },
  {
    country: 'Estonia',
    calling_code: 372
  },
  {
    country: 'Ethiopia',
    calling_code: 251
  },
  {
    country: 'Falkland Islands',
    calling_code: 500
  },
  {
    country: 'Faroe Islands',
    calling_code: 298
  },
  {
    country: 'Fiji Islands',
    calling_code: null
  },
  {
    country: 'Finland',
    calling_code: 358
  },
  {
    country: 'France',
    calling_code: 33
  },
  {
    country: 'French Guiana',
    calling_code: 594
  },
  {
    country: 'French Polynesia',
    calling_code: 689
  },
  {
    country: 'French Southern territories',
    calling_code: null
  },
  {
    country: 'Gabon',
    calling_code: 241
  },
  {
    country: 'Gambia',
    calling_code: 220
  },
  {
    country: 'Georgia',
    calling_code: 995
  },
  {
    country: 'Germany',
    calling_code: 49
  },
  {
    country: 'Ghana',
    calling_code: 233
  },
  {
    country: 'Gibraltar',
    calling_code: 350
  },
  {
    country: 'Greece',
    calling_code: 30
  },
  {
    country: 'Greenland',
    calling_code: 299
  },
  {
    country: 'Grenada',
    calling_code: 1473
  },
  {
    country: 'Guadeloupe',
    calling_code: 590
  },
  {
    country: 'Guam',
    calling_code: 1671
  },
  {
    country: 'Guatemala',
    calling_code: 502
  },
  {
    country: 'Guinea',
    calling_code: 224
  },
  {
    country: 'Guinea-Bissau',
    calling_code: 245
  },
  {
    country: 'Guyana',
    calling_code: 592
  },
  {
    country: 'Haiti',
    calling_code: 509
  },
  {
    country: 'Heard Island and McDonald Islands',
    calling_code: null
  },
  {
    country: 'Holy See (Vatican City State)',
    calling_code: null
  },
  {
    country: 'Honduras',
    calling_code: 504
  },
  {
    country: 'Hong Kong',
    calling_code: 852
  },
  {
    country: 'Hungary',
    calling_code: 36
  },
  {
    country: 'Iceland',
    calling_code: 354
  },
  {
    country: 'India',
    calling_code: 91
  },
  {
    country: 'Indonesia',
    calling_code: 62
  },
  {
    country: 'Iran',
    calling_code: 98
  },
  {
    country: 'Iraq',
    calling_code: 964
  },
  {
    country: 'Ireland',
    calling_code: 353
  },
  {
    country: 'Israel',
    calling_code: 972
  },
  {
    country: 'Italy',
    calling_code: 39
  },
  {
    country: 'Ivory Coast',
    calling_code: 225
  },
  {
    country: 'Jamaica',
    calling_code: 1876
  },
  {
    country: 'Japan',
    calling_code: 81
  },
  {
    country: 'Jordan',
    calling_code: 962
  },
  {
    country: 'Kazakhstan',
    calling_code: 7
  },
  {
    country: 'Kenya',
    calling_code: 254
  },
  {
    country: 'Kiribati',
    calling_code: 686
  },
  {
    country: 'Kuwait',
    calling_code: 965
  },
  {
    country: 'Kyrgyzstan',
    calling_code: 996
  },
  {
    country: 'Laos',
    calling_code: 856
  },
  {
    country: 'Latvia',
    calling_code: 371
  },
  {
    country: 'Lebanon',
    calling_code: 961
  },
  {
    country: 'Lesotho',
    calling_code: 266
  },
  {
    country: 'Liberia',
    calling_code: 231
  },
  {
    country: 'Libyan Arab Jamahiriya',
    calling_code: null
  },
  {
    country: 'Liechtenstein',
    calling_code: 423
  },
  {
    country: 'Lithuania',
    calling_code: 370
  },
  {
    country: 'Luxembourg',
    calling_code: 352
  },
  {
    country: 'Macao',
    calling_code: null
  },
  {
    country: 'North Macedonia',
    calling_code: 389
  },
  {
    country: 'Madagascar',
    calling_code: 261
  },
  {
    country: 'Malawi',
    calling_code: 265
  },
  {
    country: 'Malaysia',
    calling_code: 60
  },
  {
    country: 'Maldives',
    calling_code: 960
  },
  {
    country: 'Mali',
    calling_code: 223
  },
  {
    country: 'Malta',
    calling_code: 356
  },
  {
    country: 'Marshall Islands',
    calling_code: 692
  },
  {
    country: 'Martinique',
    calling_code: 596
  },
  {
    country: 'Mauritania',
    calling_code: 222
  },
  {
    country: 'Mauritius',
    calling_code: 230
  },
  {
    country: 'Mayotte',
    calling_code: 262
  },
  {
    country: 'Mexico',
    calling_code: 52
  },
  {
    country: 'Micronesia, Federated States of',
    calling_code: 691
  },
  {
    country: 'Moldova',
    calling_code: 373
  },
  {
    country: 'Monaco',
    calling_code: 377
  },
  {
    country: 'Mongolia',
    calling_code: 976
  },
  {
    country: 'Montserrat',
    calling_code: 1664
  },
  {
    country: 'Morocco',
    calling_code: 212
  },
  {
    country: 'Mozambique',
    calling_code: 258
  },
  {
    country: 'Myanmar',
    calling_code: 95
  },
  {
    country: 'Namibia',
    calling_code: 264
  },
  {
    country: 'Nauru',
    calling_code: 674
  },
  {
    country: 'Nepal',
    calling_code: 977
  },
  {
    country: 'Netherlands',
    calling_code: 31
  },
  {
    country: 'Netherlands Antilles',
    calling_code: null
  },
  {
    country: 'New Caledonia',
    calling_code: 687
  },
  {
    country: 'New Zealand',
    calling_code: 64
  },
  {
    country: 'Nicaragua',
    calling_code: 505
  },
  {
    country: 'Niger',
    calling_code: 227
  },
  {
    country: 'Nigeria',
    calling_code: 234
  },
  {
    country: 'Niue',
    calling_code: 683
  },
  {
    country: 'Norfolk Island',
    calling_code: 672
  },
  {
    country: 'North Korea',
    calling_code: null
  },
  {
    country: 'Northern Ireland',
    calling_code: null
  },
  {
    country: 'Northern Mariana Islands',
    calling_code: 1670
  },
  {
    country: 'Norway',
    calling_code: 47
  },
  {
    country: 'Oman',
    calling_code: 968
  },
  {
    country: 'Pakistan',
    calling_code: 92
  },
  {
    country: 'Palau',
    calling_code: 680
  },
  {
    country: 'Palestine',
    calling_code: 970
  },
  {
    country: 'Panama',
    calling_code: 507
  },
  {
    country: 'Papua New Guinea',
    calling_code: 675
  },
  {
    country: 'Paraguay',
    calling_code: 595
  },
  {
    country: 'Peru',
    calling_code: 51
  },
  {
    country: 'Philippines',
    calling_code: 63
  },
  {
    country: 'Pitcairn',
    calling_code: null
  },
  {
    country: 'Poland',
    calling_code: 48
  },
  {
    country: 'Portugal',
    calling_code: 351
  },
  {
    country: 'Puerto Rico',
    calling_code: 1939
  },
  {
    country: 'Qatar',
    calling_code: 974
  },
  {
    country: 'Reunion',
    calling_code: 262
  },
  {
    country: 'Romania',
    calling_code: 40
  },
  {
    country: 'Russian Federation',
    calling_code: 7
  },
  {
    country: 'Rwanda',
    calling_code: 250
  },
  {
    country: 'Saint Helena',
    calling_code: 290
  },
  {
    country: 'Saint Kitts and Nevis',
    calling_code: 1869
  },
  {
    country: 'Saint Lucia',
    calling_code: 1758
  },
  {
    country: 'Saint Pierre and Miquelon',
    calling_code: 508
  },
  {
    country: 'Saint Vincent and the Grenadines',
    calling_code: 1784
  },
  {
    country: 'Samoa',
    calling_code: 685
  },
  {
    country: 'San Marino',
    calling_code: 378
  },
  {
    country: 'Sao Tome and Principe',
    calling_code: 239
  },
  {
    country: 'Saudi Arabia',
    calling_code: 966
  },
  {
    country: 'Scotland',
    calling_code: null
  },
  {
    country: 'Senegal',
    calling_code: 221
  },
  {
    country: 'Seychelles',
    calling_code: 248
  },
  {
    country: 'Sierra Leone',
    calling_code: 232
  },
  {
    country: 'Singapore',
    calling_code: 65
  },
  {
    country: 'Slovakia',
    calling_code: 421
  },
  {
    country: 'Slovenia',
    calling_code: 386
  },
  {
    country: 'Solomon Islands',
    calling_code: 677
  },
  {
    country: 'Somalia',
    calling_code: 252
  },
  {
    country: 'South Africa',
    calling_code: 27
  },
  {
    country: 'South Georgia and the South Sandwich Islands',
    calling_code: 500
  },
  {
    country: 'South Korea',
    calling_code: null
  },
  {
    country: 'South Sudan',
    calling_code: 211
  },
  {
    country: 'Spain',
    calling_code: 34
  },
  {
    country: 'Sri Lanka',
    calling_code: 94
  },
  {
    country: 'Sudan',
    calling_code: 249
  },
  {
    country: 'Suriname',
    calling_code: 597
  },
  {
    country: 'Svalbard and Jan Mayen',
    calling_code: null
  },
  {
    country: 'Swaziland',
    calling_code: 268
  },
  {
    country: 'Sweden',
    calling_code: 46
  },
  {
    country: 'Switzerland',
    calling_code: 41
  },
  {
    country: 'Syria',
    calling_code: 963
  },
  {
    country: 'Tajikistan',
    calling_code: 992
  },
  {
    country: 'Tanzania',
    calling_code: 255
  },
  {
    country: 'Thailand',
    calling_code: 66
  },
  {
    country: 'The Democratic Republic of Congo',
    calling_code: 243
  },
  {
    country: 'Togo',
    calling_code: 228
  },
  {
    country: 'Tokelau',
    calling_code: 690
  },
  {
    country: 'Tonga',
    calling_code: 676
  },
  {
    country: 'Trinidad and Tobago',
    calling_code: 1868
  },
  {
    country: 'Tunisia',
    calling_code: 216
  },
  {
    country: 'Turkey',
    calling_code: 90
  },
  {
    country: 'Turkmenistan',
    calling_code: 993
  },
  {
    country: 'Turks and Caicos Islands',
    calling_code: 1649
  },
  {
    country: 'Tuvalu',
    calling_code: 688
  },
  {
    country: 'Uganda',
    calling_code: 256
  },
  {
    country: 'Ukraine',
    calling_code: 380
  },
  {
    country: 'United Arab Emirates',
    calling_code: 971
  },
  {
    country: 'United Kingdom',
    calling_code: 44
  },
  {
    country: 'United States',
    calling_code: 1
  },
  {
    country: 'United States Minor Outlying Islands',
    calling_code: null
  },
  {
    country: 'Uruguay',
    calling_code: 598
  },
  {
    country: 'Uzbekistan',
    calling_code: 998
  },
  {
    country: 'Vanuatu',
    calling_code: 678
  },
  {
    country: 'Venezuela',
    calling_code: 58
  },
  {
    country: 'Vietnam',
    calling_code: 84
  },
  {
    country: 'Virgin Islands, British',
    calling_code: null
  },
  {
    country: 'Virgin Islands, U.S.',
    calling_code: null
  },
  {
    country: 'Wales',
    calling_code: null
  },
  {
    country: 'Wallis and Futuna',
    calling_code: 681
  },
  {
    country: 'Western Sahara',
    calling_code: null
  },
  {
    country: 'Yemen',
    calling_code: 967
  },
  {
    country: 'Yugoslavia',
    calling_code: null
  },
  {
    country: 'Zambia',
    calling_code: 260
  },
  {
    country: 'Zimbabwe',
    calling_code: 263
  }
];

export const countryCodes = [
  { name: 'Afghanistan', code: 'AF' },
  { name: 'Åland Islands', code: 'AX' },
  { name: 'Albania', code: 'AL' },
  { name: 'Algeria', code: 'DZ' },
  { name: 'American Samoa', code: 'AS' },
  { name: 'AndorrA', code: 'AD' },
  { name: 'Angola', code: 'AO' },
  { name: 'Anguilla', code: 'AI' },
  { name: 'Antarctica', code: 'AQ' },
  { name: 'Antigua and Barbuda', code: 'AG' },
  { name: 'Argentina', code: 'AR' },
  { name: 'Armenia', code: 'AM' },
  { name: 'Aruba', code: 'AW' },
  { name: 'Australia', code: 'AU' },
  { name: 'Austria', code: 'AT' },
  { name: 'Azerbaijan', code: 'AZ' },
  { name: 'Bahamas', code: 'BS' },
  { name: 'Bahrain', code: 'BH' },
  { name: 'Bangladesh', code: 'BD' },
  { name: 'Barbados', code: 'BB' },
  { name: 'Belarus', code: 'BY' },
  { name: 'Belgium', code: 'BE' },
  { name: 'Belize', code: 'BZ' },
  { name: 'Benin', code: 'BJ' },
  { name: 'Bermuda', code: 'BM' },
  { name: 'Bhutan', code: 'BT' },
  { name: 'Bolivia', code: 'BO' },
  { name: 'Bosnia and Herzegovina', code: 'BA' },
  { name: 'Botswana', code: 'BW' },
  { name: 'Bouvet Island', code: 'BV' },
  { name: 'Brazil', code: 'BR' },
  { name: 'British Indian Ocean Territory', code: 'IO' },
  { name: 'Brunei Darussalam', code: 'BN' },
  { name: 'Bulgaria', code: 'BG' },
  { name: 'Burkina Faso', code: 'BF' },
  { name: 'Burundi', code: 'BI' },
  { name: 'Cambodia', code: 'KH' },
  { name: 'Cameroon', code: 'CM' },
  { name: 'Canada', code: 'CA' },
  { name: 'Cape Verde', code: 'CV' },
  { name: 'Cayman Islands', code: 'KY' },
  { name: 'Central African Republic', code: 'CF' },
  { name: 'Chad', code: 'TD' },
  { name: 'Chile', code: 'CL' },
  { name: 'China', code: 'CN' },
  { name: 'Christmas Island', code: 'CX' },
  { name: 'Cocos (Keeling) Islands', code: 'CC' },
  { name: 'Colombia', code: 'CO' },
  { name: 'Comoros', code: 'KM' },
  { name: 'Congo', code: 'CG' },
  { name: 'Congo, The Democratic Republic of the', code: 'CD' },
  { name: 'Cook Islands', code: 'CK' },
  { name: 'Costa Rica', code: 'CR' },
  { name: "Cote D'Ivoire", code: 'CI' },
  { name: 'Croatia', code: 'HR' },
  { name: 'Cuba', code: 'CU' },
  { name: 'Cyprus', code: 'CY' },
  { name: 'Czech Republic', code: 'CZ' },
  { name: 'Denmark', code: 'DK' },
  { name: 'Djibouti', code: 'DJ' },
  { name: 'Dominica', code: 'DM' },
  { name: 'Dominican Republic', code: 'DO' },
  { name: 'Ecuador', code: 'EC' },
  { name: 'Egypt', code: 'EG' },
  { name: 'El Salvador', code: 'SV' },
  { name: 'Equatorial Guinea', code: 'GQ' },
  { name: 'Eritrea', code: 'ER' },
  { name: 'Estonia', code: 'EE' },
  { name: 'Ethiopia', code: 'ET' },
  { name: 'Falkland Islands (Malvinas)', code: 'FK' },
  { name: 'Faroe Islands', code: 'FO' },
  { name: 'Fiji', code: 'FJ' },
  { name: 'Finland', code: 'FI' },
  { name: 'France', code: 'FR' },
  { name: 'French Guiana', code: 'GF' },
  { name: 'French Polynesia', code: 'PF' },
  { name: 'French Southern Territories', code: 'TF' },
  { name: 'Gabon', code: 'GA' },
  { name: 'Gambia', code: 'GM' },
  { name: 'Georgia', code: 'GE' },
  { name: 'Germany', code: 'DE' },
  { name: 'Ghana', code: 'GH' },
  { name: 'Gibraltar', code: 'GI' },
  { name: 'Greece', code: 'GR' },
  { name: 'Greenland', code: 'GL' },
  { name: 'Grenada', code: 'GD' },
  { name: 'Guadeloupe', code: 'GP' },
  { name: 'Guam', code: 'GU' },
  { name: 'Guatemala', code: 'GT' },
  { name: 'Guernsey', code: 'GG' },
  { name: 'Guinea', code: 'GN' },
  { name: 'Guinea-Bissau', code: 'GW' },
  { name: 'Guyana', code: 'GY' },
  { name: 'Haiti', code: 'HT' },
  { name: 'Heard Island and Mcdonald Islands', code: 'HM' },
  { name: 'Holy See (Vatican City State)', code: 'VA' },
  { name: 'Honduras', code: 'HN' },
  { name: 'Hong Kong', code: 'HK' },
  { name: 'Hungary', code: 'HU' },
  { name: 'Iceland', code: 'IS' },
  { name: 'India', code: 'IN' },
  { name: 'Indonesia', code: 'ID' },
  { name: 'Iran, Islamic Republic Of', code: 'IR' },
  { name: 'Iraq', code: 'IQ' },
  { name: 'Ireland', code: 'IE' },
  { name: 'Isle of Man', code: 'IM' },
  { name: 'Israel', code: 'IL' },
  { name: 'Italy', code: 'IT' },
  { name: 'Jamaica', code: 'JM' },
  { name: 'Japan', code: 'JP' },
  { name: 'Jersey', code: 'JE' },
  { name: 'Jordan', code: 'JO' },
  { name: 'Kazakhstan', code: 'KZ' },
  { name: 'Kenya', code: 'KE' },
  { name: 'Kiribati', code: 'KI' },
  { name: "Korea, Democratic People'S Republic of", code: 'KP' },
  { name: 'Korea, Republic of', code: 'KR' },
  { name: 'Kuwait', code: 'KW' },
  { name: 'Kyrgyzstan', code: 'KG' },
  { name: "Lao People'S Democratic Republic", code: 'LA' },
  { name: 'Latvia', code: 'LV' },
  { name: 'Lebanon', code: 'LB' },
  { name: 'Lesotho', code: 'LS' },
  { name: 'Liberia', code: 'LR' },
  { name: 'Libyan Arab Jamahiriya', code: 'LY' },
  { name: 'Liechtenstein', code: 'LI' },
  { name: 'Lithuania', code: 'LT' },
  { name: 'Luxembourg', code: 'LU' },
  { name: 'Macao', code: 'MO' },
  { name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK' },
  { name: 'Madagascar', code: 'MG' },
  { name: 'Malawi', code: 'MW' },
  { name: 'Malaysia', code: 'MY' },
  { name: 'Maldives', code: 'MV' },
  { name: 'Mali', code: 'ML' },
  { name: 'Malta', code: 'MT' },
  { name: 'Marshall Islands', code: 'MH' },
  { name: 'Martinique', code: 'MQ' },
  { name: 'Mauritania', code: 'MR' },
  { name: 'Mauritius', code: 'MU' },
  { name: 'Mayotte', code: 'YT' },
  { name: 'Mexico', code: 'MX' },
  { name: 'Micronesia, Federated States of', code: 'FM' },
  { name: 'Moldova, Republic of', code: 'MD' },
  { name: 'Monaco', code: 'MC' },
  { name: 'Mongolia', code: 'MN' },
  { name: 'Montserrat', code: 'MS' },
  { name: 'Morocco', code: 'MA' },
  { name: 'Mozambique', code: 'MZ' },
  { name: 'Myanmar', code: 'MM' },
  { name: 'Namibia', code: 'NA' },
  { name: 'Nauru', code: 'NR' },
  { name: 'Nepal', code: 'NP' },
  { name: 'Netherlands', code: 'NL' },
  { name: 'Netherlands Antilles', code: 'AN' },
  { name: 'New Caledonia', code: 'NC' },
  { name: 'New Zealand', code: 'NZ' },
  { name: 'Nicaragua', code: 'NI' },
  { name: 'Niger', code: 'NE' },
  { name: 'Nigeria', code: 'NG' },
  { name: 'Niue', code: 'NU' },
  { name: 'Norfolk Island', code: 'NF' },
  { name: 'Northern Mariana Islands', code: 'MP' },
  { name: 'Norway', code: 'NO' },
  { name: 'Oman', code: 'OM' },
  { name: 'Pakistan', code: 'PK' },
  { name: 'Palau', code: 'PW' },
  { name: 'Palestinian Territory, Occupied', code: 'PS' },
  { name: 'Panama', code: 'PA' },
  { name: 'Papua New Guinea', code: 'PG' },
  { name: 'Paraguay', code: 'PY' },
  { name: 'Peru', code: 'PE' },
  { name: 'Philippines', code: 'PH' },
  { name: 'Pitcairn', code: 'PN' },
  { name: 'Poland', code: 'PL' },
  { name: 'Portugal', code: 'PT' },
  { name: 'Puerto Rico', code: 'PR' },
  { name: 'Qatar', code: 'QA' },
  { name: 'Reunion', code: 'RE' },
  { name: 'Romania', code: 'RO' },
  { name: 'Russian Federation', code: 'RU' },
  { name: 'RWANDA', code: 'RW' },
  { name: 'Saint Helena', code: 'SH' },
  { name: 'Saint Kitts and Nevis', code: 'KN' },
  { name: 'Saint Lucia', code: 'LC' },
  { name: 'Saint Pierre and Miquelon', code: 'PM' },
  { name: 'Saint Vincent and the Grenadines', code: 'VC' },
  { name: 'Samoa', code: 'WS' },
  { name: 'San Marino', code: 'SM' },
  { name: 'Sao Tome and Principe', code: 'ST' },
  { name: 'Saudi Arabia', code: 'SA' },
  { name: 'Senegal', code: 'SN' },
  { name: 'Serbia and Montenegro', code: 'CS' },
  { name: 'Seychelles', code: 'SC' },
  { name: 'Sierra Leone', code: 'SL' },
  { name: 'Singapore', code: 'SG' },
  { name: 'Slovakia', code: 'SK' },
  { name: 'Slovenia', code: 'SI' },
  { name: 'Solomon Islands', code: 'SB' },
  { name: 'Somalia', code: 'SO' },
  { name: 'South Africa', code: 'ZA' },
  { name: 'South Georgia and the South Sandwich Islands', code: 'GS' },
  { name: 'Spain', code: 'ES' },
  { name: 'Sri Lanka', code: 'LK' },
  { name: 'Sudan', code: 'SD' },
  { name: 'Suriname', code: 'SR' },
  { name: 'Svalbard and Jan Mayen', code: 'SJ' },
  { name: 'Swaziland', code: 'SZ' },
  { name: 'Sweden', code: 'SE' },
  { name: 'Switzerland', code: 'CH' },
  { name: 'Syrian Arab Republic', code: 'SY' },
  { name: 'Taiwan, Province of China', code: 'TW' },
  { name: 'Tajikistan', code: 'TJ' },
  { name: 'Tanzania, United Republic of', code: 'TZ' },
  { name: 'Thailand', code: 'TH' },
  { name: 'Timor-Leste', code: 'TL' },
  { name: 'Togo', code: 'TG' },
  { name: 'Tokelau', code: 'TK' },
  { name: 'Tonga', code: 'TO' },
  { name: 'Trinidad and Tobago', code: 'TT' },
  { name: 'Tunisia', code: 'TN' },
  { name: 'Turkey', code: 'TR' },
  { name: 'Turkmenistan', code: 'TM' },
  { name: 'Turks and Caicos Islands', code: 'TC' },
  { name: 'Tuvalu', code: 'TV' },
  { name: 'Uganda', code: 'UG' },
  { name: 'Ukraine', code: 'UA' },
  { name: 'United Arab Emirates', code: 'AE' },
  { name: 'United Kingdom', code: 'GB' },
  { name: 'United States', code: 'US' },
  { name: 'United States Minor Outlying Islands', code: 'UM' },
  { name: 'Uruguay', code: 'UY' },
  { name: 'Uzbekistan', code: 'UZ' },
  { name: 'Vanuatu', code: 'VU' },
  { name: 'Venezuela', code: 'VE' },
  { name: 'Viet Nam', code: 'VN' },
  { name: 'Virgin Islands, British', code: 'VG' },
  { name: 'Virgin Islands, U.S.', code: 'VI' },
  { name: 'Wallis and Futuna', code: 'WF' },
  { name: 'Western Sahara', code: 'EH' },
  { name: 'Yemen', code: 'YE' },
  { name: 'Zambia', code: 'ZM' },
  { name: 'Zimbabwe', code: 'ZW' }
];

export const arabicCountries = [
  {
    code: 'AD',
    country: 'أندورا',
    calling_code: '376'
  },
  {
    code: 'AE',
    country: 'الامارات العربية المتحدة',
    calling_code: '971'
  },
  {
    code: 'AF',
    country: 'أفغانستان',
    calling_code: '93'
  },
  {
    code: 'AG',
    country: 'أنتيجوا وبربودا',
    calling_code: '1'
  },
  {
    code: 'AI',
    country: 'أنجويلا',
    calling_code: '1'
  },
  {
    code: 'AL',
    country: 'ألبانيا',
    calling_code: '355'
  },
  {
    code: 'AM',
    country: 'أرمينيا',
    calling_code: '374'
  },
  {
    code: 'AO',
    country: 'أنجولا',
    calling_code: '244'
  },
  {
    code: 'AQ',
    country: 'القطب الجنوبي',
    calling_code: '672'
  },
  {
    code: 'AR',
    country: 'الأرجنتين',
    calling_code: '54'
  },
  {
    code: 'AS',
    country: 'ساموا الأمريكية',
    calling_code: '1'
  },
  {
    code: 'AT',
    country: 'النمسا',
    calling_code: '43'
  },
  {
    code: 'AU',
    country: 'أستراليا',
    calling_code: '61'
  },
  {
    code: 'AW',
    country: 'آروبا',
    calling_code: '297'
  },
  {
    code: 'AX',
    country: 'جزر أولان',
    calling_code: '358'
  },
  {
    code: 'AZ',
    country: 'أذربيجان',
    calling_code: '994'
  },
  {
    code: 'BA',
    country: 'البوسنة والهرسك',
    calling_code: '387'
  },
  {
    code: 'BB',
    country: 'بربادوس',
    calling_code: '1'
  },
  {
    code: 'BD',
    country: 'بنجلاديش',
    calling_code: '880'
  },
  {
    code: 'BE',
    country: 'بلجيكا',
    calling_code: '32'
  },
  {
    code: 'BF',
    country: 'بوركينا فاسو',
    calling_code: '226'
  },
  {
    code: 'BG',
    country: 'بلغاريا',
    calling_code: '359'
  },
  {
    code: 'BH',
    country: 'البحرين',
    calling_code: '973'
  },
  {
    code: 'BI',
    country: 'بوروندي',
    calling_code: '257'
  },
  {
    code: 'BJ',
    country: 'بنين',
    calling_code: '229'
  },
  {
    code: 'BL',
    country: 'سان بارتيلمي',
    calling_code: '590'
  },
  {
    code: 'BM',
    country: 'برمودا',
    calling_code: '1'
  },
  {
    code: 'BN',
    country: 'بروناي',
    calling_code: '673'
  },
  {
    code: 'BO',
    country: 'بوليفيا',
    calling_code: '591'
  },
  {
    code: 'BQ',
    country: 'بونير',
    calling_code: '599'
  },
  {
    code: 'BR',
    country: 'البرازيل',
    calling_code: '55'
  },
  {
    code: 'BS',
    country: 'الباهاما',
    calling_code: '1'
  },
  {
    code: 'BT',
    country: 'بوتان',
    calling_code: '975'
  },
  {
    code: 'BV',
    country: 'جزيرة بوفيه',
    calling_code: '47'
  },
  {
    code: 'BW',
    country: 'بتسوانا',
    calling_code: '267'
  },
  {
    code: 'BY',
    country: 'روسيا البيضاء',
    calling_code: '375'
  },
  {
    code: 'BZ',
    country: 'بليز',
    calling_code: '501'
  },
  {
    code: 'CA',
    country: 'كندا',
    calling_code: '1'
  },
  {
    code: 'CC',
    country: 'جزر كوكوس',
    calling_code: '61'
  },
  {
    code: 'CD',
    country: 'جمهورية الكونغو الديمقراطية',
    calling_code: '243'
  },
  {
    code: 'CF',
    country: 'جمهورية افريقيا الوسطى',
    calling_code: '236'
  },
  {
    code: 'CG',
    country: 'الكونغو - برازافيل',
    calling_code: '242'
  },
  {
    code: 'CH',
    country: 'سويسرا',
    calling_code: '41'
  },
  {
    code: 'CI',
    country: 'ساحل العاج',
    calling_code: '225'
  },
  {
    code: 'CK',
    country: 'جزر كوك',
    calling_code: '682'
  },
  {
    code: 'CL',
    country: 'شيلي',
    calling_code: '56'
  },
  {
    code: 'CM',
    country: 'الكاميرون',
    calling_code: '237'
  },
  {
    code: 'CN',
    country: 'الصين',
    calling_code: '86'
  },
  {
    code: 'CO',
    country: 'كولومبيا',
    calling_code: '57'
  },
  {
    code: 'CR',
    country: 'كوستاريكا',
    calling_code: '506'
  },
  {
    code: 'CU',
    country: 'كوبا',
    calling_code: '53'
  },
  {
    code: 'CV',
    country: 'الرأس الأخضر',
    calling_code: '238'
  },
  {
    code: 'CW',
    country: 'كوراساو',
    calling_code: '599'
  },
  {
    code: 'CX',
    country: 'جزيرة الكريسماس',
    calling_code: '61'
  },
  {
    code: 'CY',
    country: 'قبرص',
    calling_code: '357'
  },
  {
    code: 'CZ',
    country: 'جمهورية التشيك',
    calling_code: '420'
  },
  {
    code: 'DE',
    country: 'ألمانيا',
    calling_code: '49'
  },
  {
    code: 'DJ',
    country: 'جيبوتي',
    calling_code: '253'
  },
  {
    code: 'DK',
    country: 'الدانمرك',
    calling_code: '45'
  },
  {
    code: 'DM',
    country: 'دومينيكا',
    calling_code: '1'
  },
  {
    code: 'DO',
    country: 'جمهورية الدومينيك',
    calling_code: '1'
  },
  {
    code: 'DZ',
    country: 'الجزائر',
    calling_code: '213'
  },
  {
    code: 'EC',
    country: 'الاكوادور',
    calling_code: '593'
  },
  {
    code: 'EE',
    country: 'استونيا',
    calling_code: '372'
  },
  {
    code: 'EG',
    country: 'مصر',
    calling_code: '20'
  },
  {
    code: 'EH',
    country: 'الصحراء الغربية',
    calling_code: '212'
  },
  {
    code: 'ER',
    country: 'اريتريا',
    calling_code: '291'
  },
  {
    code: 'ES',
    country: 'أسبانيا',
    calling_code: '34'
  },
  {
    code: 'ET',
    country: 'اثيوبيا',
    calling_code: '251'
  },
  {
    code: 'FI',
    country: 'فنلندا',
    calling_code: '358'
  },
  {
    code: 'FJ',
    country: 'فيجي',
    calling_code: '679'
  },
  {
    code: 'FK',
    country: 'جزر فوكلاند',
    calling_code: '500'
  },
  {
    code: 'FM',
    country: 'ميكرونيزيا',
    calling_code: '691'
  },
  {
    code: 'FO',
    country: 'جزر فارو',
    calling_code: '298'
  },
  {
    code: 'FR',
    country: 'فرنسا',
    calling_code: '33'
  },
  {
    code: 'GA',
    country: 'الجابون',
    calling_code: '241'
  },
  {
    code: 'GB',
    country: 'المملكة المتحدة',
    calling_code: '44'
  },
  {
    code: 'GD',
    country: 'جرينادا',
    calling_code: '1'
  },
  {
    code: 'GE',
    country: 'جورجيا',
    calling_code: '995'
  },
  {
    code: 'GF',
    country: 'غويانا',
    calling_code: '594'
  },
  {
    code: 'GG',
    country: 'غيرنزي',
    calling_code: '44'
  },
  {
    code: 'GH',
    country: 'غانا',
    calling_code: '233'
  },
  {
    code: 'GI',
    country: 'جبل طارق',
    calling_code: '350'
  },
  {
    code: 'GL',
    country: 'جرينلاند',
    calling_code: '299'
  },
  {
    code: 'GM',
    country: 'غامبيا',
    calling_code: '220'
  },
  {
    code: 'GN',
    country: 'غينيا',
    calling_code: '224'
  },
  {
    code: 'GP',
    country: 'جوادلوب',
    calling_code: '590'
  },
  {
    code: 'GQ',
    country: 'غينيا الاستوائية',
    calling_code: '240'
  },
  {
    code: 'GR',
    country: 'اليونان',
    calling_code: '30'
  },
  {
    code: 'GS',
    country: 'جورجيا الجنوبية وجزر ساندويتش الجنوبية',
    calling_code: '500'
  },
  {
    code: 'GT',
    country: 'جواتيمالا',
    calling_code: '502'
  },
  {
    code: 'GU',
    country: 'جوام',
    calling_code: '1'
  },
  {
    code: 'GW',
    country: 'غينيا بيساو',
    calling_code: '245'
  },
  {
    code: 'GY',
    country: 'غيانا',
    calling_code: '595'
  },
  {
    code: 'HK',
    country: 'هونج كونج الصينية',
    calling_code: '852'
  },
  {
    code: 'HM',
    country: 'جزيرة هيرد وماكدونالد',
    calling_code: ''
  },
  {
    code: 'HN',
    country: 'هندوراس',
    calling_code: '504'
  },
  {
    code: 'HR',
    country: 'كرواتيا',
    calling_code: '385'
  },
  {
    code: 'HT',
    country: 'هايتي',
    calling_code: '509'
  },
  {
    code: 'HU',
    country: 'المجر',
    calling_code: '36'
  },
  {
    code: 'ID',
    country: 'اندونيسيا',
    calling_code: '62'
  },
  {
    code: 'IE',
    country: 'أيرلندا',
    calling_code: '353'
  },
  {
    code: 'IL',
    country: 'اسرائيل',
    calling_code: '972'
  },
  {
    code: 'IM',
    country: 'جزيرة مان',
    calling_code: '44'
  },
  {
    code: 'IN',
    country: 'الهند',
    calling_code: '91'
  },
  {
    code: 'IO',
    country: 'المحيط الهندي البريطاني',
    calling_code: '246'
  },
  {
    code: 'IQ',
    country: 'العراق',
    calling_code: '964'
  },
  {
    code: 'IR',
    country: 'ايران',
    calling_code: '98'
  },
  {
    code: 'IS',
    country: 'أيسلندا',
    calling_code: '354'
  },
  {
    code: 'IT',
    country: 'ايطاليا',
    calling_code: '39'
  },
  {
    code: 'JE',
    country: 'جيرسي',
    calling_code: '44'
  },
  {
    code: 'JM',
    country: 'جامايكا',
    calling_code: '1'
  },
  {
    code: 'JO',
    country: 'الأردن',
    calling_code: '962'
  },
  {
    code: 'JP',
    country: 'اليابان',
    calling_code: '81'
  },
  {
    code: 'KE',
    country: 'كينيا',
    calling_code: '254'
  },
  {
    code: 'KG',
    country: 'قرغيزستان',
    calling_code: '996'
  },
  {
    code: 'KH',
    country: 'كمبوديا',
    calling_code: '855'
  },
  {
    code: 'KI',
    country: 'كيريباتي',
    calling_code: '686'
  },
  {
    code: 'KM',
    country: 'جزر القمر',
    calling_code: '269'
  },
  {
    code: 'KN',
    country: 'سانت كيتس ونيفيس',
    calling_code: '1'
  },
  {
    code: 'KP',
    country: 'كوريا الشمالية',
    calling_code: '850'
  },
  {
    code: 'KR',
    country: 'كوريا الجنوبية',
    calling_code: '82'
  },
  {
    code: 'KW',
    country: 'الكويت',
    calling_code: '965'
  },
  {
    code: 'KY',
    country: 'جزر الكايمن',
    calling_code: '345'
  },
  {
    code: 'KZ',
    country: 'كازاخستان',
    calling_code: '7'
  },
  {
    code: 'LA',
    country: 'لاوس',
    calling_code: '856'
  },
  {
    code: 'LB',
    country: 'لبنان',
    calling_code: '961'
  },
  {
    code: 'LC',
    country: 'سانت لوسيا',
    calling_code: '1'
  },
  {
    code: 'LI',
    country: 'ليختنشتاين',
    calling_code: '423'
  },
  {
    code: 'LK',
    country: 'سريلانكا',
    calling_code: '94'
  },
  {
    code: 'LR',
    country: 'ليبيريا',
    calling_code: '231'
  },
  {
    code: 'LS',
    country: 'ليسوتو',
    calling_code: '266'
  },
  {
    code: 'LT',
    country: 'ليتوانيا',
    calling_code: '370'
  },
  {
    code: 'LU',
    country: 'لوكسمبورج',
    calling_code: '352'
  },
  {
    code: 'LV',
    country: 'لاتفيا',
    calling_code: '371'
  },
  {
    code: 'LY',
    country: 'ليبيا',
    calling_code: '218'
  },
  {
    code: 'MA',
    country: 'المغرب',
    calling_code: '212'
  },
  {
    code: 'MC',
    country: 'موناكو',
    calling_code: '377'
  },
  {
    code: 'MD',
    country: 'مولدافيا',
    calling_code: '373'
  },
  {
    code: 'ME',
    country: 'الجبل الأسود',
    calling_code: '382'
  },
  {
    code: 'MF',
    country: 'سانت مارتين',
    calling_code: '590'
  },
  {
    code: 'MG',
    country: 'مدغشقر',
    calling_code: '261'
  },
  {
    code: 'MH',
    country: 'جزر المارشال',
    calling_code: '692'
  },
  {
    code: 'MK',
    country: 'مقدونيا',
    calling_code: '389'
  },
  {
    code: 'ML',
    country: 'مالي',
    calling_code: '223'
  },
  {
    code: 'MM',
    country: 'ميانمار',
    calling_code: '95'
  },
  {
    code: 'MN',
    country: 'منغوليا',
    calling_code: '976'
  },
  {
    code: 'MO',
    country: 'ماكاو الصينية',
    calling_code: '853'
  },
  {
    code: 'MP',
    country: 'جزر ماريانا الشمالية',
    calling_code: '1'
  },
  {
    code: 'MQ',
    country: 'مارتينيك',
    calling_code: '596'
  },
  {
    code: 'MR',
    country: 'موريتانيا',
    calling_code: '222'
  },
  {
    code: 'MS',
    country: 'مونتسرات',
    calling_code: '1'
  },
  {
    code: 'MT',
    country: 'مالطا',
    calling_code: '356'
  },
  {
    code: 'MU',
    country: 'موريشيوس',
    calling_code: '230'
  },
  {
    code: 'MV',
    country: 'جزر الملديف',
    calling_code: '960'
  },
  {
    code: 'MW',
    country: 'ملاوي',
    calling_code: '265'
  },
  {
    code: 'MX',
    country: 'المكسيك',
    calling_code: '52'
  },
  {
    code: 'MY',
    country: 'ماليزيا',
    calling_code: '60'
  },
  {
    code: 'MZ',
    country: 'موزمبيق',
    calling_code: '258'
  },
  {
    code: 'NA',
    country: 'ناميبيا',
    calling_code: '264'
  },
  {
    code: 'NC',
    country: 'كاليدونيا الجديدة',
    calling_code: '687'
  },
  {
    code: 'NE',
    country: 'النيجر',
    calling_code: '227'
  },
  {
    code: 'NF',
    country: 'جزيرة نورفوك',
    calling_code: '672'
  },
  {
    code: 'NG',
    country: 'نيجيريا',
    calling_code: '234'
  },
  {
    code: 'NI',
    country: 'نيكاراجوا',
    calling_code: '505'
  },
  {
    code: 'NL',
    country: 'هولندا',
    calling_code: '31'
  },
  {
    code: 'NO',
    country: 'النرويج',
    calling_code: '47'
  },
  {
    code: 'NP',
    country: 'نيبال',
    calling_code: '977'
  },
  {
    code: 'NR',
    country: 'نورو',
    calling_code: '674'
  },
  {
    code: 'NU',
    country: 'نيوي',
    calling_code: '683'
  },
  {
    code: 'NZ',
    country: 'نيوزيلاندا',
    calling_code: '64'
  },
  {
    code: 'OM',
    country: 'عمان',
    calling_code: '968'
  },
  {
    code: 'PA',
    country: 'بنما',
    calling_code: '507'
  },
  {
    code: 'PE',
    country: 'بيرو',
    calling_code: '51'
  },
  {
    code: 'PF',
    country: 'بولينيزيا الفرنسية',
    calling_code: '689'
  },
  {
    code: 'PG',
    country: 'بابوا غينيا الجديدة',
    calling_code: '675'
  },
  {
    code: 'PH',
    country: 'الفيلبين',
    calling_code: '63'
  },
  {
    code: 'PK',
    country: 'باكستان',
    calling_code: '92'
  },
  {
    code: 'PL',
    country: 'بولندا',
    calling_code: '48'
  },
  {
    code: 'PM',
    country: 'سانت بيير وميكولون',
    calling_code: '508'
  },
  {
    code: 'PN',
    country: 'بتكايرن',
    calling_code: '872'
  },
  {
    code: 'PR',
    country: 'بورتوريكو',
    calling_code: '1'
  },
  {
    code: 'PS',
    country: 'فلسطين',
    calling_code: '970'
  },
  {
    code: 'PT',
    country: 'البرتغال',
    calling_code: '351'
  },
  {
    code: 'PW',
    country: 'بالاو',
    calling_code: '680'
  },
  {
    code: 'PY',
    country: 'باراجواي',
    calling_code: '595'
  },
  {
    code: 'QA',
    country: 'قطر',
    calling_code: '974'
  },
  {
    code: 'RE',
    country: 'روينيون',
    calling_code: '262'
  },
  {
    code: 'RO',
    country: 'رومانيا',
    calling_code: '40'
  },
  {
    code: 'RS',
    country: 'صربيا',
    calling_code: '381'
  },
  {
    code: 'RU',
    country: 'روسيا',
    calling_code: '7'
  },
  {
    code: 'RW',
    country: 'رواندا',
    calling_code: '250'
  },
  {
    code: 'SA',
    country: 'المملكة العربية السعودية',
    calling_code: '966'
  },
  {
    code: 'SB',
    country: 'جزر سليمان',
    calling_code: '677'
  },
  {
    code: 'SC',
    country: 'سيشل',
    calling_code: '248'
  },
  {
    code: 'SD',
    country: 'السودان',
    calling_code: '249'
  },
  {
    code: 'SE',
    country: 'السويد',
    calling_code: '46'
  },
  {
    code: 'SG',
    country: 'سنغافورة',
    calling_code: '65'
  },
  {
    code: 'SH',
    country: 'سانت هيلنا',
    calling_code: '290'
  },
  {
    code: 'SI',
    country: 'سلوفينيا',
    calling_code: '386'
  },
  {
    code: 'SJ',
    country: 'سفالبارد وجان مايان',
    calling_code: '47'
  },
  {
    code: 'SK',
    country: 'سلوفاكيا',
    calling_code: '421'
  },
  {
    code: 'SL',
    country: 'سيراليون',
    calling_code: '232'
  },
  {
    code: 'SM',
    country: 'سان مارينو',
    calling_code: '378'
  },
  {
    code: 'SN',
    country: 'السنغال',
    calling_code: '221'
  },
  {
    code: 'SO',
    country: 'الصومال',
    calling_code: '252'
  },
  {
    code: 'SR',
    country: 'سورينام',
    calling_code: '597'
  },
  {
    code: 'SS',
    country: 'جنوب السودان',
    calling_code: '211'
  },
  {
    code: 'ST',
    country: 'ساو تومي وبرينسيبي',
    calling_code: '239'
  },
  {
    code: 'SV',
    country: 'السلفادور',
    calling_code: '503'
  },
  {
    code: 'SX',
    country: 'سينت مارتن',
    calling_code: '1'
  },
  {
    code: 'SY',
    country: 'سوريا',
    calling_code: '963'
  },
  {
    code: 'SZ',
    country: 'سوازيلاند',
    calling_code: '268'
  },
  {
    code: 'TC',
    country: 'جزر الترك وجايكوس',
    calling_code: '1'
  },
  {
    code: 'TD',
    country: 'تشاد',
    calling_code: '235'
  },
  {
    code: 'TF',
    country: 'المقاطعات الجنوبية الفرنسية',
    calling_code: '262'
  },
  {
    code: 'TG',
    country: 'توجو',
    calling_code: '228'
  },
  {
    code: 'TH',
    country: 'تايلند',
    calling_code: '66'
  },
  {
    code: 'TJ',
    country: 'طاجكستان',
    calling_code: '992'
  },
  {
    code: 'TK',
    country: 'توكيلو',
    calling_code: '690'
  },
  {
    code: 'TL',
    country: 'تيمور الشرقية',
    calling_code: '670'
  },
  {
    code: 'TM',
    country: 'تركمانستان',
    calling_code: '993'
  },
  {
    code: 'TN',
    country: 'تونس',
    calling_code: '216'
  },
  {
    code: 'TO',
    country: 'تونجا',
    calling_code: '676'
  },
  {
    code: 'TR',
    country: 'تركيا',
    calling_code: '90'
  },
  {
    code: 'TT',
    country: 'ترينيداد وتوباغو',
    calling_code: '1'
  },
  {
    code: 'TV',
    country: 'توفالو',
    calling_code: '688'
  },
  {
    code: 'TW',
    country: 'تايوان',
    calling_code: '886'
  },
  {
    code: 'TZ',
    country: 'تانزانيا',
    calling_code: '255'
  },
  {
    code: 'UA',
    country: 'أوكرانيا',
    calling_code: '380'
  },
  {
    code: 'UG',
    country: 'أوغندا',
    calling_code: '256'
  },
  {
    code: 'UM',
    country: 'جزر الولايات المتحدة البعيدة الصغيرة',
    calling_code: ''
  },
  {
    code: 'US',
    country: 'الولايات المتحدة الأمريكية',
    calling_code: '1'
  },
  {
    code: 'UY',
    country: 'أورجواي',
    calling_code: '598'
  },
  {
    code: 'UZ',
    country: 'أوزبكستان',
    calling_code: '998'
  },
  {
    code: 'VA',
    country: 'الفاتيكان',
    calling_code: '379'
  },
  {
    code: 'VC',
    country: 'سانت فنسنت وغرنادين',
    calling_code: '1'
  },
  {
    code: 'VE',
    country: 'فنزويلا',
    calling_code: '58'
  },
  {
    code: 'VG',
    country: 'جزر فرجين البريطانية',
    calling_code: '1'
  },
  {
    code: 'VI',
    country: 'جزر فرجين الأمريكية',
    calling_code: '1'
  },
  {
    code: 'VN',
    country: 'فيتنام',
    calling_code: '84'
  },
  {
    code: 'VU',
    country: 'فانواتو',
    calling_code: '678'
  },
  {
    code: 'WF',
    country: 'جزر والس وفوتونا',
    calling_code: '681'
  },
  {
    code: 'WS',
    country: 'ساموا',
    calling_code: '685'
  },
  {
    code: 'XK',
    country: 'كوسوفو',
    calling_code: '383'
  },
  {
    code: 'YE',
    country: 'اليمن',
    calling_code: '967'
  },
  {
    code: 'YT',
    country: 'مايوت',
    calling_code: '262'
  },
  {
    code: 'ZA',
    country: 'جمهورية جنوب افريقيا',
    calling_code: '27'
  },
  {
    code: 'ZM',
    country: 'زامبيا',
    calling_code: '260'
  },
  {
    code: 'ZW',
    country: 'زيمبابوي',
    calling_code: '263'
  }
];

export const COUNTRIES = [
  {
    calling_code: 93,
    countryEn: 'Afghanistan',
    countryAr: 'أفغانستان',
    countryJp: 'アフガニスタン',
    countryRu: 'Афганистан',
    countryEs: 'Afganistán',
    countryFa: 'افغانستان',
    countryZh: '阿富汗',
    countrySo: 'ئەفغانستان',
    code: 'AF'
  },
  {
    calling_code: 355,
    countryEn: 'Albania',
    countryAr: 'ألبانيا',
    countryJp: 'アルバニア',
    countryRu: 'Албания',
    countryEs: 'Albania',
    countryFa: 'آلبانی',
    countryZh: '阿尔巴尼亚',
    countrySo: 'ئەڵبانیا',
    code: 'AL'
  },
  {
    calling_code: 213,
    countryEn: 'Algeria',
    countryAr: 'الجزائر',
    countryJp: 'アルジェリア',
    countryRu: 'Алжир',
    countryEs: 'Argelia',
    countryFa: 'الجزایر',
    countryZh: '阿尔及利亚',
    countrySo: 'ئەلجەزیرە',
    code: 'DZ'
  },
  {
    calling_code: 1684,
    countryEn: 'American Samoa',
    countryAr: 'American Samoa',
    countryJp: '米領サモア',
    countryRu: 'Американское Самоа',
    countryEs: 'Samoa Americana',
    countryFa: 'ساموآی آمریکا',
    countryZh: '美属萨摩亚',
    countrySo: 'سامۆای ئەمەریکی',
  },
  {
    calling_code: 376,
    countryEn: 'Andorra',
    countryAr: 'أندورا',
    countryJp: 'アンドラ',
    countryRu: 'Андорра',
    countryEs: 'Andorra',
    countryFa: 'آندورا',
    countryZh: '安道尔',
    countrySo: 'ئەندۆڕا',
    code: 'AD'
  },
  {
    calling_code: 244,
    countryEn: 'Angola',
    countryAr: 'أنجولا',
    countryJp: 'アンゴラ',
    countryRu: 'Ангола',
    countryEs: 'Angola',
    countryFa: 'آنگولا',
    countryZh: '安哥拉',
    countrySo: 'ئەنگۆلا',
    code: 'AO'
  },
  {
    calling_code: 1264,
    countryEn: 'Anguilla',
    countryAr: 'Anguilla',
    countryJp: 'アンギラ',
    countryRu: 'Ангилья',
    countryEs: 'Anguilla',
    countryFa: 'آنگویلا',
    countryZh: '安圭拉',
    countrySo: 'ئەنگولیا',
  },
  {
    calling_code: 1268,
    countryEn: 'Antigua and Barbuda',
    countryAr: 'Antigua and Barbuda',
    countryJp: 'アンティグア・バーブーダ',
    countryRu: 'Антигуа и Барбуда',
    countryEs: 'Antigua y Barbuda',
    countryFa: 'آنتیگوا و باربودا',
    countryZh: '安提瓜和巴布达',
    countrySo: 'ئەنتیگوا و باڕبوودا',
  },
  {
    calling_code: 54,
    countryEn: 'Argentina',
    countryAr: 'الأرجنتين',
    countryJp: 'アルゼンチン',
    countryRu: 'Аргентина',
    countryEs: 'Argentina',
    countryFa: 'آرژانتین',
    countryZh: '阿根廷',
    countrySo: 'ئەژەنتین',
    code: 'AR'
  },
  {
    calling_code: 374,
    countryEn: 'Armenia',
    countryAr: 'أرمينيا',
    countryJp: 'アルメニア',
    countryRu: 'Армения',
    countryEs: 'Armenia',
    countryFa: 'ارمنستان',
    countryZh: '亚美尼亚',
    countrySo: 'ئەڕمەنستان',
    code: 'AM'
  },
  {
    calling_code: 297,
    countryEn: 'Aruba',
    countryAr: 'آروبا',
    countryJp: 'アルバ',
    countryRu: 'Аруба',
    countryEs: 'Aruba',
    countryFa: 'آروبا',
    countryZh: '阿鲁巴',
    countrySo: 'ئارووبا',
    code: 'AW'
  },
  {
    calling_code: 61,
    countryEn: 'Australia',
    countryAr: 'أستراليا',
    countryJp: 'オーストラリア',
    countryRu: 'Австралия',
    countryEs: 'Australia',
    countryFa: 'استرالیا',
    countryZh: '澳大利亚',
    countrySo: 'ئوستڕالیا',
    code: 'AU'
  },
  {
    calling_code: 43,
    countryEn: 'Austria',
    countryAr: 'النمسا',
    countryJp: 'オーストリア',
    countryRu: 'Австрия',
    countryEs: 'Austria',
    countryFa: 'اتریش',
    countryZh: '奥地利',
    countrySo: 'نەمسا',
    code: 'AT'
  },
  {
    calling_code: 994,
    countryEn: 'Azerbaijan',
    countryAr: 'أذربيجان',
    countryJp: 'アゼルバイジャン',
    countryRu: 'Азербайджан',
    countryEs: 'Azerbaiyán',
    countryFa: 'آذربایجان',
    countryZh: '阿塞拜疆',
    countrySo: 'ئازەربایجان',
    code: 'AZ'
  },
  {
    calling_code: 1242,
    countryEn: 'Bahamas',
    countryAr: 'Bahamas',
    countryJp: 'バハマ',
    countryRu: 'Багамские острова',
    countryEs: 'Bahamas',
    countryFa: 'باهاما',
    countryZh: '巴哈马',
    countrySo: 'باهاماس',
  },
  {
    calling_code: 973,
    countryEn: 'Bahrain',
    countryAr: 'البحرين',
    countryJp: 'ハーレーン',
    countryRu: 'Бахрейн',
    countryEs: 'Baréin',
    countryFa: 'بحرین',
    countryZh: '巴林',
    countrySo: 'بەحڕەین',
    code: 'BH'
  },
  {
    calling_code: 880,
    countryEn: 'Bangladesh',
    countryAr: 'بنجلاديش',
    countryJp: 'バングラデシュ',
    countryRu: 'Бангладеш',
    countryEs: 'Bangladesh',
    countryFa: 'بنگلادش',
    countryZh: '孟加拉国',
    countrySo: 'بەنگلادیش',
    code: 'BD'
  },
  {
    calling_code: 1246,
    countryEn: 'Barbados',
    countryAr: 'Barbados',
    countryJp: 'バルバドス',
    countryRu: 'Барбадос',
    countryEs: 'Barbados',
    countryFa: 'باربادوس',
    countryZh: '巴巴多斯',
    countrySo: 'باڕبادۆس',
  },
  {
    calling_code: 375,
    countryEn: 'Belarus',
    countryAr: 'روسيا البيضاء',
    countryJp: 'ベラルーシ',
    countryRu: 'Беларусь',
    countryEs: 'Bielorrusia',
    countryFa: 'بلاروس',
    countryZh: '白俄罗斯',
    countrySo: 'بێلاڕووس',
    code: 'BY'
  },
  {
    calling_code: 32,
    countryEn: 'Belgium',
    countryAr: 'بلجيكا',
    countryJp: 'ベルギー',
    countryRu: 'Бельгия',
    countryEs: 'Bélgica',
    countryFa: 'بلژیک',
    countryZh: '比利时',
    countrySo: 'بەلجیکا',
    code: 'BE'
  },
  {
    calling_code: 501,
    countryEn: 'Belize',
    countryAr: 'بليز',
    countryJp: 'ベリーズ',
    countryRu: 'Белиз',
    countryEs: 'Belice',
    countryFa: 'بلیز',
    countryZh: '伯利兹',
    countrySo: 'بێلیز',
    code: 'BZ'
  },
  {
    calling_code: 229,
    countryEn: 'Benin',
    countryAr: 'بنين',
    countryJp: 'ベナン',
    countryRu: 'Бенин',
    countryEs: 'Benín',
    countryFa: 'بنین',
    countryZh: '贝宁',
    countrySo: 'بێنین',
    code: 'BJ'
  },
  {
    calling_code: 1441,
    countryEn: 'Bermuda',
    countryAr: 'Bermuda',
    countryJp: 'バミューダ',
    countryRu: 'Бермуды',
    countryEs: 'Bermuda',
    countryFa: 'برمودا',
    countryZh: '百慕大',
    countrySo: 'بێرموودا',
  },
  {
    calling_code: 975,
    countryEn: 'Bhutan',
    countryAr: 'بوتان',
    countryJp: 'ブータン',
    countryRu: 'Бутан',
    countryEs: 'Bután',
    countryFa: 'بوتان',
    countryZh: '不丹',
    countrySo: 'بووتان',
    code: 'BT'
  },
  {
    calling_code: 591,
    countryEn: 'Bolivia',
    countryAr: 'بوليفيا',
    countryJp: 'ボリビア',
    countryRu: 'Боливия',
    countryEs: 'Bolivia',
    countryFa: 'بولیوی',
    countryZh: '玻利维亚',
    countrySo: 'بۆلئڤیا',
    code: 'BO'
  },
  {
    calling_code: 387,
    countryEn: 'Bosnia and Herzegovina',
    countryAr: 'البوسنة والهرسك',
    countryJp: 'ボスニア・ヘルツェゴビナ',
    countryRu: 'Босния и Герцеговина',
    countryEs: 'Bosnia y Herzegovina',
    countryFa: 'بوسنی و هرزگوین',
    countryZh: '波斯尼亚和黑塞哥维那',
    countrySo: 'بۆسنیا و هێرزێگۆئین',
    code: 'BA'
  },
  {
    calling_code: 267,
    countryEn: 'Botswana',
    countryAr: 'بتسوانا',
    countryJp: 'ボツワナ',
    countryRu: 'Ботсвана',
    countryEs: 'Botsuana',
    countryFa: 'بوتسوانا',
    countryZh: '博茨瓦纳',
    countrySo: 'بۆتسوانا',
    code: 'BW'
  },
  {
    calling_code: 55,
    countryEn: 'Brazil',
    countryAr: 'البرازيل',
    countryJp: 'ブラジル',
    countryRu: 'Бразилия',
    countryEs: 'Brasil',
    countryFa: 'برزیل',
    countryZh: '巴西',
    countrySo: 'بڕازیل',
    code: 'BR'
  },
  {
    calling_code: 246,
    countryEn: 'British Indian Ocean Territory',
    countryAr: 'المحيط الهندي البريطاني',
    countryJp: 'イギリス領インド洋地域',
    countryRu: 'Британская территория Индийского океана',
    countryEs: 'Terriorio Británico del Océano Índico',
    countryFa: 'قلمروی انگلیسی اقیانوس هند',
    countryZh: '英属印度洋领土',
    countrySo: 'هەرێمی دەریای هیندی بەریتانیا',
    code: 'IO'
  },
  {
    calling_code: 359,
    countryEn: 'Bulgaria',
    countryAr: 'بلغاريا',
    countryJp: 'ブルガリア',
    countryRu: 'Болгария',
    countryEs: 'Bulgaria',
    countryFa: 'بلغارستان',
    countryZh: '保加利亚',
    countrySo: 'بولگاریا',
    code: 'BG'
  },
  {
    calling_code: 226,
    countryEn: 'Burkina Faso',
    countryAr: 'بوركينا فاسو',
    countryJp: 'ブルキナファソ',
    countryRu: 'Буркина-Фасо',
    countryEs: 'Burkina Faso',
    countryFa: 'بورکینافاسو',
    countryZh: '布基纳法索',
    countrySo: 'بوڕکینافاسۆ',
    code: 'BF'
  },
  {
    calling_code: 257,
    countryEn: 'Burundi',
    countryAr: 'بوروندي',
    countryJp: 'ブルンジ',
    countryRu: 'Бурунди',
    countryEs: 'Burundi',
    countryFa: 'بوروندی',
    countryZh: '布隆迪',
    countrySo: 'بڕۆندی',
    code: 'BI'
  },
  {
    calling_code: 855,
    countryEn: 'Cambodia',
    countryAr: 'كمبوديا',
    countryJp: 'カンボジア',
    countryRu: 'Камбоджа',
    countryEs: 'Camboya',
    countryFa: 'کامبوج',
    countryZh: '柬埔寨',
    countrySo: 'کامبوجیا',
    code: 'KH'
  },
  {
    calling_code: 237,
    countryEn: 'Cameroon',
    countryAr: 'الكاميرون',
    countryJp: 'カメルーン',
    countryRu: 'Камерун',
    countryEs: 'Camerún',
    countryFa: 'کامرون',
    countryZh: '喀麦隆',
    countrySo: 'کامێرۆن',
    code: 'CM'
  },
  {
    calling_code: 1,
    countryEn: 'Canada',
    countryAr: 'أنتيجوا وبربودا',
    countryJp: 'カナダ',
    countryRu: 'Канада',
    countryEs: 'Canadá',
    countryFa: 'کانادا',
    countryZh: '加拿大',
    countrySo: 'کەنەدا',
    code: 'AG'
  },
  {
    calling_code: 1345,
    countryEn: 'Cayman Islands',
    countryAr: 'Cayman Islands',
    countryJp: 'ケイマン諸島',
    countryRu: 'Каймановы острова',
    countryEs: 'Islas Caimán',
    countryFa: 'جزایر کیمن',
    countryZh: '开曼群岛',
    countrySo: 'دوورگەکانی کایمان',
  },
  {
    calling_code: 236,
    countryEn: 'Central African Republic',
    countryAr: 'جمهورية افريقيا الوسطى',
    countryJp: '中央アフリカ共和国',
    countryRu: 'Центрально-Африканская Республика',
    countryEs: 'República Centroafricana',
    countryFa: 'جمهوری آفریقای مرکزی',
    countryZh: '中非共和国',
    countrySo: 'کۆماری ئەفریکای ناوەڕاست',
    code: 'CF'
  },
  {
    calling_code: 235,
    countryEn: 'Chad',
    countryAr: 'تشاد',
    countryJp: 'チャド',
    countryRu: 'Чад',
    countryEs: 'Chad',
    countryFa: 'چاد',
    countryZh: '乍得',
    countrySo: 'چاد',
    code: 'TD'
  },
  {
    calling_code: 56,
    countryEn: 'Chile',
    countryAr: 'شيلي',
    countryJp: 'チリ',
    countryRu: 'Чили',
    countryEs: 'Chile',
    countryFa: 'شیلی',
    countryZh: '智利',
    countrySo: 'شیلی',
    code: 'CL'
  },
  {
    calling_code: 86,
    countryEn: 'China',
    countryAr: 'الصين',
    countryJp: '中国',
    countryRu: 'Китай',
    countryEs: 'China',
    countryFa: 'چین',
    countryZh: '中国',
    countrySo: 'چین',
    code: 'CN'
  },
  {
    calling_code: 61,
    countryEn: 'Christmas Island',
    countryAr: 'أستراليا',
    countryJp: 'クリスマス島',
    countryRu: 'Остров Рождества',
    countryEs: 'Isla de Navidad',
    countryFa: 'جزیره کریسمس',
    countryZh: '圣诞岛',
    countrySo: 'دوورگەی کریسماس',
    code: 'AU'
  },
  {
    calling_code: 61,
    countryEn: 'Cocos (Keeling) Islands',
    countryAr: 'أستراليا',
    countryJp: 'ココス島(キーリング島)',
    countryRu: 'Кокосовые острова (Килинг)',
    countryEs: 'Islas Cocos (Keeling)',
    countryFa: 'جزایر کوکوس (کیلینگ)',
    countryZh: '科科斯群岛',
    countrySo: 'دوورگەکانی کۆکۆس (کێلینگ)',
    code: 'AU'
  },
  {
    calling_code: 57,
    countryEn: 'Colombia',
    countryAr: 'كولومبيا',
    countryJp: 'コロンビア',
    countryRu: 'Колумбия',
    countryEs: 'Colombia',
    countryFa: 'کلمبیا',
    countryZh: '哥伦比亚',
    countrySo: 'کۆلۆمبیا',
    code: 'CO'
  },
  {
    calling_code: 269,
    countryEn: 'Comoros',
    countryAr: 'جزر القمر',
    countryJp: 'コモロ',
    countryRu: 'Коморские острова',
    countryEs: 'Comoras',
    countryFa: 'کومور',
    countryZh: '科摩罗',
    countrySo: 'کۆمۆرۆس',
    code: 'KM'
  },
  {
    calling_code: 242,
    countryEn: 'Congo',
    countryAr: 'الكونغو - برازافيل',
    countryJp: 'コンゴ共和国',
    countryRu: 'Конго',
    countryEs: 'Congo',
    countryFa: 'کنگو',
    countryZh: '刚果',
    countrySo: 'کۆنگۆ',
    code: 'CG'
  },
  {
    calling_code: 682,
    countryEn: 'Cook Islands',
    countryAr: 'جزر كوك',
    countryJp: 'クック諸島',
    countryRu: 'Острова Кука',
    countryEs: 'Islas Cook',
    countryFa: 'جزایر کوک',
    countryZh: '库克群岛',
    countrySo: 'دوورگەکانی کووک',
    code: 'CK'
  },
  {
    calling_code: 506,
    countryEn: 'Costa Rica',
    countryAr: 'كوستاريكا',
    countryJp: 'コスタリカ',
    countryRu: 'Коста-Рика',
    countryEs: 'Costa Rica',
    countryFa: 'کاستاریکا',
    countryZh: '哥斯达黎加',
    countrySo: 'کۆستاڕیکا',
    code: 'CR'
  },
  {
    calling_code: 385,
    countryEn: 'Croatia',
    countryAr: 'كرواتيا',
    countryJp: 'クロアチア',
    countryRu: 'Хорватия',
    countryEs: 'Croacia',
    countryFa: 'کرواسی',
    countryZh: '克罗地亚',
    countrySo: 'کڕۆواتیا',
    code: 'HR'
  },
  {
    calling_code: 53,
    countryEn: 'Cuba',
    countryAr: 'كوبا',
    countryJp: 'キューバ',
    countryRu: 'Куба',
    countryEs: 'Cuba',
    countryFa: 'کوبا',
    countryZh: '古巴',
    countrySo: 'کوبا',
    code: 'CU'
  },
  {
    calling_code: 357,
    countryEn: 'Cyprus',
    countryAr: 'قبرص',
    countryJp: 'キプロス',
    countryRu: 'Кипр',
    countryEs: 'Chipre',
    countryFa: 'قبرس',
    countryZh: '塞浦路斯',
    countrySo: 'قوبرس',
    code: 'CY'
  },
  {
    calling_code: 420,
    countryEn: 'Czech Republic',
    countryAr: 'جمهورية التشيك',
    countryJp: 'チェコ共和国',
    countryRu: 'Республика Чехия',
    countryEs: 'República Checa',
    countryFa: 'جمهوری چک',
    countryZh: '捷克共和国',
    countrySo: 'کۆماری چیک',
    code: 'CZ'
  },
  {
    calling_code: 45,
    countryEn: 'Denmark',
    countryAr: 'الدانمرك',
    countryJp: 'デンマーク',
    countryRu: 'Дания',
    countryEs: 'Dinamarca',
    countryFa: 'دانمارک',
    countryZh: '丹麦',
    countrySo: 'دانیمارک',
    code: 'DK'
  },
  {
    calling_code: 253,
    countryEn: 'Djibouti',
    countryAr: 'جيبوتي',
    countryJp: 'ジブチ',
    countryRu: 'Джибути',
    countryEs: 'Yibuti',
    countryFa: 'جیبوتی',
    countryZh: '吉布提',
    countrySo: 'جیبووتی',
    code: 'DJ'
  },
  {
    calling_code: 1767,
    countryEn: 'Dominica',
    countryAr: 'Dominica',
    countryJp: 'ドミニカ国',
    countryRu: 'Доминика',
    countryEs: 'Dominica',
    countryFa: 'دومینیکا',
    countryZh: '多米尼加',
    countrySo: 'دۆمینیکا',
  },
  {
    calling_code: 1849,
    countryEn: 'Dominican Republic',
    countryAr: 'Dominican Republic',
    countryJp: 'ドミニカ共和国',
    countryRu: 'Доминиканская Республика',
    countryEs: 'República Dominicana',
    countryFa: 'جمهوری دومینیکن',
    countryZh: '多米尼加共和国',
    countrySo: 'کۆماری دۆمینیکا',
  },
  {
    calling_code: 670,
    countryEn: 'East Timor',
    countryAr: 'تيمور الشرقية',
    countryJp: '東ティモール',
    countryRu: 'Восточный Тимор',
    countryEs: 'Timor Oriental',
    countryFa: 'تیمور شرقی',
    countryZh: '东帝汶',
    countrySo: 'تەیمووری ڕۆژهەڵات',
    code: 'TL'
  },
  {
    calling_code: 593,
    countryEn: 'Ecuador',
    countryAr: 'الاكوادور',
    countryJp: 'エクアドル',
    countryRu: 'Эквадор',
    countryEs: 'Ecuador',
    countryFa: 'اکوادور',
    countryZh: '厄瓜多尔',
    countrySo: 'ئێکوادۆر',
    code: 'EC'
  },
  {
    calling_code: 20,
    countryEn: 'Egypt',
    countryAr: 'مصر',
    countryJp: 'エジプト',
    countryRu: 'Египет',
    countryEs: 'Egipto',
    countryFa: 'مصر',
    countryZh: '埃及',
    countrySo: 'میسر',
    code: 'EG'
  },
  {
    calling_code: 503,
    countryEn: 'El Salvador',
    countryAr: 'السلفادور',
    countryJp: 'エルサルバドル',
    countryRu: 'Сальвадор',
    countryEs: 'El Salvador',
    countryFa: 'السالوادور',
    countryZh: '萨尔瓦多',
    countrySo: 'ئێل سالوادۆر',
    code: 'SV'
  },
  {
    calling_code: 240,
    countryEn: 'Equatorial Guinea',
    countryAr: 'غينيا الاستوائية',
    countryJp: '赤道ギニア',
    countryRu: 'Экваториальная Гвинея',
    countryEs: 'Guinea Ecuatorial',
    countryFa: 'گینه استوایی',
    countryZh: '赤道几内亚',
    countrySo: 'گینەی ئوستوواییی',
    code: 'GQ'
  },
  {
    calling_code: 291,
    countryEn: 'Eritrea',
    countryAr: 'اريتريا',
    countryJp: 'エリトリア',
    countryRu: 'Эритрея',
    countryEs: 'Eritrea',
    countryFa: 'اریتره',
    countryZh: '厄立特里亚',
    countrySo: 'ئێریترا',
    code: 'ER'
  },
  {
    calling_code: 372,
    countryEn: 'Estonia',
    countryAr: 'استونيا',
    countryJp: 'エストニア',
    countryRu: 'Эстония',
    countryEs: 'Estonia',
    countryFa: 'استونی',
    countryZh: '爱沙尼亚',
    countrySo: 'ئەستۆنیا',
    code: 'EE'
  },
  {
    calling_code: 251,
    countryEn: 'Ethiopia',
    countryAr: 'اثيوبيا',
    countryJp: 'エチオピア',
    countryRu: 'Эфиопия',
    countryEs: 'Etiopía',
    countryFa: 'اتیوپی',
    countryZh: '埃塞俄比亚',
    countrySo: 'ئەسیۆپیا',
    code: 'ET'
  },
  {
    calling_code: 500,
    countryEn: 'Falkland Islands',
    countryAr: 'جزر فوكلاند',
    countryJp: 'フォークランド諸島',
    countryRu: 'Фолклендские острова',
    countryEs: 'Islas Malvinas (Falkland)',
    countryFa: 'جزایر فالکلند',
    countryZh: '福克兰群岛',
    countrySo: 'دوورگەکانی فاڵکلەند',
    code: 'FK'
  },
  {
    calling_code: 298,
    countryEn: 'Faroe Islands',
    countryAr: 'جزر فارو',
    countryJp: 'フェロー諸島',
    countryRu: 'Фарерские острова',
    countryEs: 'Islas Feroe',
    countryFa: 'جزایر فارو',
    countryZh: '法罗群岛',
    countrySo: 'دوورگەکانی فاڕۆی',
    code: 'FO'
  },
  {
    calling_code: 358,
    countryEn: 'Finland',
    countryAr: 'جزر أولان',
    countryJp: 'フィンランド',
    countryRu: 'Финляндия',
    countryEs: 'Finlandia',
    countryFa: 'فنلاند',
    countryZh: '芬兰',
    countrySo: 'فەنلاند',
    code: 'AX'
  },
  {
    calling_code: 33,
    countryEn: 'France',
    countryAr: 'فرنسا',
    countryJp: 'フランス',
    countryRu: 'Франция',
    countryEs: 'Francia',
    countryFa: 'فرانسه',
    countryZh: '法国',
    countrySo: 'فەڕانسە',
    code: 'FR'
  },
  {
    calling_code: 594,
    countryEn: 'French Guiana',
    countryAr: 'غويانا',
    countryJp: '仏領ギアナ',
    countryRu: 'Французская Гвиана',
    countryEs: 'Guyana Francesa',
    countryFa: 'گویان فرانسه',
    countryZh: '法属圭亚那',
    countrySo: 'گینەی فەڕانسە',
    code: 'GF'
  },
  {
    calling_code: 689,
    countryEn: 'French Polynesia',
    countryAr: 'بولينيزيا الفرنسية',
    countryJp: 'フランス領ポリネシア',
    countryRu: 'Французская Полинезия',
    countryEs: 'Polinesia Francesa',
    countryFa: 'پلی‌نزی فرانسه',
    countryZh: '法属波利尼西亚',
    countrySo: 'فۆلینیسیای فەڕەنسە',
    code: 'PF'
  },
  {
    calling_code: 241,
    countryEn: 'Gabon',
    countryAr: 'الجابون',
    countryJp: 'ガボン',
    countryRu: 'Габон',
    countryEs: 'Gabón',
    countryFa: 'گابن',
    countryZh: '加蓬',
    countrySo: 'گابۆن',
    code: 'GA'
  },
  {
    calling_code: 220,
    countryEn: 'Gambia',
    countryAr: 'غامبيا',
    countryJp: 'ガンビア',
    countryRu: 'Гамбия',
    countryEs: 'Gambia',
    countryFa: 'گامبیا',
    countryZh: '冈比亚',
    countrySo: 'گامبیا',
    code: 'GM'
  },
  {
    calling_code: 995,
    countryEn: 'Georgia',
    countryAr: 'جورجيا',
    countryJp: 'グルジア',
    countryRu: 'Грузия',
    countryEs: 'Georgia',
    countryFa: 'گرجستان',
    countryZh: '格鲁吉亚',
    countrySo: 'جۆرجیا',
    code: 'GE'
  },
  {
    calling_code: 49,
    countryEn: 'Germany',
    countryAr: 'ألمانيا',
    countryJp: 'ドイツ',
    countryRu: 'Германия',
    countryEs: 'Alemania',
    countryFa: 'آلمان',
    countryZh: '德国',
    countrySo: 'ئەڵمانیا',
    code: 'DE'
  },
  {
    calling_code: 233,
    countryEn: 'Ghana',
    countryAr: 'غانا',
    countryJp: 'ガーナ',
    countryRu: 'Гана',
    countryEs: 'Ghana',
    countryFa: 'غنا',
    countryZh: '加纳',
    countrySo: 'غەنا',
    code: 'GH'
  },
  {
    calling_code: 350,
    countryEn: 'Gibraltar',
    countryAr: 'جبل طارق',
    countryJp: 'ジブラルタル',
    countryRu: 'Гибралтар',
    countryEs: 'Gibraltar',
    countryFa: 'جبل‌الطارق',
    countryZh: '直布罗陀',
    countrySo: 'گیبرالتار',
    code: 'GI'
  },
  {
    calling_code: 30,
    countryEn: 'Greece',
    countryAr: 'اليونان',
    countryJp: 'ギリシャ',
    countryRu: 'Греция',
    countryEs: 'Grecia',
    countryFa: 'یونان',
    countryZh: '希腊',
    countrySo: 'یۆنان',
    code: 'GR'
  },
  {
    calling_code: 299,
    countryEn: 'Greenland',
    countryAr: 'جرينلاند',
    countryJp: 'グリーンランド',
    countryRu: 'Гренландия',
    countryEs: 'Groenlandia',
    countryFa: 'گرینلند',
    countryZh: '格陵兰',
    countrySo: 'گرینلەند',
    code: 'GL'
  },
  {
    calling_code: 1473,
    countryEn: 'Grenada',
    countryAr: 'Grenada',
    countryJp: 'グレナダ',
    countryRu: 'Гренада',
    countryEs: 'Granada',
    countryFa: 'گرنادا',
    countryZh: '格林纳达',
    countrySo: 'گڕاندا',
  },
  {
    calling_code: 590,
    countryEn: 'Guadeloupe',
    countryAr: 'سان بارتيلمي',
    countryJp: 'グアドループ',
    countryRu: 'Гваделупа',
    countryEs: 'Guadalupe',
    countryFa: 'گوادلوپ',
    countryZh: '瓜德罗普岛',
    countrySo: 'گوالێدووپێ',
    code: 'BL'
  },
  {
    calling_code: 1671,
    countryEn: 'Guam',
    countryAr: 'Guam',
    countryJp: 'グアム',
    countryRu: 'Гуам',
    countryEs: 'Guam',
    countryFa: 'گوام',
    countryZh: '关岛',
    countrySo: 'گووما',
  },
  {
    calling_code: 502,
    countryEn: 'Guatemala',
    countryAr: 'جواتيمالا',
    countryJp: 'グアテマラ',
    countryRu: 'Гватемала',
    countryEs: 'Guatemala',
    countryFa: 'گواتمالا',
    countryZh: '危地马拉',
    countrySo: 'گواتمالا',
    code: 'GT'
  },
  {
    calling_code: 224,
    countryEn: 'Guinea',
    countryAr: 'غينيا',
    countryJp: 'ギニア',
    countryRu: 'Гвинея',
    countryEs: 'Guinea',
    countryFa: 'گینه',
    countryZh: '几内亚',
    countrySo: 'گینا',
    code: 'GN'
  },
  {
    calling_code: 245,
    countryEn: 'Guinea-Bissau',
    countryAr: 'غينيا بيساو',
    countryJp: 'ギニアビサウ',
    countryRu: 'Гвинея-Бисау',
    countryEs: 'Guinea-Bissau',
    countryFa: 'گینه بیسائو',
    countryZh: '几内亚比绍',
    countrySo: 'گینا-بیسائۆ',
    code: 'GW'
  },
  {
    calling_code: 592,
    countryEn: 'Guyana',
    countryAr: 'Guyana',
    countryJp: 'ガイアナ',
    countryRu: 'Гайана',
    countryEs: 'Guyana',
    countryFa: 'گویان',
    countryZh: '圭亚那',
    countrySo: 'گوویانا',
  },
  {
    calling_code: 509,
    countryEn: 'Haiti',
    countryAr: 'هايتي',
    countryJp: 'ハイチ',
    countryRu: 'Гаити',
    countryEs: 'Haití',
    countryFa: 'هائیتی',
    countryZh: '海地',
    countrySo: 'هائیتی',
    code: 'HT'
  },
  {
    calling_code: 504,
    countryEn: 'Honduras',
    countryAr: 'هندوراس',
    countryJp: 'ホンジュラス',
    countryRu: 'Гондурас',
    countryEs: 'Honduras',
    countryFa: 'هندوراس',
    countryZh: '洪都拉斯',
    countrySo: 'هیندوڕاس',
    code: 'HN'
  },
  {
    calling_code: 852,
    countryEn: 'Hong Kong',
    countryAr: 'هونج كونج الصينية',
    countryJp: '香港',
    countryRu: 'Гонконг',
    countryEs: 'Hong Kong',
    countryFa: 'هنگ‌کنگ',
    countryZh: '香港',
    countrySo: 'هۆنگ کۆنگ',
    code: 'HK'
  },
  {
    calling_code: 36,
    countryEn: 'Hungary',
    countryAr: 'المجر',
    countryJp: 'ハンガリー',
    countryRu: 'Венгрия',
    countryEs: 'Hungría',
    countryFa: 'مجارستان',
    countryZh: '匈牙利',
    countrySo: 'هەنگاری',
    code: 'HU'
  },
  {
    calling_code: 354,
    countryEn: 'Iceland',
    countryAr: 'أيسلندا',
    countryJp: 'アイスランド',
    countryRu: 'Исландия',
    countryEs: 'Islandia',
    countryFa: 'ایسلند',
    countryZh: '冰岛',
    countrySo: 'ئایسلەند',
    code: 'IS'
  },
  {
    calling_code: 91,
    countryEn: 'India',
    countryAr: 'الهند',
    countryJp: 'インド',
    countryRu: 'Индия',
    countryEs: 'India',
    countryFa: 'هند',
    countryZh: '印度',
    countrySo: 'هیندستان',
    code: 'IN'
  },
  {
    calling_code: 62,
    countryEn: 'Indonesia',
    countryAr: 'اندونيسيا',
    countryJp: 'インドネシア',
    countryRu: 'Индонезия',
    countryEs: 'Indonesia',
    countryFa: 'اندونزی',
    countryZh: '印度尼西亚',
    countrySo: 'ئەندۆنۆزی',
    code: 'ID'
  },
  {
    calling_code: 98,
    countryEn: 'Iran',
    countryAr: 'ايران',
    countryJp: 'イラン',
    countryRu: 'Иран',
    countryEs: 'Irán',
    countryFa: 'ایران',
    countryZh: '伊朗',
    countrySo: 'ئێران',
    code: 'IR'
  },
  {
    calling_code: 964,
    countryEn: 'Iraq',
    countryAr: 'العراق',
    countryJp: 'イラク',
    countryRu: 'Ирак',
    countryEs: 'Irak',
    countryFa: 'عراق',
    countryZh: '伊拉克',
    countrySo: 'عێراق',
    code: 'IQ'
  },
  {
    calling_code: 353,
    countryEn: 'Ireland',
    countryAr: 'أيرلندا',
    countryJp: 'アイルランド',
    countryRu: 'Ирландия',
    countryEs: 'Irlanda',
    countryFa: 'ایرلند',
    countryZh: '爱尔兰',
    countrySo: 'ئیرلەندا',
    code: 'IE'
  },
  {
    calling_code: 972,
    countryEn: 'Israel',
    countryAr: 'اسرائيل',
    countryJp: 'イスラエル',
    countryRu: 'Израиль',
    countryEs: 'Israel',
    countryFa: 'اسرائيل',
    countryZh: '以色列',
    countrySo: 'ئیسرائیل',
    code: 'IL'
  },
  {
    calling_code: 39,
    countryEn: 'Italy',
    countryAr: 'ايطاليا',
    countryJp: 'イタリア',
    countryRu: 'Италия',
    countryEs: 'Italia',
    countryFa: 'ایتالیا',
    countryZh: '意大利',
    countrySo: 'ئیتاڵیا',
    code: 'IT'
  },
  {
    calling_code: 225,
    countryEn: 'Ivory Coast',
    countryAr: 'ساحل العاج',
    countryJp: 'コートジボワール',
    countryRu: "Кот-д'Ивуар",
    countryEs: 'Costa de Marfil',
    countryFa: 'ساحل عاج',
    countryZh: '象牙海岸',
    countrySo: 'ئایوۆری کۆست',
    code: 'CI'
  },
  {
    calling_code: 1876,
    countryEn: 'Jamaica',
    countryAr: 'Jamaica',
    countryJp: 'ジャマイカ',
    countryRu: 'Ямайка',
    countryEs: 'Jamaica',
    countryFa: 'جامائیکا',
    countryZh: '牙买加',
    countrySo: 'جامائیکا',
  },
  {
    calling_code: 81,
    countryEn: 'Japan',
    countryAr: 'اليابان',
    countryJp: '日本',
    countryRu: 'Япония',
    countryEs: 'Japón',
    countryFa: 'ژاپن',
    countryZh: '日本',
    countrySo: 'ژاپۆن',
    code: 'JP'
  },
  {
    calling_code: 962,
    countryEn: 'Jordan',
    countryAr: 'الأردن',
    countryJp: 'ヨルダン',
    countryRu: 'Иордания',
    countryEs: 'Jordania',
    countryFa: 'اردن',
    countryZh: '约旦',
    countrySo: 'ئۆردەن',
    code: 'JO'
  },
  ,
  {
    calling_code: 7,
    countryEn: 'Kazakhstan',
    countryAr: 'كازاخستان',
    countryJp: 'カザフスタン',
    countryRu: 'Казахстан',
    countryEs: 'Kazajistán',
    countryFa: 'قزاقستان',
    countryZh: '哈萨克斯坦',
    countrySo: 'قەزاکستان',
    code: 'KZ'
  },
  {
    calling_code: 254,
    countryEn: 'Kenya',
    countryAr: 'كينيا',
    countryJp: 'ケニア',
    countryRu: 'Кения',
    countryEs: 'Kenia',
    countryFa: 'کنیا',
    countryZh: '肯尼亚',
    countrySo: 'کێنیا',
    code: 'KE'
  },
  {
    calling_code: 686,
    countryEn: 'Kiribati',
    countryAr: 'كيريباتي',
    countryJp: 'キリバス',
    countryRu: 'Кирибати',
    countryEs: 'Kiribati',
    countryFa: 'کیریباتی',
    countryZh: '基里巴斯',
    countrySo: 'کیرابیتی',
    code: 'KI'
  },
  {
    calling_code: 965,
    countryEn: 'Kuwait',
    countryAr: 'الكويت',
    countryJp: 'クウェート',
    countryRu: 'Кувейт',
    countryEs: 'Kuwait',
    countryFa: 'کویت',
    countryZh: '科威特',
    countrySo: 'کوەیت',
    code: 'KW'
  },
  {
    calling_code: 996,
    countryEn: 'Kyrgyzstan',
    countryAr: 'قرغيزستان',
    countryJp: 'キルギス',
    countryRu: 'Кыргызстан',
    countryEs: 'Kirguistán',
    countryFa: 'قرقیزستان',
    countryZh: '吉尔吉斯斯坦',
    countrySo: 'قیرقیزستان',
    code: 'KG'
  },
  {
    calling_code: 856,
    countryEn: 'Laos',
    countryAr: 'لاوس',
    countryJp: 'ラオス',
    countryRu: 'Лаос',
    countryEs: 'Laos',
    countryFa: 'لائوس',
    countryZh: '老挝',
    countrySo: 'لائۆس',
    code: 'LA'
  },
  {
    calling_code: 371,
    countryEn: 'Latvia',
    countryAr: 'لاتفيا',
    countryJp: 'ラトビア',
    countryRu: 'Латвия',
    countryEs: 'Letonia',
    countryFa: 'لتونی',
    countryZh: '拉脱维亚',
    countrySo: 'لاتڤیا',
    code: 'LV'
  },
  {
    calling_code: 961,
    countryEn: 'Lebanon',
    countryAr: 'لبنان',
    countryJp: 'レバノン',
    countryRu: 'Ливан',
    countryEs: 'Líbano',
    countryFa: 'لبنان',
    countryZh: '黎巴嫩',
    countrySo: 'لوبنان',
    code: 'LB'
  },
  {
    calling_code: 266,
    countryEn: 'Lesotho',
    countryAr: 'ليسوتو',
    countryJp: 'レソト',
    countryRu: 'Лесото',
    countryEs: 'Lesoto',
    countryFa: 'لسوتو',
    countryZh: '莱索托',
    countrySo: 'لێسۆتۆ',
    code: 'LS'
  },
  {
    calling_code: 231,
    countryEn: 'Liberia',
    countryAr: 'ليبيريا',
    countryJp: 'リベリア',
    countryRu: 'Либерия',
    countryEs: 'Liberia',
    countryFa: 'لیبریا',
    countryZh: '利比里亚',
    countrySo: 'لیبێریا',
    code: 'LR'
  },
  {
    calling_code: 218,
    countryEn: 'Libya',
    countryAr: 'ليبيا',
    countryJp: 'リビア',
    countryRu: 'Ливия',
    countryEs: 'Libia',
    countryFa: 'لیبی',
    countryZh: '利比亚',
    countrySo: 'لیبیا',
    code: 'LY'
  },
  {
    calling_code: 423,
    countryEn: 'Liechtenstein',
    countryAr: 'ليختنشتاين',
    countryJp: 'リヒテンシュタイン',
    countryRu: 'Лихтенштейн',
    countryEs: 'Liechtenstein',
    countryFa: 'لیختن‌اشتاین',
    countryZh: '列支敦士登',
    countrySo: 'لیختنشتاین',
    code: 'LI'
  },
  {
    calling_code: 370,
    countryEn: 'Lithuania',
    countryAr: 'ليتوانيا',
    countryJp: 'リトアニア',
    countryRu: 'Литва',
    countryEs: 'Lituania',
    countryFa: 'لیتوانی',
    countryZh: '立陶宛',
    countrySo: 'لیتوانیا',
    code: 'LT'
  },
  {
    calling_code: 352,
    countryEn: 'Luxembourg',
    countryAr: 'لوكسمبورج',
    countryJp: 'ルクセンブルク',
    countryRu: 'Люксембург',
    countryEs: 'Luxemburgo',
    countryFa: 'لوکزامبورگ',
    countryZh: '卢森堡',
    countrySo: 'لووگزامبورگ',
    code: 'LU'
  },
  {
    calling_code: 389,
    countryEn: 'North Macedonia',
    countryAr: 'مقدونيا',
    countryJp: '北マケドニア',
    countryRu: 'Северная Македония',
    countryEs: 'Macedonia del Norte',
    countryFa: 'مقدونیه شمالی',
    countryZh: '北马其顿',
    countrySo: 'مەکدۆنیای باکوور',
    code: 'MK'
  },
  {
    calling_code: 261,
    countryEn: 'Madagascar',
    countryAr: 'مدغشقر',
    countryJp: 'マダガスカル',
    countryRu: 'Мадагаскар',
    countryEs: 'Madagascar',
    countryFa: 'ماداگاسکار',
    countryZh: '马达加斯加',
    countrySo: 'ماداگاسکار',
    code: 'MG'
  },
  {
    calling_code: 265,
    countryEn: 'Malawi',
    countryAr: 'ملاوي',
    countryJp: 'マラウイ',
    countryRu: 'Малави',
    countryEs: 'Malawi',
    countryFa: 'مالاوی',
    countryZh: '马拉维',
    countrySo: 'مالاوی',
    code: 'MW'
  },
  {
    calling_code: 60,
    countryEn: 'Malaysia',
    countryAr: 'ماليزيا',
    countryJp: 'マレーシア',
    countryRu: 'Малайзия',
    countryEs: 'Malasia',
    countryFa: 'مالزی',
    countryZh: '马来西亚',
    countrySo: 'مالیزیا',
    code: 'MY'
  },
  {
    calling_code: 960,
    countryEn: 'Maldives',
    countryAr: 'جزر الملديف',
    countryJp: 'モルディブ',
    countryRu: 'Мальдивы',
    countryEs: 'Maldivas',
    countryFa: 'مالدیو',
    countryZh: '马尔代夫',
    countrySo: 'مالدیڤ',
    code: 'MV'
  },
  {
    calling_code: 223,
    countryEn: 'Mali',
    countryAr: 'مالي',
    countryJp: 'マリ',
    countryRu: 'Мали',
    countryEs: 'Mali',
    countryFa: 'مالی',
    countryZh: '马里',
    countrySo: 'مالی',
    code: 'ML'
  },
  {
    calling_code: 356,
    countryEn: 'Malta',
    countryAr: 'مالطا',
    countryJp: 'マルタ',
    countryRu: 'Мальта',
    countryEs: 'Malta',
    countryFa: 'مالت',
    countryZh: '马耳他',
    countrySo: 'مالتا',
    code: 'MT'
  },
  {
    calling_code: 692,
    countryEn: 'Marshall Islands',
    countryAr: 'جزر المارشال',
    countryJp: 'マーシャル諸島',
    countryRu: 'Маршалловы острова',
    countryEs: 'Islas Marshall',
    countryFa: 'جزایر مارشال',
    countryZh: '马绍尔群岛',
    countrySo: 'دوورگەکانی مارشاڵ',
    code: 'MH'
  },
  {
    calling_code: 596,
    countryEn: 'Martinique',
    countryAr: 'مارتينيك',
    countryJp: 'マルティニーク',
    countryRu: 'Мартиника',
    countryEs: 'Martinica',
    countryFa: 'مارتینیک',
    countryZh: '马提尼克岛',
    countrySo: 'مارتینکۆ',
    code: 'MQ'
  },
  {
    calling_code: 222,
    countryEn: 'Mauritania',
    countryAr: 'موريتانيا',
    countryJp: 'モーリタニア',
    countryRu: 'Мавритания',
    countryEs: 'Mauritania',
    countryFa: 'موریتانی',
    countryZh: '毛里塔尼亚',
    countrySo: 'ماورتینیا',
    code: 'MR'
  },
  {
    calling_code: 230,
    countryEn: 'Mauritius',
    countryAr: 'موريشيوس',
    countryJp: 'モーリシャス',
    countryRu: 'Маврикий',
    countryEs: 'Mauricio',
    countryFa: 'موریس',
    countryZh: '毛里求斯',
    countrySo: 'ماورتیووس',
    code: 'MU'
  },
  {
    calling_code: 262,
    countryEn: 'Mayotte',
    countryAr: 'روينيون',
    countryJp: 'マヨット 島',
    countryRu: 'Майотта',
    countryEs: 'Mayotte',
    countryFa: 'مایوت',
    countryZh: '梅奥特',
    countrySo: 'مایۆتی',
    code: 'RE'
  },
  {
    calling_code: 52,
    countryEn: 'Mexico',
    countryAr: 'المكسيك',
    countryJp: 'メキシコ',
    countryRu: 'Мексика',
    countryEs: 'México',
    countryFa: 'مکزیک',
    countryZh: '墨西哥',
    countrySo: 'مێکزیکۆ',
    code: 'MX'
  },
  {
    calling_code: 691,
    countryEn: 'Micronesia, Federated States of',
    countryAr: 'ميكرونيزيا',
    countryJp: 'ミクロネシア',
    countryRu: 'Микронезия, Федеративные Штаты ',
    countryEs: 'Micronesia, Estados Federados de ',
    countryFa: 'ایالات فدرال میکرونزی',
    countryZh: '密克罗尼西亚联邦',
    countrySo: 'میکڕۆنیزیا، دەوڵەتە فیدیڕاڵەکانی',
    code: 'FM'
  },
  {
    calling_code: 373,
    countryEn: 'Moldova',
    countryAr: 'مولدافيا',
    countryJp: 'モルドバ',
    countryRu: 'Молдова',
    countryEs: 'Moldavia',
    countryFa: 'مولداوی',
    countryZh: '摩尔多瓦',
    countrySo: 'مۆلدۆڤا',
    code: 'MD'
  },
  {
    calling_code: 377,
    countryEn: 'Monaco',
    countryAr: 'موناكو',
    countryJp: 'モナコ',
    countryRu: 'Монако',
    countryEs: 'Mónaco',
    countryFa: 'موناکو',
    countryZh: '摩纳哥',
    countrySo: 'مۆناکۆ',
    code: 'MC'
  },
  {
    calling_code: 976,
    countryEn: 'Mongolia',
    countryAr: 'منغوليا',
    countryJp: 'モンゴル国',
    countryRu: 'Монголия',
    countryEs: 'Mongolia',
    countryFa: 'مغولستان',
    countryZh: '蒙古',
    countrySo: 'مۆنگۆلیا',
    code: 'MN'
  },
  {
    calling_code: 1664,
    countryEn: 'Montserrat',
    countryAr: 'Montserrat',
    countryJp: 'モントセラト',
    countryRu: 'Монтсеррат',
    countryEs: 'Montserrat',
    countryFa: 'مونتسرات',
    countryZh: '蒙特塞拉特',
    countrySo: 'مۆنتسێرات',
  },
  {
    calling_code: 212,
    countryEn: 'Morocco',
    countryAr: 'المغرب',
    countryJp: 'モロッコ',
    countryRu: 'Марокко',
    countryEs: 'Marruecos',
    countryFa: 'مراکش',
    countryZh: '摩洛哥',
    countrySo: 'مۆڕاکۆ',
    code: 'MA'
  },
  {
    calling_code: 258,
    countryEn: 'Mozambique',
    countryAr: 'موزمبيق',
    countryJp: 'モザンビーク',
    countryRu: 'Мозамбик',
    countryEs: 'Mozambique',
    countryFa: 'موزامبیک',
    countryZh: '莫桑比克',
    countrySo: 'موزامبیک',
    code: 'MZ'
  },
  {
    calling_code: 95,
    countryEn: 'Myanmar',
    countryAr: 'ميانمار',
    countryJp: 'ミャンマー',
    countryRu: 'Мьянма',
    countryEs: 'Myanmar (Birmania)',
    countryFa: 'میانمار',
    countryZh: '缅甸',
    countrySo: 'میانمار',
    code: 'MM'
  },
  {
    calling_code: 264,
    countryEn: 'Namibia',
    countryAr: 'ناميبيا',
    countryJp: 'ナミビア',
    countryRu: 'Намибия',
    countryEs: 'Namibia',
    countryFa: 'ناميبيا',
    countryZh: '纳米比亚',
    countrySo: 'نامیبیا',
    code: 'NA'
  },
  {
    calling_code: 674,
    countryEn: 'Nauru',
    countryAr: 'نورو',
    countryJp: 'ナウル',
    countryRu: 'Науру',
    countryEs: 'Nauru',
    countryFa: 'نائورو',
    countryZh: '瑙鲁',
    countrySo: 'ناووروو',
    code: 'NR'
  },
  {
    calling_code: 977,
    countryEn: 'Nepal',
    countryAr: 'نيبال',
    countryJp: 'ネパール',
    countryRu: 'Непал',
    countryEs: 'Nepal',
    countryFa: 'نپال',
    countryZh: '尼泊尔',
    countrySo: 'نێپال',
    code: 'NP'
  },
  {
    calling_code: 31,
    countryEn: 'Netherlands',
    countryAr: 'هولندا',
    countryJp: 'オランダ',
    countryRu: 'Нидерланды',
    countryEs: 'Países Bajos',
    countryFa: 'هلند',
    countryZh: '荷兰',
    countrySo: 'هۆڵەندا',
    code: 'NL'
  },
  {
    calling_code: 687,
    countryEn: 'New Caledonia',
    countryAr: 'كاليدونيا الجديدة',
    countryJp: 'ニューカレドニア',
    countryRu: 'Новая Каледония',
    countryEs: 'Nueva Caledonia',
    countryFa: 'کالدونیای جدید',
    countryZh: '新喀里多尼亚',
    countrySo: 'نیوو کالێدۆنیا',
    code: 'NC'
  },
  {
    calling_code: 64,
    countryEn: 'New Zealand',
    countryAr: 'نيوزيلاندا',
    countryJp: 'ニュージーランド',
    countryRu: 'Новая Зеландия',
    countryEs: 'Nueva Zelanda',
    countryFa: 'نیوزیلند',
    countryZh: '新西兰',
    countrySo: 'نیووزێلاند',
    code: 'NZ'
  },
  {
    calling_code: 505,
    countryEn: 'Nicaragua',
    countryAr: 'نيكاراجوا',
    countryJp: 'ニカラグア',
    countryRu: 'Никарагуа',
    countryEs: 'Nicaragua',
    countryFa: 'نیکاراگوئه',
    countryZh: '尼加拉瓜',
    countrySo: 'نیکاراگۆئێ',
    code: 'NI'
  },
  {
    calling_code: 227,
    countryEn: 'Niger',
    countryAr: 'النيجر',
    countryJp: 'ニジェール',
    countryRu: 'Нигер',
    countryEs: 'Níger',
    countryFa: 'نیجر',
    countryZh: '尼日尔',
    countrySo: 'نایجێر',
    code: 'NE'
  },
  {
    calling_code: 234,
    countryEn: 'Nigeria',
    countryAr: 'نيجيريا',
    countryJp: 'ナイジェリア',
    countryRu: 'Нигерия',
    countryEs: 'Nigeria',
    countryFa: 'نیجریه',
    countryZh: '尼日利亚',
    countrySo: 'نایجێریا',
    code: 'NG'
  },
  {
    calling_code: 683,
    countryEn: 'Niue',
    countryAr: 'نيوي',
    countryJp: 'ニウエ',
    countryRu: 'Ниуэ',
    countryEs: 'Niue',
    countryFa: 'نیوئه',
    countryZh: '纽埃',
    countrySo: 'نیوو',
    code: 'NU'
  },
  {
    calling_code: 672,
    countryEn: 'Norfolk Island',
    countryAr: 'القطب الجنوبي',
    countryJp: 'ノーフォーク島',
    countryRu: 'Остров Норфолк',
    countryEs: 'Isla Norfolk',
    countryFa: 'جزیره نورفولک',
    countryZh: '诺福克岛',
    countrySo: 'نۆرفۆلک ئایلەند',
    code: 'AQ'
  },
  {
    calling_code: 1670,
    countryEn: 'Northern Mariana Islands',
    countryAr: 'Northern Mariana Islands',
    countryJp: '北マリアナ諸島',
    countryRu: 'Северные Марианские острова',
    countryEs: 'Islas Marianas del Norte',
    countryFa: 'جزایر ماریانای شمالی',
    countryZh: '北马里亚纳群岛',
    countrySo: 'دوورگەکانی نۆرسێرن ماریانا',
  },
  {
    calling_code: 47,
    countryEn: 'Norway',
    countryAr: 'جزيرة بوفيه',
    countryJp: 'ノルウェー',
    countryRu: 'Норвегия',
    countryEs: 'Noruega',
    countryFa: 'نروژ',
    countryZh: '挪威',
    countrySo: 'نەرویج',
    code: 'BV'
  },
  {
    calling_code: 968,
    countryEn: 'Oman',
    countryAr: 'عمان',
    countryJp: 'オマーン',
    countryRu: 'Оман',
    countryEs: 'Omán',
    countryFa: 'عمان',
    countryZh: '阿曼',
    countrySo: 'ئەمان',
    code: 'OM'
  },
  {
    calling_code: 92,
    countryEn: 'Pakistan',
    countryAr: 'باكستان',
    countryJp: 'パキスタン',
    countryRu: 'Пакистан',
    countryEs: 'Pakistán',
    countryFa: 'پاکستان',
    countryZh: '巴基斯坦',
    countrySo: 'پاکیستان',
    code: 'PK'
  },
  {
    calling_code: 680,
    countryEn: 'Palau',
    countryAr: 'بالاو',
    countryJp: 'パラオ',
    countryRu: 'Палау',
    countryEs: 'Palau',
    countryFa: 'پالائو',
    countryZh: '帕劳',
    countrySo: 'پالائوو',
    code: 'PW'
  },
  {
    calling_code: 970,
    countryEn: 'Palestine',
    countryAr: 'فلسطين',
    countryJp: 'パレスチナ',
    countryRu: 'Палестина',
    countryEs: 'Palestina',
    countryFa: 'فلسطین',
    countryZh: '巴勒斯坦',
    countrySo: 'فەلستین',
    code: 'PS'
  },
  {
    calling_code: 507,
    countryEn: 'Panama',
    countryAr: 'بنما',
    countryJp: 'パナマ',
    countryRu: 'Панама',
    countryEs: 'Panamá',
    countryFa: 'پاناما',
    countryZh: '巴拿马',
    countrySo: 'پاناما',
    code: 'PA'
  },
  {
    calling_code: 675,
    countryEn: 'Papua New Guinea',
    countryAr: 'بابوا غينيا الجديدة',
    countryJp: 'パプアニューギニア',
    countryRu: 'Папуа - Новая Гвинея',
    countryEs: 'Papúa Nueva Guinea',
    countryFa: 'پاپوآ گینه نو',
    countryZh: '巴布亚新几内亚',
    countrySo: 'پاپووا گینەی نوێ',
    code: 'PG'
  },
  {
    calling_code: 595,
    countryEn: 'Paraguay',
    countryAr: 'غيانا',
    countryJp: 'パラグアイ',
    countryRu: 'Парагвай',
    countryEs: 'Paraguay',
    countryFa: 'پاراگوئه',
    countryZh: '巴拉圭',
    countrySo: 'پاڕاگۆئێ',
    code: 'GY'
  },
  {
    calling_code: 51,
    countryEn: 'Peru',
    countryAr: 'بيرو',
    countryJp: 'ペルー',
    countryRu: 'Перу',
    countryEs: 'Perú',
    countryFa: 'پرو',
    countryZh: '秘鲁',
    countrySo: 'پێروو',
    code: 'PE'
  },
  {
    calling_code: 63,
    countryEn: 'Philippines',
    countryAr: 'الفيلبين',
    countryJp: 'フィリピン',
    countryRu: 'Филиппины',
    countryEs: 'Filipinas',
    countryFa: 'فیلیپین',
    countryZh: '菲律宾',
    countrySo: 'فیلیپین',
    code: 'PH'
  },
  {
    calling_code: 48,
    countryEn: 'Poland',
    countryAr: 'بولندا',
    countryJp: 'ポーランド',
    countryRu: 'Польша',
    countryEs: 'Polonia',
    countryFa: 'لهستان',
    countryZh: '波兰',
    countrySo: 'پۆڵەندا',
    code: 'PL'
  },
  {
    calling_code: 351,
    countryEn: 'Portugal',
    countryAr: 'البرتغال',
    countryJp: 'ポルトガル',
    countryRu: 'Португалия',
    countryEs: 'Portugal',
    countryFa: 'کشور پرتغال',
    countryZh: '葡萄牙',
    countrySo: 'پورتوگال',
    code: 'PT'
  },
  {
    calling_code: 1939,
    countryEn: 'Puerto Rico',
    countryAr: 'Puerto Rico',
    countryJp: 'プエルトリコ',
    countryRu: 'Пуэрто-Рико',
    countryEs: 'Puerto Rico',
    countryFa: 'پورتوریکو',
    countryZh: '波多黎各',
    countrySo: 'پۆرتێ ڕیکۆ',
  },
  {
    calling_code: 974,
    countryEn: 'Qatar',
    countryAr: 'قطر',
    countryJp: 'カタール',
    countryRu: 'Катар',
    countryEs: 'Qatar',
    countryFa: 'قطر',
    countryZh: '卡塔尔',
    countrySo: 'قەتەر',
    code: 'QA'
  },
  {
    calling_code: 262,
    countryEn: 'Reunion',
    countryAr: 'روينيون',
    countryJp: 'レユニオン',
    countryRu: 'Реюньон',
    countryEs: 'Reunión',
    countryFa: 'ریونیون',
    countryZh: '团圆',
    countrySo: 'ڕێیوونینۆن',
    code: 'RE'
  },
  {
    calling_code: 40,
    countryEn: 'Romania',
    countryAr: 'رومانيا',
    countryJp: 'ルーマニア',
    countryRu: 'Румыния',
    countryEs: 'Rumania',
    countryFa: 'رومانی',
    countryZh: '罗马尼亚',
    countrySo: 'ڕۆمانیا',
    code: 'RO'
  },
  {
    calling_code: 7,
    countryEn: 'Russian Federation',
    countryAr: 'كازاخستان',
    countryJp: 'ロシア連邦',
    countryRu: 'Российская Федерация',
    countryEs: 'Federación Rusa',
    countryFa: 'فدراسیون روسیه',
    countryZh: '俄罗斯联邦',
    countrySo: 'فیدڕاسیۆنی ڕوسیا',
    code: 'KZ'
  },
  {
    calling_code: 250,
    countryEn: 'Rwanda',
    countryAr: 'رواندا',
    countryJp: 'ルワンダ',
    countryRu: 'Руанда',
    countryEs: 'Ruanda',
    countryFa: 'رواندا',
    countryZh: '卢旺达',
    countrySo: 'ڕواندا',
    code: 'RW'
  },
  {
    calling_code: 290,
    countryEn: 'Saint Helena',
    countryAr: 'سانت هيلنا',
    countryJp: 'セントヘレナ',
    countryRu: 'Остров Святой Елены',
    countryEs: 'Santa Helena',
    countryFa: 'سنت هلنا',
    countryZh: '圣赫勒拿',
    countrySo: 'سەینت هێلێنا',
    code: 'SH'
  },
  {
    calling_code: 1869,
    countryEn: 'Saint Kitts and Nevis',
    countryAr: 'Saint Kitts and Nevis',
    countryJp: 'セントクリストファー・ネイビス',
    countryRu: 'Сент-Китс и Невис',
    countryEs: 'San Cristóbal y Nieves',
    countryFa: 'سنت کیتس و نویس',
    countryZh: '圣基茨和尼维斯',
    countrySo: 'سەینت کیت و نێڤیس',
  },
  {
    calling_code: 1758,
    countryEn: 'Saint Lucia',
    countryAr: 'Saint Lucia',
    countryJp: 'セントルシア',
    countryRu: 'Сент-Люсия',
    countryEs: 'Santa Lucía',
    countryFa: 'سنت لوسیا',
    countryZh: '圣卢西亚',
    countrySo: 'سەینت لووسیا',
  },
  {
    calling_code: 508,
    countryEn: 'Saint Pierre and Miquelon',
    countryAr: 'سانت بيير وميكولون',
    countryJp: 'サンピエール島とミクロン島',
    countryRu: 'Сен-Пьер и Микелон',
    countryEs: 'San Pedro y Miquelón',
    countryFa: 'سنت پیر و میکلون',
    countryZh: '圣皮埃尔和密克隆',
    countrySo: 'سەینت پیێر و میکولۆن',
    code: 'PM'
  },
  {
    calling_code: 1784,
    countryEn: 'Saint Vincent and the Grenadines',
    countryAr: 'Saint Vincent and the Grenadines',
    countryJp: 'セントビンセント・グレナディーン諸島',
    countryRu: 'Сент-Винсент и Гренадины',
    countryEs: 'San Vicente y las Granadinas',
    countryFa: 'سنت وینسنت و جزایر گرنادین',
    countryZh: '圣文森特和格林纳丁斯',
    countrySo: 'سەینت وینستن و گرێنادینز',
  },
  {
    calling_code: 685,
    countryEn: 'Samoa',
    countryAr: 'ساموا',
    countryJp: 'サモア',
    countryRu: 'Самоа',
    countryEs: 'Samoa',
    countryFa: 'ساموآ',
    countryZh: '萨摩亚',
    countrySo: 'سامۆا',
    code: 'WS'
  },
  {
    calling_code: 378,
    countryEn: 'San Marino',
    countryAr: 'سان مارينو',
    countryJp: 'サンマリノ',
    countryRu: 'Сан-Марино',
    countryEs: 'San Marino',
    countryFa: 'سان‌مارینو',
    countryZh: '圣马力诺',
    countrySo: 'سەنمارینۆ',
    code: 'SM'
  },
  {
    calling_code: 239,
    countryEn: 'Sao Tome and Principe',
    countryAr: 'ساو تومي وبرينسيبي',
    countryJp: 'サントメとプリンシペ',
    countryRu: 'Сан-Томе и Принсипи',
    countryEs: 'Santo Tomé y Príncipe',
    countryFa: 'سائوتومه و پرینسیپ',
    countryZh: '圣多美和普林西比',
    countrySo: 'سائۆ تۆما و پڕینسیپ',
    code: 'ST'
  },
  {
    calling_code: 966,
    countryEn: 'Saudi Arabia',
    countryAr: 'المملكة العربية السعودية',
    countryJp: 'サウジアラビア',
    countryRu: 'Саудовская Аравия',
    countryEs: 'Arabia Saudí',
    countryFa: 'عربستان سعودی',
    countryZh: '沙特阿拉伯',
    countrySo: 'عەڕەبستانی سەعوودی',
    code: 'SA'
  },
  {
    calling_code: 221,
    countryEn: 'Senegal',
    countryAr: 'السنغال',
    countryJp: 'セネガル',
    countryRu: 'Сенегал',
    countryEs: 'Senegal',
    countryFa: 'سنگال',
    countryZh: '塞内加尔',
    countrySo: 'سێنێگال',
    code: 'SN'
  },
  {
    calling_code: 248,
    countryEn: 'Seychelles',
    countryAr: 'سيشل',
    countryJp: 'セーシェル',
    countryRu: 'Сейшельские острова',
    countryEs: 'Seychelles',
    countryFa: 'سیشل',
    countryZh: '塞舌尔',
    countrySo: 'سێیچێلس',
    code: 'SC'
  },
  {
    calling_code: 232,
    countryEn: 'Sierra Leone',
    countryAr: 'سيراليون',
    countryJp: 'シエラレオネ',
    countryRu: 'Сьерра-Леоне',
    countryEs: 'Sierra Leona',
    countryFa: 'سیرالئون',
    countryZh: '塞拉利昂',
    countrySo: 'سیرا لێئۆن',
    code: 'SL'
  },
  {
    calling_code: 65,
    countryEn: 'Singapore',
    countryAr: 'سنغافورة',
    countryJp: 'シンガポール',
    countryRu: 'Сингапур',
    countryEs: 'Singapur',
    countryFa: 'سنگاپور',
    countryZh: '新加坡',
    countrySo: 'سەنگاپوور',
    code: 'SG'
  },
  {
    calling_code: 421,
    countryEn: 'Slovakia',
    countryAr: 'سلوفاكيا',
    countryJp: 'スロバキア',
    countryRu: 'Словакия',
    countryEs: 'Eslovaquia',
    countryFa: 'اسلواکی',
    countryZh: '斯洛伐克',
    countrySo: 'سلۆڤاکیا',
    code: 'SK'
  },
  {
    calling_code: 386,
    countryEn: 'Slovenia',
    countryAr: 'سلوفينيا',
    countryJp: 'スロベニア',
    countryRu: 'Словения',
    countryEs: 'Eslovenia',
    countryFa: 'اسلوونی',
    countryZh: '斯洛文尼亚',
    countrySo: 'سلۆڤێنیا',
    code: 'SI'
  },
  {
    calling_code: 677,
    countryEn: 'Solomon Islands',
    countryAr: 'جزر سليمان',
    countryJp: 'ソロモン諸島',
    countryRu: 'Соломоновы острова',
    countryEs: 'Islas Salomón',
    countryFa: 'جزایر سلیمان',
    countryZh: '所罗门群岛',
    countrySo: 'دوورگەکانی سۆلۆمۆن',
    code: 'SB'
  },
  {
    calling_code: 252,
    countryEn: 'Somalia',
    countryAr: 'الصومال',
    countryJp: 'ソマリア',
    countryRu: 'Сомали',
    countryEs: 'Somalia',
    countryFa: 'سومالی',
    countryZh: '索马里',
    countrySo: 'سومالیا',
    code: 'SO'
  },
  {
    calling_code: 27,
    countryEn: 'South Africa',
    countryAr: 'جمهورية جنوب افريقيا',
    countryJp: '南アフリカ',
    countryRu: 'Южная Африка',
    countryEs: 'Sudáfrica',
    countryFa: 'آفریقای جنوبی',
    countryZh: '南非',
    countrySo: 'ئەفریکای باشوور',
    code: 'ZA'
  },
  {
    calling_code: 500,
    countryEn: 'South Georgia and the South Sandwich Islands',
    countryAr: 'جزر فوكلاند',
    countryJp: '南ジョージアと南サンドイッチ諸島',
    countryRu: 'Южная Георгия и Южные Сандвичевы острова',
    countryEs: 'Islas Georgia del Sur y Sandwich del Sur',
    countryFa: 'جنوب جورجیا و جزایر ساندویچ جنوبی',
    countryZh: '南乔治亚岛和南桑威奇群岛',
    countrySo: 'جۆرجیای باشوور و دوورگەکانی سانویچی باشوور',
    code: 'FK'
  },
  {
    calling_code: 211,
    countryEn: 'South Sudan',
    countryAr: 'جنوب السودان',
    countryJp: '南スーダン',
    countryRu: 'Южный Судан',
    countryEs: 'Sudán del Sur',
    countryFa: 'سودان جنوبی',
    countryZh: '南苏丹',
    countrySo: 'سوودانی باشوور',
    code: 'SS'
  },
  {
    calling_code: 34,
    countryEn: 'Spain',
    countryAr: 'أسبانيا',
    countryJp: 'スペイン',
    countryRu: 'Испания',
    countryEs: 'España',
    countryFa: 'اسپانیا',
    countryZh: '西班牙',
    countrySo: 'ئیسپانیا',
    code: 'ES'
  },
  {
    calling_code: 94,
    countryEn: 'Sri Lanka',
    countryAr: 'سيريلانكا',
    countryJp: 'スリランカ',
    countryRu: 'Шри-Ланка',
    countryEs: 'Sri Lanka',
    countryFa: 'سری‌لانکا',
    countryZh: '斯里兰卡',
    countrySo: 'سێریلانکا',
    code: 'LK'
  },
  {
    calling_code: 249,
    countryEn: 'Sudan',
    countryAr: 'السودان',
    countryJp: 'スーダン',
    countryRu: 'Судан',
    countryEs: 'Sudán',
    countryFa: 'سودان',
    countryZh: '苏丹',
    countrySo: 'سوودان',
    code: 'SD'
  },
  {
    calling_code: 597,
    countryEn: 'Suriname',
    countryAr: 'سورينام',
    countryJp: 'スリナム',
    countryRu: 'Суринам',
    countryEs: 'Surinam',
    countryFa: 'سورینام',
    countryZh: '苏里南',
    countrySo: 'سوورینام',
    code: 'SR'
  },
  {
    calling_code: 268,
    countryEn: 'Swaziland',
    countryAr: 'سوازيلاند',
    countryJp: 'スワジランド',
    countryRu: 'Свазиленд',
    countryEs: 'Sualizandia',
    countryFa: 'سوازیلند',
    countryZh: '斯威士兰',
    countrySo: 'سوازلەند',
    code: 'SZ'
  },
  {
    calling_code: 46,
    countryEn: 'Sweden',
    countryAr: 'السويد',
    countryJp: 'スウェーデン',
    countryRu: 'Швеция',
    countryEs: 'Suecia',
    countryFa: 'سوئد',
    countryZh: '瑞典',
    countrySo: 'سوەید',
    code: 'SE'
  },
  {
    calling_code: 41,
    countryEn: 'Switzerland',
    countryAr: 'سويسرا',
    countryJp: 'スイス',
    countryRu: 'Швейцария',
    countryEs: 'Suiza',
    countryFa: 'سوئیس',
    countryZh: '瑞士',
    countrySo: 'سویسرا',
    code: 'CH'
  },
  {
    calling_code: 963,
    countryEn: 'Syria',
    countryAr: 'سوريا',
    countryJp: 'シリア',
    countryRu: 'Сирия',
    countryEs: 'Siria',
    countryFa: 'سوریه',
    countryZh: '叙利亚',
    countrySo: 'سوریا',
    code: 'SY'
  },
  {
    calling_code: 992,
    countryEn: 'Tajikistan',
    countryAr: 'طاجكستان',
    countryJp: 'タジキスタン',
    countryRu: 'Таджикистан',
    countryEs: 'Tayikistán',
    countryFa: 'تاجیکستان',
    countryZh: '塔吉克斯坦',
    countrySo: 'تاجیکیستان',
    code: 'TJ'
  },
  {
    calling_code: 255,
    countryEn: 'Tanzania',
    countryAr: 'تانزانيا',
    countryJp: 'タンザニア',
    countryRu: 'Танзания',
    countryEs: 'Tanzania',
    countryFa: 'تانزانیا',
    countryZh: '坦桑尼亚',
    countrySo: 'تانزانیا',
    code: 'TZ'
  },
  {
    calling_code: 66,
    countryEn: 'Thailand',
    countryAr: 'تايلند',
    countryJp: 'タイ',
    countryRu: 'Таиланд',
    countryEs: 'Tailandia',
    countryFa: 'تایلند',
    countryZh: '泰国',
    countrySo: 'تایلەند',
    code: 'TH'
  },
  {
    calling_code: 243,
    countryEn: 'The Democratic Republic of Congo',
    countryAr: 'جمهورية الكونغو الديمقراطية',
    countryJp: 'コンゴ民主共和国',
    countryRu: 'Демократическая Республика Конго',
    countryEs: 'República Democrática del Congo',
    countryFa: 'جمهوری دموکراتیک کنگو',
    countryZh: '刚果民主共和国',
    countrySo: 'کۆماری دیموکراتیکی کۆنگۆ',
    code: 'CD'
  },
  {
    calling_code: 228,
    countryEn: 'Togo',
    countryAr: 'توجو',
    countryJp: 'トーゴ',
    countryRu: 'Того',
    countryEs: 'Togo',
    countryFa: 'توگو',
    countryZh: '多哥',
    countrySo: 'تۆگۆ',
    code: 'TG'
  },
  {
    calling_code: 690,
    countryEn: 'Tokelau',
    countryAr: 'توكيلو',
    countryJp: 'トケラウ',
    countryRu: 'Токелау',
    countryEs: 'Tokelau',
    countryFa: 'توكلائو',
    countryZh: '托克劳',
    countrySo: 'تۆکێلائۆ',
    code: 'TK'
  },
  {
    calling_code: 676,
    countryEn: 'Tonga',
    countryAr: 'تونجا',
    countryJp: 'トンガ',
    countryRu: 'Тонга',
    countryEs: 'Tonga',
    countryFa: 'تونگا',
    countryZh: '汤加',
    countrySo: 'تۆنگا',
    code: 'TO'
  },
  {
    calling_code: 1868,
    countryEn: 'Trinidad and Tobago',
    countryAr: 'Trinidad and Tobago',
    countryJp: 'トリニダード・トバゴ',
    countryRu: 'Тринидад и Тобаго',
    countryEs: 'Trinidad y Tobago',
    countryFa: 'ترینیداد و توباگو',
    countryZh: '特立尼达和多巴哥',
    countrySo: 'تریندادا و تۆباگۆ',
  },
  {
    calling_code: 216,
    countryEn: 'Tunisia',
    countryAr: 'تونس',
    countryJp: 'チュニジア',
    countryRu: 'Тунис',
    countryEs: 'Túnez',
    countryFa: 'تونس',
    countryZh: '突尼斯',
    countrySo: 'توونس',
    code: 'TN'
  },
  {
    calling_code: 90,
    countryEn: 'Turkey',
    countryAr: 'تركيا',
    countryJp: 'トルコ',
    countryRu: 'Турция',
    countryEs: 'Turquía',
    countryFa: 'ترکیه',
    countryZh: '土耳其',
    countrySo: 'تورکیا',
    code: 'TR'
  },
  {
    calling_code: 993,
    countryEn: 'Turkmenistan',
    countryAr: 'تركمانستان',
    countryJp: 'トルクメニスタン',
    countryRu: 'Туркменистан',
    countryEs: 'Turkmenistán',
    countryFa: 'ترکمنستان',
    countryZh: '土库曼斯坦',
    countrySo: 'تورکەمەنستان',
    code: 'TM'
  },
  {
    calling_code: 1649,
    countryEn: 'Turks and Caicos Islands',
    countryAr: 'Turks and Caicos Islands',
    countryJp: 'タークス・カイコス諸島',
    countryRu: 'Острова Теркс и Кайкос',
    countryEs: 'Islas Turcos y Caicos',
    countryFa: 'جزایر ترک و کایکوس',
    countryZh: '特克斯和凯科斯群岛',
    countrySo: 'دوورگەکانی تورک و کەیکس',
  },
  {
    calling_code: 688,
    countryEn: 'Tuvalu',
    countryAr: 'توفالو',
    countryJp: 'ツバル',
    countryRu: 'Тувалу',
    countryEs: 'Tuvalu',
    countryFa: 'تووالو',
    countryZh: '图瓦卢',
    countrySo: 'تووڤالۆ',
    code: 'TV'
  },
  {
    calling_code: 256,
    countryEn: 'Uganda',
    countryAr: 'أوغندا',
    countryJp: 'ウガンダ',
    countryRu: 'Уганда',
    countryEs: 'Uganda',
    countryFa: 'اوگاندا',
    countryZh: '乌干达',
    countrySo: 'ئۆگاندا',
    code: 'UG'
  },
  {
    calling_code: 380,
    countryEn: 'Ukraine',
    countryAr: 'أوكرانيا',
    countryJp: 'ウクライナ',
    countryRu: 'Украина',
    countryEs: 'Ucrania',
    countryFa: 'اوکراین',
    countryZh: '乌克兰',
    countrySo: 'ئوکڕاین',
    code: 'UA'
  },
  {
    calling_code: 971,
    countryEn: 'United Arab Emirates',
    countryAr: 'الامارات العربية المتحدة',
    countryJp: 'アラブ首長国連邦',
    countryRu: 'Объединенные Арабские Эмираты',
    countryEs: 'Emiratos Árabes Unidos',
    countryFa: 'امارات متحده عربی',
    countryZh: '阿拉伯联合酋长国',
    countrySo: 'ئیماڕاتی یەکگرتووی عەڕەبی',
    code: 'AE'
  },
  {
    calling_code: 44,
    countryEn: 'United Kingdom',
    countryAr: 'المملكة المتحدة',
    countryJp: '英国',
    countryRu: 'Великобритания',
    countryEs: 'Reino Unido',
    countryFa: 'بریتانیا',
    countryZh: '联合王国',
    countrySo: 'شانشینی یەکگرتوو',
    code: 'GB'
  },
  {
    calling_code: 1,
    countryEn: 'United States',
    countryAr: 'أنتيجوا وبربودا',
    countryJp: '米国',
    countryRu: 'Соединенные Штаты Америки',
    countryEs: 'Estados Unidos',
    countryFa: 'ایالات متحده',
    countryZh: '美国',
    countrySo: 'ویلایەتە یەکگرتووەکان',
    code: 'AG'
  },
  {
    calling_code: 598,
    countryEn: 'Uruguay',
    countryAr: 'أورجواي',
    countryJp: 'ウルグアイ',
    countryRu: 'Уругвай',
    countryEs: 'Uruguay',
    countryFa: 'اروگوئه',
    countryZh: '乌拉圭',
    countrySo: 'ئۆرۆگۆئێ',
    code: 'UY'
  },
  {
    calling_code: 998,
    countryEn: 'Uzbekistan',
    countryAr: 'أوزبكستان',
    countryJp: 'ウズベキスタン',
    countryRu: 'Узбекистан',
    countryEs: 'Uzbekistán',
    countryFa: 'ازبکستان',
    countryZh: '乌兹别克斯坦',
    countrySo: 'ئوزبەکستان',
    code: 'UZ'
  },
  {
    calling_code: 678,
    countryEn: 'Vanuatu',
    countryAr: 'فانواتو',
    countryJp: 'バヌアツ',
    countryRu: 'Вануату',
    countryEs: 'Vanuatu',
    countryFa: 'وانواتو',
    countryZh: '瓦努阿图',
    countrySo: 'ڤانوواتوو',
    code: 'VU'
  },
  {
    calling_code: 58,
    countryEn: 'Venezuela',
    countryAr: 'فنزويلا',
    countryJp: 'ベネズエラ',
    countryRu: 'Венесуэла',
    countryEs: 'Venezuela',
    countryFa: 'ونزوئلا',
    countryZh: '委内瑞拉',
    countrySo: 'ڤەنەزۆئێلا',
    code: 'VE'
  },
  {
    calling_code: 84,
    countryEn: 'Vietnam',
    countryAr: 'فيتنام',
    countryJp: 'ベトナム',
    countryRu: 'Вьетнам',
    countryEs: 'Vietnam',
    countryFa: 'ویتنام',
    countryZh: '越南',
    countrySo: 'ڤیەتنام',
    code: 'VN'
  },
  {
    calling_code: 681,
    countryEn: 'Wallis and Futuna',
    countryAr: 'جزر والس وفوتونا',
    countryJp: 'ウォリスとフツナ',
    countryRu: 'Острова Уоллис и Футуна',
    countryEs: 'Wallis y Futuna',
    countryFa: 'والیس و فوتونا',
    countryZh: '瓦利斯和富图纳',
    countrySo: 'والیس و فووتوونا',
    code: 'WF'
  },
  {
    calling_code: 967,
    countryEn: 'Yemen',
    countryAr: 'اليمن',
    countryJp: 'イエメン',
    countryRu: 'Йемен',
    countryEs: 'Yemen',
    countryFa: 'یمن',
    countryZh: '也门',
    countrySo: 'یەمەن',
    code: 'YE'
  },
  {
    calling_code: 260,
    countryEn: 'Zambia',
    countryAr: 'زامبيا',
    countryJp: 'ザンビア',
    countryRu: 'Замбия',
    countryEs: 'Zambia',
    countryFa: 'زامبیا',
    countryZh: '赞比亚',
    countrySo: 'زامبیا',
    code: 'ZM'
  },
  {
    calling_code: 263,
    countryEn: 'Zimbabwe',
    countryAr: 'زيمبابوي',
    countryJp: 'ジンバブエ',
    countryRu: 'Зимбабве ',
    countryEs: 'Zimbaue',
    countryFa: 'زیمبابوه',
    countryZh: '津巴布韦',
    countrySo: 'زیمباوێ',
    code: 'ZW'
  }
]

export const getSortedCountries = () => {
  const lang = localStorage.getItem('lang');
  let key = 'countryEn'
  if (lang === 'ar-ae') key = 'countryAr'
  if (lang === 'jp') key = 'countryJp'
  if (lang === 'ru') key = 'countryRu'
  if (lang === 'es') key = 'countryEs'
  if (lang === 'fa') key = 'countryAr'
  if (lang === 'zh') key = 'countryZh'
  if (lang === 'so') key = 'countrySo'

  const sortedCountries = COUNTRIES.sort((a, b) =>
    a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0
  );
  return sortedCountries;
};

export const getSortedCountriesNew = (countires) => {
  const lang = localStorage.getItem('lang');
  let key = 'countryEn'
  if (lang === 'ar-ae') key = 'countryAr'
  if (lang === 'jp') key = 'countryJp'
  if (lang === 'ru') key = 'countryRu'
  if (lang === 'es') key = 'countryEs'
  if (lang === 'fa') key = 'countryAr'
  if (lang === 'zh') key = 'countryZh'
  if (lang === 'so') key = 'countrySo'

  const sortedCountries = countires.sort((a, b) =>
    a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0
  );
  return sortedCountries;
};

export const getCountryByCountryCode = (code) => {
  // return countryCallingCode[0].country;

  const matched = countryCodes.filter((obj) => obj.code === code);
  if (matched.length > 0) {
    const inn = countryCallingCode.filter(
      (obj) => matched[0].name === obj.country
    );
    if (inn.length > 0) {
      return inn[0].country;
    }
  }
  return '';
};

export const getCountryObjByName = (name) => {
  const inn = COUNTRIES.filter(
    (obj) => name === obj.countryEn
  );
  if (inn.length > 0) {
    return inn[0];
  }
  return null;
};

export const getCountryObjByNameNew = (name, countries=[]) => {
  const inn = countries.filter(
    (obj) => name === obj.countryEn
  );
  if (inn.length > 0) {
    return inn[0];
  }
  return null;
};

