import React from 'react';
import { connect } from 'react-redux';
import { authUser } from '../../reducers/Auth';
import ReactDOM from 'react-dom';
import {
  Grid,
  InputAdornment,
  Button,
  TextField,
  CircularProgress
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import MailOutlineTwoToneIcon from '@material-ui/icons/MailOutlineTwoTone';
import LockTwoToneIcon from '@material-ui/icons/LockTwoTone';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { withNamespaces } from 'react-i18next';
import { clearJourneyAll } from 'utils/utilities'

const Login = (props) => {
  const t = props.t;
  const [state, setState] = React.useState({
    message: '',
    showMessage: false,
    messageType: ''
  });

  const authUser = async (model) => {
    setState({
      ...state,
      showMessage: false
    });

    const res = await props.authUser(model);

    setState({
      ...state,
      message: res.message,
      messageType: res.messageType,
      showMessage: true
    });
    // if (res.success) props.history.push('/dashboard');
    // window.open('https://cp-new-ui.azurewebsites.net/Dashboard', '_parent');
    if (res.success) {
      window.open(window.location.origin + '/Dashboard', '_parent');
      clearJourneyAll();
      
    }
  };

  return (
    <>
      {/* <div className="app-wrapper  bg-white"> */}
      {/* <div className="app-main "> */}
      {/* <div className="app-content p-0"> */}
      <div className="app-inner-content-layout--main">
        <div className="flex-grow-1 w-100 d-flex align-items-center">
          <div className="bg-composed-wrapper--content">
            <Grid container spacing={0} className="">
              <Grid item lg={12} xl={12} className="d-flex align-items-center">
                <Grid item md={10} lg={10} xl={10} className="mx-auto">
                  <Formik
                    initialValues={{
                      email: '',
                      password: ''
                    }}
                    validationSchema={Yup.object({
                      password: Yup.string()
                        .min(6, t('Must be at least 6 characters'))
                        .required(t('Required')),
                      email: Yup.string()
                        .email('Invalid email address')
                        .required(t('Required'))
                    })}
                    onSubmit={async (values, { setSubmitting }) => {
                      authUser({
                        email: values.email,
                        password: values.password
                      });
                      setSubmitting(false);
                    }}>
                    {({
                      values,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit
                    }) => {
                      return (
                        <form onSubmit={handleSubmit}>
                          <div className="py-4">
                            <div className="text-center">
                              <p className="font-size-lg mb-0 text-black-50">
                                {t(
                                  'Fill in the fields below to login to your account'
                                )}
                              </p>
                            </div>
                            <div>
                              <div className="my-4">
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  id="email"
                                  name="email"
                                  label={t('Email address')}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <MailOutlineTwoToneIcon />
                                      </InputAdornment>
                                    )
                                  }}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.email}
                                  error={touched.email && errors.email}
                                  helperText={touched.email && errors.email}
                                />
                              </div>
                              <div className="mb-3">
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  id="password"
                                  name="password"
                                  label={t('Password')}
                                  type="password"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <LockTwoToneIcon />
                                      </InputAdornment>
                                    )
                                  }}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.password}
                                  error={touched.password && errors.password}
                                  helperText={
                                    touched.password && errors.password
                                  }
                                />
                              </div>
                              {state.showMessage && (
                                <div className="text-center py-4">
                                  <Alert severity={state.messageType}>
                                    {state.message}
                                  </Alert>
                                </div>
                              )}
                              <div className="text-center py-4">
                                <Button
                                  disabled={props.Auth.loginLoading}
                                  type="submit"
                                  className="btn-second font-weight-bold w-50 my-2">
                                  {props.Auth.loginLoading ? (
                                    <CircularProgress size={20} />
                                  ) : (
                                    t('Log In')
                                  )}
                                </Button>
                              </div>
                            </div>
                          </div>
                        </form>
                      );
                    }}
                  </Formik>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
      {/* </div> */}
      {/* </div> */}
      {/* </div> */}
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    Auth: state.Auth
  };
};

//connect with redux and give the access to defined method
export default withNamespaces()(connect(mapStateToProps, { authUser })(Login));
