import React, { Component, useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import i18n from '../../i18n';
import {
  Typography,
  Container,
  Button,
  TextField,
  Checkbox,
  Snackbar,
  CircularProgress,
  IconButton,
  MenuItem
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MuiAlert from '@material-ui/lab/Alert';
import {
  Send,
  ChevronRight,
  MoreHoriz,
  Check,
  Visibility,
  VisibilityOff
} from '@material-ui/icons';

import { Formik } from 'formik';
import * as Yup from 'yup';

import {
  COUNTRIES,
  countryCallingCode,
  getCountryByCountryCode,
  getCountryObjByName,
  getSortedCountries,
  getSortedCountriesNew,
  getCountryObjByNameNew
} from '../../common';
import { countryKey } from '../../utils/utilities';
import {
  generatePin,
  isEmailAvailable,
  verifyPin,
  registerUser,
  registerLiveUser,
  authUser,
  locateMe
} from './../../reducers/Auth';
import {
  getConfigs
} from './../../reducers/Configs';
import { withNamespaces } from 'react-i18next';
import  { app_config }  from 'config/app-config'

const validEmail = (email) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

const phoneRegExp = /(\+|00)(297|93|244|1264|358|355|376|971|54|374|1684|1268|61|43|994|257|32|229|226|880|359|973|1242|387|590|375|501|1441|591|55|1246|673|975|267|236|1|61|41|56|86|225|237|243|242|682|57|269|238|506|53|5999|61|1345|357|420|49|253|1767|45|1809|1829|1849|213|593|20|291|212|34|372|251|358|679|500|33|298|691|241|44|995|44|233|350|224|590|220|245|240|30|1473|299|502|594|1671|592|852|504|385|509|36|62|44|91|246|353|98|964|354|972|39|1876|44|962|81|76|77|254|996|855|686|1869|82|383|965|856|961|231|218|1758|423|94|266|370|352|371|853|590|212|377|373|261|960|52|692|389|223|356|95|382|976|1670|258|222|1664|596|230|265|60|262|264|687|227|672|234|505|683|31|47|977|674|64|968|92|507|64|51|63|680|675|48|1787|1939|850|351|595|970|689|974|262|40|7|250|966|249|221|65|500|4779|677|232|503|378|252|508|381|211|239|597|421|386|46|268|1721|248|963|1649|235|228|66|992|690|993|670|676|1868|216|90|688|886|255|256|380|598|1|998|3906698|379|1784|58|1284|1340|84|678|681|685|967|27|260|263)(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{4,20}$/im;
const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z!@#$%^&*()_,.?":{}|<>\d]{8,20}$/;

let isPinVerified = false;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      className="p-md-3 p-sm-0"
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}>
      {value === index && <div>{children}</div>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const Form = (props) => {
  const {
    values: {
      type,
      firstName,
      lastName,
      countryResidency,
      countryCode,
      city,
      phone,
      email,
      portalPassword,
      portalPasswordConfirm,
      emailPin,
      declarations,
      tos
    },
    errors,
    touched,
    handleSubmit,
    handleChange,
    setFieldTouched,
    setFieldValue,
    activeSubmit,
    isLoading
  } = props;
  const t = props.t;
  // const countries = getSortedCountries();
  const [havePin, setHavePin] = useState(false);
  const [verifingPin, setVerifingPin] = useState(false);
  const [verifiedPin, setVerifiedPin] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [pinBtnText, setPinBtnText] = useState(t('Send PIN'));
  const [pinBtnIcon, setPinBtnIcon] = useState(<Send />);
  const [lang, setLang] = useState('');
  const [countries, setCountries] = React.useState([]);

  const myhandleChange = (e) => {
    setFieldTouched(e.target.name);
    handleChange(e);
  };
  const phoneRef = useRef();
  const pinRef = useRef();

  const getUsreLang = () => {
    const tmp = localStorage.getItem('lang');
    if (tmp === 'ar-ae' || tmp === 'en-gb') return tmp;
    return 'en-gb';
  };

  const sendPin = async (e) => {
    setPinBtnText(t('Sending'));
    setPinBtnIcon(<MoreHoriz />);
    try {
      const resp = await generatePin({ email: email, language: getUsreLang() });
      if (resp.isSuccess) {
        setHavePin(true);
        setPinBtnText(t('Enter PIN'));
        setPinBtnIcon(<ChevronRight />);
      }
    } catch (error) {
      setHavePin(false);
      setPinBtnText(t('Send PIN'));
      setPinBtnIcon(<Send />);
    }
  };

  const verifyEmailPin = async () => {
    setVerifingPin(true);
    setPinBtnText(t('Verifying'));
    setPinBtnIcon(<MoreHoriz />);
    try {
      const resp = await verifyPin({ email: email, value: emailPin });
      if (resp.isSuccess) {
        setVerifingPin(false);
        setVerifiedPin(true);
        setPinBtnText(t('PIN Verified'));
        setPinBtnIcon(<Check />);
        isPinVerified = true;
      }
    } catch (error) {
      setVerifingPin(false);
      setPinBtnText(t('Enter PIN'));
      setPinBtnIcon(<ChevronRight />);
      errors.emailPin = t('PIN not verified');
      setFieldTouched(t('emailPin'));
    }
  };

  useEffect(() => {
      props.getConfigs('countries');
  }, []);

  useEffect(() => {
    if (props.Configs.countries && props.Configs.countries.length > 0) {
      setCountries(getSortedCountriesNew(props.Configs.countries));
    }
  }, [props.Configs.countries]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const lang = params.get('lang');
    if (lang) {
      if (lang === 'ar-ae' || lang === 'en-gb') {
        setLang(lang);
        i18n.changeLanguage(lang);
        localStorage.setItem('lang', lang);
      }
    } else {
      setLang(localStorage.getItem('lang'));
    }
  }, []);

  useEffect(() => {
    if (type === 'corporate') {
      setFieldValue('lastName', '');
      setFieldTouched('lastName', false);
    }
  }, [type]);

  useEffect(() => {
    setHavePin(false);
    setPinBtnText(t('Send PIN'));
    setPinBtnIcon(<Send />);
  }, [email]);

  useEffect(() => {
    if (havePin) pinRef.current.focus();
  }, [havePin]);

  useEffect(() => {
    if (emailPin.length === 6) verifyEmailPin();
  }, [emailPin]);

  useEffect(() => {
    const selCountry = countries && countries.length > 0 && countries.find(
      (country) => country.name === countryResidency
    );
    if (selCountry) {
      setFieldValue('phone', '+' + selCountry.calling_code);
      const selectedCountry = countries.find(c => c.countryEn === countryResidency)
      if (selectedCountry && selectedCountry.code)
        setFieldValue('countryCode', selectedCountry.code);
    }
  }, [countryResidency]);

  const handleBlur = (field) => {
    setFieldTouched(field);
  };

  const storedLang = localStorage.getItem('lang');
  let key = countryKey(storedLang);

  return (
    <Container className={lang === 'ar-ae' && 'dir-ar-ae'}>
      <form onSubmit={handleSubmit}>
        <TextField
          variant="standard"
          className="mt-4 mb-2 text-left"
          name="type"
          select
          helperText={touched.type ? errors.type : ''}
          error={touched.type ? Boolean(errors.type) : false}
          label={t('User Type')}
          value={type}
          onChange={handleChange}
          onBlur={() => {
            handleBlur('type');
          }}
          fullWidth>
          <MenuItem className="p-2" value="individual">
            {t('Individual')}
          </MenuItem>
          <MenuItem className="p-2" value="corporate">
            {t('Corporate')}
          </MenuItem>
        </TextField>
        <TextField
          variant="standard"
          className="mb-2"
          name="firstName"
          helperText={touched.firstName ? errors.firstName : ''}
          error={touched.firstName ? Boolean(errors.firstName) : false}
          label={type === 'individual' ? t('First Name') : t('Company Name')}
          value={firstName}
          onChange={myhandleChange}
          onBlur={() => {
            handleBlur('firstName');
          }}
          fullWidth
        />

        {type === 'individual' && (
          <TextField
            variant="standard"
            className="mb-2"
            name="lastName"
            helperText={touched.lastName ? errors.lastName : ''}
            error={touched.lastName ? Boolean(errors.lastName) : false}
            label={t('Last Name')}
            value={lastName}
            onChange={handleChange}
            onBlur={() => {
              handleBlur('lastName');
            }}
            fullWidth
          />
        )}

        <Autocomplete
          id="country"
          className="mb-2"
          options={countries}
          fullWidth
          autoHighlight
          blurOnSelect
          value={getCountryObjByNameNew(countryResidency, countries)}
          onChange={(e, value) => { setFieldValue("countryResidency", value ? value.countryEn : ''); }}
          getOptionLabel={(option) => option[key]}
          renderOption={(option) => option[key]}
          renderInput={(params) => (
            <TextField
              {...params}
              label={
                type === 'individual'
                  ? t('Country of Residence')
                  : t('Country of Incorporation')
              }
              name="countryResidency"
              value={countryResidency}
              helperText={
                touched.countryResidency ? errors.countryResidency : ''
              }
              error={
                touched.countryResidency
                  ? Boolean(errors.countryResidency)
                  : false
              }
              onBlur={() => {
                handleBlur('countryResidency');
              }}
              variant="standard"
            />
          )}
        />
        <TextField
          variant="standard"
          className="mb-4"
          name="phone"
          inputRef={phoneRef}
          onBlur={() => {
            handleBlur('phone');
          }}
          helperText={touched.phone ? errors.phone : ''}
          error={touched.phone ? Boolean(errors.phone) : false}
          label={t('Phone')}
          fullWidth
          value={phone}
          onChange={handleChange}
          inputProps={{ className: 'dir-ltr' }}
        />
        <TextField
          variant="standard"
          className="mb-4"
          name="email"
          helperText={touched.email ? errors.email : ''}
          error={touched.email ? Boolean(errors.email) : false}
          label={t('Email')}
          onBlur={() => {
            handleBlur('email');
          }}
          fullWidth
          value={email}
          onChange={handleChange}
        />
        <TextField
          variant="standard"
          className="mb-2"
          name="portalPassword"
          onBlur={() => {
            handleBlur('portalPassword');
          }}
          helperText={touched.portalPassword ? errors.portalPassword : ''}
          error={
            touched.portalPassword ? Boolean(errors.portalPassword) : false
          }
          label={t('Password')}
          fullWidth
          type={showPassword ? 'text' : 'password'}
          value={portalPassword}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => {
                  setShowPassword(!showPassword);
                }}>
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            )
          }}
        />
        <div className="mb-2">
          <div className="badge badge-pill badge-info mr-1">
            {t('8-20 characters')}
          </div>
          <div className="badge badge-pill badge-info mr-1">
            {t('Latin Letters')}
          </div>
          <div className="badge badge-pill badge-info mr-1">{t('Numbers')}</div>
        </div>
        <TextField
          variant="standard"
          className="mb-4"
          name="portalPasswordConfirm"
          onBlur={() => {
            handleBlur('portalPasswordConfirm');
          }}
          helperText={
            touched.portalPasswordConfirm ? errors.portalPasswordConfirm : ''
          }
          error={
            touched.portalPasswordConfirm
              ? Boolean(errors.portalPasswordConfirm)
              : false
          }
          label={t('Confirm Password')}
          fullWidth
          type={showPassword1 ? 'text' : 'password'}
          value={portalPasswordConfirm}
          onChange={handleChange}
          InputProps={{
            endAdornment: (
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => {
                  setShowPassword1(!showPassword1);
                }}>
                {showPassword1 ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            )
          }}
        />
        <div className="d-flex align-items-center justify-content-start">
          <Button
            className="btn-warning mb-4"
            disabled={Boolean(errors.email) || havePin}
            endIcon={pinBtnIcon}
            onClick={sendPin}>
            {t(pinBtnText)}
          </Button>
          <TextField
            variant="standard"
            className="mb-4 mx-3"
            inputRef={pinRef}
            label={t('Enter PIN')}
            helperText={touched.emailPin ? errors.emailPin : ''}
            error={touched.emailPin ? Boolean(errors.emailPin) : false}
            value={emailPin}
            onChange={handleChange}
            name="emailPin"
            disabled={!havePin || verifingPin || verifiedPin}
          />
        </div>
        {havePin && (
          <div className="MuiFormHelperText-root mb-4 text-center text-danger">
            {t('PIN sent to your email, please verify')}
          </div>
        )}
        <div className="d-flex justify-content-between">
          <Checkbox
            checked={tos}
            onChange={handleChange}
            name="tos"
            id="tos"
            value="true"
            className="align-self-start p-0 mx-2"
            color="default"
          />
          <div className="mb-4">
            <p>
              {t('By clicking here I give my consent for')}
              &nbsp; <b>{t(`${app_config.clientName}`)}</b> &nbsp;
              {t(
                'to contact me for marketing purposes, You can opt out at any time, For further details please see our'
              )}
                {t('Marketing and Communication Policy Statement')}
              {/* </a> */}
              .
            </p>
            <p
              className="MuiFormHelperText-root Mui-error"
              style={{ color: 'red', fontSize: 'small' }}>
              {touched.emailPin &&
                errors.tos &&
                t('You must tick the consent form')}
            </p>
          </div>
        </div>
        <div className="text-center">
          <Button
            style={{ minWidth: '8rem' }}
            type="submit"
            size="large"
            className="btn-success"
            disabled={!activeSubmit || props.isLoading}>
            {props.isLoading ? (
              <CircularProgress size={20} />
            ) : (
              t('Register Now')
            )}
          </Button>
        </div>
      </form>
    </Container>
  );
};

const LivePreviewExample = (props) => {
  const t = props.t;
  const validationSchema = Yup.object({
    type: Yup.string('Select Type').required(t('Type is required')),
    firstName: Yup.string('Enter a first name').when('type', {
      is: 'individual',
      then: Yup.string().required(t('First name is required')),
      otherwise: Yup.string().required(t('Company name is required'))
    }),
    lastName: Yup.string('Enter a last name').when('type', {
      is: 'individual',
      then: Yup.string().required(t('Last name is required')),
      otherwise: Yup.string()
    }),
    countryResidency: Yup.string('Select your country of residence').when(
      'type',
      {
        is: 'individual',
        then: Yup.string().required(t('Country of residence is required')),
        otherwise: Yup.string().required(
          t('Country of incorporation is required')
        )
      }
    ),
    city: Yup.string('Enter City'),
    phone: Yup.string('Enter your Phone')
      .required(t('Phone is required'))
      .matches(phoneRegExp, t('Phone number is not valid')),
    email: Yup.string('Enter your email')
      .required(t('Email is required'))
      .email(t('Enter a valid email'))
      .test(
        'email-availability',
        t('Account already exists with this email, Kindly login'),
        async (value) => {
          try {
            if (validEmail(value)) {
              const resp = await isEmailAvailable({
                email: value.toLowerCase()
              });

              return resp.result.availability;
            }
            return false;
          } catch (error) {
            console.error(error);
            return false;
          }
        }
      ),
    portalPassword: Yup.string('')
      .min(8, t('Password must contain atleast 8 characters'))
      .max(20, t('Password should not be more than 20 characters long'))
      .matches(
        passwordRegExp,
        t('Atleast one lower case, upper case and number required')
      )
      .required(t('Enter your password')),
    portalPasswordConfirm: Yup.string('')
      .oneOf([Yup.ref('portalPassword'), null], 'Passwords must match').required(t('Enter confirm password')),
    emailPin: Yup.string('')
      .required(t('PIN must be entered'))
      .length(6, t('Pin should be 6 characters exact')),
    // .test('verify-pin', 'PIN must be verfied', async (value) => {
    //   return isPinVerified;
    // }),
    tos: Yup.boolean().oneOf([true], t('Accept Terms & Conditions is required'))
  });

  const { history, referral, utmCampaign } = props;

  const [activeSubmit, setActiveSubmit] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const [infoBar, setInfoBar] = useState(false);
  const [errorBar, setErrorBar] = useState(false);
  const [partnerId, setPartnerId] = useState(false);
  const [ibId, setIbId] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const partnerId = params.get('ibRef');
    const tmpIbId = params.get('ibId');
    if (partnerId) setPartnerId(partnerId);
    if (tmpIbId) setIbId(tmpIbId);
  }, []);

  const submit = async (data) => {
    setActiveSubmit(false);
    setIsLoading(true);
    if (partnerId) data.ibRef = partnerId;
    if (ibId) data.ibId = ibId;
    try {
      data.referral = referral ? referral : null;
      data.utmCampaign = utmCampaign ? utmCampaign : null;

      const resp = await registerLiveUser(data);
      await props.authUser({
        email: data.email,
        password: data.portalPassword
      });

      setIsLoading(false);
      if (resp.isSuccess) {
        localStorage.removeItem('kyc');
        localStorage.removeItem('deposit');
        localStorage.removeItem('bonus');
        localStorage.removeItem('trading');
        localStorage.removeItem('kdt');
        localStorage.removeItem('skip');
        localStorage.removeItem('activeStep');
        localStorage.removeItem('showCreateAccount');
        window.open(window.location.origin + '/Dashboard', '_parent');
        setInfoBar(true);
        setTimeout(() => {
          history.push('/Dashboard');
        }, 2000);
      } else {
        setErrorBar(true);
        setActiveSubmit(true);
      }
    } catch (error) {
      setErrorBar(true);
      setActiveSubmit(true);
      setIsLoading(false);
    }
  };

  const handleInfoBarClose = () => {
    setInfoBar(false);
  };

  const handleErrorBarClose = () => {
    setErrorBar(false);
  };

  const [values, setValues] = useState({
    type: 'individual',
    firstName: '',
    lastName: '',
    countryResidency: 'United Arab Emirates',
    countryCode: 'AE',
    // city: 'none',
    phone: '+971',
    email: '',
    portalPassword: '',
    portalPasswordConfirm: '',
    emailPin: '',
    declarations: [
      `By clicking here I give my consent for ${app_config.clientName} to contact me for marketing purposes. You can opt out at any time. For further details please see ourMarketing and Communication Policy Statement.`
    ],
    tos: false,
    language: new URLSearchParams(window.location.search).get('lang') || 'en-gb'
  });

  useEffect(() => {
    const tmp = async () => {
      const currentCuontry = await props.locateMe();
      setValues({
        ...values,
        countryResidency: getCountryByCountryCode(currentCuontry.country),
        countryCode: currentCuontry.country
      });
    };
    tmp();
  }, []);

  return (
    <>
      <Formik
        enableReinitialize={true}
        validateOnMount={false}
        validateOnChange={true}
        validate={false}
        validateOnBlur={true}
        initialValues={values}
        validationSchema={validationSchema}
        onSubmit={submit}>
        {(props) => (
          <Formis
            activeSubmit={activeSubmit}
            isLoading={isLoading}
            {...props}
          />
        )}
      </Formik>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    // Auth: state.Auth
    Configs: state.Configs
  };
};

// export default LivePreviewExample;
export default withNamespaces()(
  connect(mapStateToProps, { authUser, locateMe, getConfigs })(LivePreviewExample)
);
const Formis = withNamespaces()(connect(mapStateToProps, { getConfigs })(Form));
