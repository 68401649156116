import axiosHelper from '../helpers/axiosHelper';
import * as qs from 'querystring';

// Demo Account
export const GET_DEMO_ACCOUNT_START = 'GET_DEMO_ACCOUNT_START';
export const GET_DEMO_ACCOUNT_DONE = 'GET_DEMO_ACCOUNT_DONE';
export const CREATE_DEMO_APPLICATION_START = 'CREATE_DEMO_APPLICATION_START';
export const CREATE_DEMO_APPLICATION_DONE = 'CREATE_DEMO_APPLICATION_DONE';
export const CREATE_DEMO_APPLICATION_REQUEST_START =
  'CREATE_DEMO_APPLICATION_REQUEST_START';
export const CREATE_DEMO_APPLICATION_REQUEST_DONE =
  'CREATE_DEMO_APPLICATION_REQUEST_DONE';

// Live Account
export const GET_LIVE_ACCOUNT_START = 'GET_LIVE_ACCOUNT_START';
export const GET_LIVE_ACCOUNT_DONE = 'GET_LIVE_ACCOUNT_DONE';
export const CREATE_LIVE_APPLICATION_START = 'CREATE_LIVE_APPLICATION_START';
export const CREATE_LIVE_APPLICATION_DONE = 'CREATE_LIVE_APPLICATION_DONE';
export const CREATE_APPLICATION_REQUEST_START =
  'CREATE_APPLICATION_REQUEST_START';
export const CREATE_APPLICATION_REQUEST_DONE =
  'CREATE_APPLICATION_REQUEST_DONE';

//Transactions
export const GET_TRANSACTIONS_START = 'GET_TRANSACTIONS_START';
export const GET_TRANSACTIONS_DONE = 'GET_TRANSACTIONS_DONE';

//Traders
export const GET_TRADERS_START = 'GET_TRADERS_START';
export const GET_TRADERS_DONE = 'GET_TRADERS_DONE';

//close positions
export const GET_CLOSE_POSITIONS_START = 'GET_CLOSE_POSITIONS_START';
export const GET_CLOSE_POSITIONS_DONE = 'GET_CLOSE_POSITIONS_DONE';

export const SET_SELECTED_ACCOUNT = 'SET_SELECTED_ACCOUNT';
export const SET_SELECTED_ACCOUNT_DEMO = 'SET_SELECTED_ACCOUNT_DEMO';

//Change MT5 Password
export const CHANGE_MT5_PASSWORD_START = 'CHANGE_MT5_PASSWORD_START';
export const CHANGE_MT5_PASSWORD_DONE = 'CHANGE_MT5_PASSWORD_DONE';

//Change Leverage
export const CHANGE_LEVERAGE_START = 'CHANGE_LEVERAGE_START';
export const CHANGE_LEVERAGE_DONE = 'CHANGE_LEVERAGE_DONE';

//Withdrawal Request
export const WITHDRAWAL_REQUEST_START = 'WITHDRAWAL_REQUEST_START';
export const WITHDRAWAL_REQUEST_DONE = 'WITHDRAWAL_REQUEST_DONE';

//Deposit
export const DEPOSIT_START = 'DEPOSIT_START';
export const DEPOSIT_DONE = 'DEPOSIT_DONE';

//GATE TO PAY
export const GATE_TO_PAY_LIST_START = 'GATE_TO_PAY_LIST_START';
export const GATE_TO_PAY_LIST_DONE = 'GATE_TO_PAY_LIST_DONE';

//Account Types
export const ACCOUNT_TYPE_START = 'ACCOUNT_TYPE_START';
export const ACCOUNT_TYPE_DONE = 'ACCOUNT_TYPE_DONE';

//Demo Account Types
export const DEMO_ACCOUNT_TYPE_START = 'DEMO_ACCOUNT_TYPE_START';
export const DEMO_ACCOUNT_TYPE_DONE = 'DEMO_ACCOUNT_TYPE_DONE';

export const GET_MY_ACTIVE_GATEWAYS_START = 'GET_MY_ACTIVE_GATEWAYS_START';
export const GET_MY_ACTIVE_GATEWAYS_END = 'GET_MY_ACTIVE_GATEWAYS_END';


export const GET_SUMMARY_IB_START = 'GET_SUMMARY_IB_START';
export const GET_SUMMARY_IB_DONE = 'GET_SUMMARY_IB_DONE';

//IB Transactions
export const GET_IB_TRANSACTIONS_START = 'GET_IB_TRANSACTIONS_START';
export const GET_IB_TRANSACTIONS_DONE = 'GET_IB_TRANSACTIONS_DONE';

//IB Live Apps
export const GET_IB_LIVE_APPS_START = 'GET_IB_LIVE_APPS_START';
export const GET_IB_LIVE_APPS_DONE = 'GET_IB_LIVE_APPS_DONE';

//IB Demo Apps
export const GET_IB_DEMO_APPS_START = 'GET_IB_DEMO_APPS_START';
export const GET_IB_DEMO_APPS_DONE = 'GET_IB_DEMO_APPS_DONE';

//IB Open Position
export const GET_OPEN_POSITIONS_IB_START = 'GET_OPEN_POSITIONS_IB_START';
export const GET_OPEN_POSITIONS_IB_DONE = 'GET_OPEN_POSITIONS_IB_DONE';

//IB Close Position
export const GET_CLOSE_POSITIONS_IB_START = 'GET_CLOSE_POSITIONS_IB_START';
export const GET_CLOSE_POSITIONS_IB_DONE = 'GET_CLOSE_POSITIONS_IB_DONE';

//IB Commissions
export const GET_COMMISSIONS_IB_START = 'GET_COMMISSIONS_IB_START';
export const GET_COMMISSIONS_IB_DONE = 'GET_COMMISSIONS_IB_DONE';

//IB Commissions By Id
export const GET_COMMISSIONS_BY_ID_IB_START = 'GET_COMMISSIONS_BY_ID_IB_START';
export const GET_COMMISSIONS_BY_ID_IB_DONE = 'GET_COMMISSIONS_BY_ID_IB_DONE';


export const GET_IB_ACCOUNT_START = 'GET_IB_ACCOUNT_START';
export const GET_IB_ACCOUNT_DONE = 'GET_IB_ACCOUNT_DONE';

export const GET_CLIENTS_ACCOUNT_START = 'GET_CLIENTS_ACCOUNT_START';
export const GET_CLIENTS_ACCOUNT_DONE = 'GET_CLIENTS_ACCOUNT_DONE';


//Get Demo user
export const getDemoAccounts = () => async (dispatch) => {
  dispatch({ type: GET_DEMO_ACCOUNT_START });
  let axios = axiosHelper(true);
  const res = await axios
    .get('/my/demo-applications')
    .then(async (response) => {
      dispatch({ type: GET_DEMO_ACCOUNT_DONE, payload: response.data.result });
      dispatch(setSelectedDemoAccount(response.data.result[0] ? response.data.result[0]._id : ''));
      if (response.data.isSuccess) {
        return {
          success: true,
          messageType: 'success',
          message: ''
        };
      }
      return {
        success: false,
        messageType: 'error',
        message: response.data.message
      };
    })
    .catch((error) => {
      dispatch({ type: GET_DEMO_ACCOUNT_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

//Create Demo Account
export const createDemoApplication = (model) => async (dispatch) => {
  dispatch({ type: CREATE_DEMO_APPLICATION_START });
  let axios = axiosHelper(true);
  const res = await axios
    .post('/my/demo-application', qs.stringify(model))
    .then(async (response) => {
      dispatch({ type: CREATE_DEMO_APPLICATION_DONE });
      if (response.data.isSuccess) {
        await dispatch(getDemoAccounts());
        return {
          success: true,
          messageType: 'success',
          message: 'Demo account created successfully.'
        };
      }
      return { success: false, messageType: 'error', message: response.data.message };
    })
    .catch((error) => {
      dispatch({ type: CREATE_DEMO_APPLICATION_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

//Get Live user
export const getLiveAccounts = () => async (dispatch) => {
  dispatch({ type: GET_LIVE_ACCOUNT_START });
  let axios = axiosHelper(true);
  const res = await axios
    .get('/my/live-applications')
    .then(async (response) => {
      dispatch({ type: GET_LIVE_ACCOUNT_DONE, payload: response.data.result });
      dispatch(setSelectedLiveAccount(response.data.result[0] ? response.data.result[0]._id : ''));
      if (response.data.isSuccess) {
        return {
          success: true,
          messageType: 'success',
          message: ''
        };
      }
      return {
        success: false,
        messageType: 'error',
        message: response.data.message
      };
    })
    .catch((error) => {
      dispatch({ type: GET_LIVE_ACCOUNT_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

export const getIbAccount = () => (dispatch) => {
  dispatch({ type: GET_IB_ACCOUNT_START });
  let axios = axiosHelper(true);
  axios
    .get('/ib/ib-application')
    .then(async (response) => {
      dispatch({ type: GET_IB_ACCOUNT_DONE, payload: response.data.result });
    })
    .catch((error) => {
      dispatch({ type: GET_IB_ACCOUNT_DONE, payload: {} });
    });
};

export const getClientsAccounts = () => (dispatch) => {
  dispatch({ type: GET_CLIENTS_ACCOUNT_START });
  let axios = axiosHelper(true);
  axios
    .get('/ib/live-applications')
    .then(async (response) => {
      dispatch({ type: GET_CLIENTS_ACCOUNT_DONE, payload: response.data.result });
    })
    .catch((error) => {
      dispatch({ type: GET_CLIENTS_ACCOUNT_DONE, payload: {} });
    });
};

//Create Demo Account
export const createLiveApplication = (model) => async (dispatch) => {
  dispatch({ type: CREATE_LIVE_APPLICATION_START });
  let axios = axiosHelper(true);
  const res = await axios
    .post('/my/live-application', qs.stringify(model))
    .then(async (response) => {
      dispatch({ type: CREATE_LIVE_APPLICATION_DONE });
      if (response.data.isSuccess) {
        await dispatch(getLiveAccounts());
        localStorage.removeItem('activeStep');
        return {
          success: true,
          messageType: 'success',
          message: 'Live account created successfully.'
        };
      }
      return { success: false, messageType: 'error', message: response.data.message };
    })
    .catch((error) => {
      dispatch({ type: CREATE_LIVE_APPLICATION_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};


export const changeApplicationRequest = (data) => async (dispatch) => {
  let axios = axiosHelper(true);
  const res = await axios.post('/ib/ibopentradingaccount', qs.stringify(data))
    .then(async (response) => {
      console.log(response.data, 'response')
      // dispatch({ type: CHANGE_APPLICATION_REQUEST_START });
      if (response.data.isSuccess) {
        return {
          success: true,
          messageType: 'success',
          message: 'Account request created successfully.'
        }
      } else {
        return {
          success: true,
          messageType: 'error',
          message: response.data.errors
        }
      }
    })
    .catch((error) => {
      console.log(error, 'error')

      // dispatch({ type: CHANGE_APPLICATION_REQUEST_START });
      // let message = 'Invalid request.';
      // if (error.response && error.response.data)
      //   message = error.response.data.message;
      // return { success: false, messageType: 'error', message: message };
    });
  return res;
};


export const createApplicationRequest = (model) => async (dispatch) => {
  dispatch({ type: CREATE_APPLICATION_REQUEST_START });
  let axios = axiosHelper(true);
  const res = await axios
    .post('/request/account', qs.stringify(model))
    .then(async (response) => {
      dispatch({ type: CREATE_APPLICATION_REQUEST_START });
      if (response.data.isSuccess) {
        return {
          success: true,
          messageType: 'success',
          message: 'Account request created successfully.'
        };
      }
      return { success: false, messageType: 'error', message: response.data.message };
    })
    .catch((error) => {
      dispatch({ type: CREATE_APPLICATION_REQUEST_START });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

//Get Transactions
export const getTransactions = (id, limit, page = 1) => async (dispatch) => {
  dispatch({ type: GET_TRANSACTIONS_START });
  let axios = axiosHelper(true);
  const res = await axios
    .get(
      '/transaction/' +
      id +
      `?limit=${limit}&page=${page}`
    )
    .then(async (response) => {
      dispatch({ type: GET_TRANSACTIONS_DONE, payload: response.data.result });
      if (response.data.isSuccess) {
        return {
          success: true,
          messageType: 'success',
          message: ''
        };
      }
      return {
        success: false,
        messageType: 'error',
        message: response.data.message
      };
    })
    .catch((error) => {
      dispatch({ type: GET_TRANSACTIONS_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

//Get Traders
export const getTraders = (id, limit, offset) => async (dispatch) => {
  dispatch({ type: GET_TRADERS_START });
  let axios = axiosHelper(true);
  const res = await axios
    .get('/my/open-positions/' + id + `?limit=${limit}&offset=${offset}`)
    .then(async (response) => {
      dispatch({ type: GET_TRADERS_DONE, payload: response.data.result });
      if (response.data.isSuccess) {
        return {
          success: true,
          messageType: 'success',
          message: ''
        };
      }
      return {
        success: false,
        messageType: 'error',
        message: response.data.message
      };
    })
    .catch((error) => {
      dispatch({ type: GET_TRADERS_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

//Get closed positions
export const getClosePositions = (
  id,
  from,
  to,
  limit = 10,
  offset = 0
) => async (dispatch) => {
  dispatch({ type: GET_CLOSE_POSITIONS_START });
  let axios = axiosHelper(true);
  const res = await axios
    // .get('/my/close-positions/' + id + `?limit=${limit}&offset=${offset}&from=${from}&to=${to}`)
    .get('/my/last5-close-positions/' + id + `?limit=${limit}&offset=${offset}`)
    .then(async (response) => {
      dispatch({
        type: GET_CLOSE_POSITIONS_DONE,
        payload: response.data.result
      });
      if (response.data.isSuccess) {
        return {
          success: true,
          messageType: 'success',
          message: ''
        };
      }
      return {
        success: false,
        messageType: 'error',
        message: response.data.message
      };
    })
    .catch((error) => {
      dispatch({ type: GET_CLOSE_POSITIONS_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

export const setSelectedLiveAccount = (id) => async (dispatch) => {
  dispatch({ type: SET_SELECTED_ACCOUNT, payload: id });
};

export const setSelectedDemoAccount = (id) => async (dispatch) => {
  dispatch({ type: SET_SELECTED_ACCOUNT_DEMO, payload: id });
};
//Change MT5 Password
export const changeMT5Password = (model, id) => async (dispatch) => {
  dispatch({ type: CHANGE_MT5_PASSWORD_START });
  let axios = axiosHelper(true);
  const res = await axios
    .post('/application/' + id + '/change-password', qs.stringify(model))
    .then((response) => {
      dispatch({ type: CHANGE_MT5_PASSWORD_DONE });
      if (response.data.isSuccess) {
        return {
          success: true,
          messageType: 'success',
          message: 'Password changed successfully.'
        };
      }
      return { success: false, messageType: 'error', message: response.data.message };
    })
    .catch((error) => {
      dispatch({ type: CHANGE_MT5_PASSWORD_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};
// export const journeyStatus = () => (dispatch)

// }
//Change Leverage
export const changeLeverage = (model) => async (dispatch) => {
  dispatch({ type: CHANGE_LEVERAGE_START });
  let axios = axiosHelper(true);
  const res = await axios
    .post('/request/change-leverage', qs.stringify(model))
    .then(async (response) => {
      dispatch({ type: CHANGE_LEVERAGE_DONE });
      if (response.data.isSuccess) {
        await dispatch(getLiveAccounts());
        return {
          success: true,
          messageType: 'success',
          message: 'Leverage change request submitted successfully.'
        };
      }
      return { success: false, messageType: 'error', message: response.data.message };
    })
    .catch((error) => {
      dispatch({ type: CHANGE_LEVERAGE_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

//Withdrawal request
export const praxisWithdrawalRequest = (model) => async (dispatch) => {

  try {
    dispatch({ type: WITHDRAWAL_REQUEST_START });
    let axios = axiosHelper(true, false, true);
    // model should be amount, accountNumber, applicationId
    const response = await axios.post('/praxis/withdrawal', JSON.stringify(model));
    dispatch({ type: WITHDRAWAL_REQUEST_DONE });
    return {
      success: true,
      messageType: 'success',
      data: response.data.result,
      message: response.data.message || 'Success'
    };

  } catch (error) {
    dispatch({ type: WITHDRAWAL_REQUEST_DONE });
    let message = 'Invalid request.';
    if (error.response && error.response.data)
      message = error.response.data.message;
    return { success: false, messageType: 'error', message: message };
  }

}
export const withdrawalRequest = (model) => async (dispatch) => {
  dispatch({ type: WITHDRAWAL_REQUEST_START });
  let axios = axiosHelper(true, false, false, true);
  const res = await axios
    .post('/transaction', model)
    .then(async (response) => {
      dispatch({ type: WITHDRAWAL_REQUEST_DONE });
      if (response.data.isSuccess) {
        await dispatch(getLiveAccounts());
        return {
          success: true,
          messageType: 'success',
          message: 'Withdraw request created successfully, wait for the admin for approval'
        };
      }
      return { success: false, messageType: 'error', message: response.data.message };
    })
    .catch((error) => {
      dispatch({ type: WITHDRAWAL_REQUEST_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

export const wireTransferDeposit = (data) => async (dispatch) => {
  dispatch({ type: DEPOSIT_START });
  let axios = axiosHelper(true, false, false, true);

  const res = await axios
    .post('/transaction', data)
    .then((response) => {
      dispatch({ type: DEPOSIT_DONE });
      if (response.data.isSuccess)
        return {
          success: true,
          messageType: 'success',
          data: response.data.result,
          message: 'Successfully submitted deposit request'
        };
      return { success: false, messageType: 'error', message: response.data.message };
    })
    .catch((error) => {
      dispatch({ type: DEPOSIT_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

export const getMyActiveGateways = (data) => async (dispatch) => {
  dispatch({ type: GET_MY_ACTIVE_GATEWAYS_START });
  let axios = axiosHelper(true, false, false, false);

  const res = await axios
    .get('/my/active-gateways')
    .then((response) => {
      dispatch({ type: GET_MY_ACTIVE_GATEWAYS_END });
      if (response.data.isSuccess) {
        dispatch({ type: GET_MY_ACTIVE_GATEWAYS_END, payload: response.data.result });
        return {
          success: true,
          messageType: 'success',
          data: response.data.result,
          message: 'Successfully submitted deposit request'
        };
      }

      return { success: false, messageType: 'error', message: response.data.message };
    })
    .catch((error) => {
      dispatch({ type: GET_MY_ACTIVE_GATEWAYS_END });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

//Deposit
export const praxisDirectDeposit = (data) => async (dispatch) => {
  dispatch({ type: DEPOSIT_START });
  let axios = axiosHelper(true, false, false, true);

  const res = await axios
    .post('/transaction', data)
    .then((response) => {
      dispatch({ type: DEPOSIT_DONE });
      if (response.data.isSuccess)
        return {
          success: true,
          messageType: 'success',
          data: response.data.result,
          message: 'Successfully submitted deposit request'
        };
      return { success: false, messageType: 'error', message: response.data.message };
    })
    .catch((error) => {
      dispatch({ type: DEPOSIT_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};
//Withdrawal request
export const getDepositLink = (model, paymentType) => async (dispatch) => {
  dispatch({ type: DEPOSIT_START });
  let axios = axiosHelper(true, false, true);
  let apiEndpoint = '';
  
  if (['Master Card', 'Visa Secure'].includes(paymentType))
    apiEndpoint = '/qubepay/pay';
  else if (paymentType === 'Neteller') apiEndpoint = '/paysafe/pay';
  else if (paymentType === 'Cashu') apiEndpoint = '/cashu/pay';
  else if (paymentType === 'GateToPay') apiEndpoint = '/gatetopay/pay'
  else if (paymentType === 'Praxis') apiEndpoint = '/praxis/pay'
  
  const res = await axios
    .post(apiEndpoint, JSON.stringify(model))
    .then(async (response) => {
      
      dispatch({ type: DEPOSIT_DONE });
      if (response.data.isSuccess) {
        return {
          success: true,
          messageType: 'success',
          data: response.data.result,
          message: response.data.message || 'Success'
        };
      }
      return { success: false, messageType: 'error', message: response.data.message };
    })
    .catch((error) => {
      dispatch({ type: DEPOSIT_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

export const getCustomerGateToPayCards = (model, paymentType = 'GateToPay') => async (dispatch) => {
  dispatch({ type: GATE_TO_PAY_LIST_START });
  let axios = axiosHelper(true, false, true);
  let apiEndpoint = '';
  if (paymentType === 'GateToPay') apiEndpoint = '/gatetopay/list'
  const res = await axios
    .post(apiEndpoint, JSON.stringify(model))
    .then(async (response) => {
      dispatch({ type: GATE_TO_PAY_LIST_DONE, payload: response.data.result.cards });
      if (response.data.isSuccess) {
        return {
          success: true,
          messageType: 'success',
          data: response.data.result,
          message: response.data.message || 'Success'
        };
      }
      return { success: false, messageType: 'error', message: response.data.message };
    })
    .catch((error) => {
      dispatch({ type: DEPOSIT_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

export const gateToPayCardSelected = (model, paymentType, isDeposit = false) => async (dispatch) => {
  dispatch({ type: DEPOSIT_START });
  let axios = axiosHelper(true, false, true);
  let apiEndpoint = '';
  if (paymentType === 'GateToPay')
    apiEndpoint = `/gatetopay/${isDeposit ? 'deposit' : 'withdraw'}`;
  else
    return {
      success: false,
      messageType: 'error',
      message: 'Issue with card selection'
    };
  const res = await axios
    .post(apiEndpoint, JSON.stringify(model))
    .then(async (response) => {
      dispatch({ type: DEPOSIT_DONE });
      if (response.data.isSuccess) {
        return {
          success: true,
          messageType: 'success',
          data: response.data.result,
          message: response.data.message || 'Success'
        };
      }
      return { success: false, messageType: 'error', message: response.data.message };
    })
    .catch((error) => {
      dispatch({ type: DEPOSIT_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

//Account Types
export const getAccountTypes = (data) => async (dispatch) => {
  dispatch({ type: ACCOUNT_TYPE_START });
  let axios = axiosHelper(true, false, false, false);
  const res = await axios
    .get('my/account-types')
    .then(async (response) => {
      dispatch({ type: ACCOUNT_TYPE_DONE, payload: response.data.result });
      if (response.data.isSuccess) {
        return { success: true, messageType: 'success', message: '' };
      }
      return { success: false, messageType: 'error', message: response.data.message };
    })
    .catch((error) => {
      dispatch({ type: ACCOUNT_TYPE_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

//Account Types
export const getDemoAccountTypes = (data) => async (dispatch) => {
  dispatch({ type: DEMO_ACCOUNT_TYPE_START });
  let axios = axiosHelper(true, false, false, false);
  const res = await axios
    .get('my/demo-account-types')
    .then(async (response) => {
      dispatch({ type: DEMO_ACCOUNT_TYPE_DONE, payload: response.data.result });
      if (response.data.isSuccess) {
        return { success: true, messageType: 'success', message: '' };
      }
      return { success: false, messageType: 'error', message: response.data.message };
    })
    .catch((error) => {
      dispatch({ type: DEMO_ACCOUNT_TYPE_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

//Get IB Transactions
export const getIBTransactions = (id, limit, page = 1) => async (dispatch) => {
  dispatch({ type: GET_IB_TRANSACTIONS_START });
  let axios = axiosHelper(true);
  const res = await axios
    .get(`/ib/${id}/transactions?limit=${limit}&page=${page}`)
    .then(async (response) => {
      dispatch({ type: GET_IB_TRANSACTIONS_DONE, payload: response.data.result });
      if (response.data.isSuccess) {
        return { success: true, messageType: 'success', message: '' };
      }
      return { success: false, messageType: 'error', message: response.data.message };
    })
    .catch((error) => {
      dispatch({ type: GET_IB_TRANSACTIONS_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

//Get IB Apps
export const getIBLiveApps = (id) => async (dispatch) => {
  dispatch({ type: GET_IB_LIVE_APPS_START });
  let axios = axiosHelper(true);
  const res = await axios
    .get(`/ib/${id}/live-applications`)
    .then(async (response) => {
      dispatch({ type: GET_IB_LIVE_APPS_DONE, payload: response.data.result });
      if (response.data.isSuccess) {
        return { success: true, messageType: 'success', message: '' };
      }
      return { success: false, messageType: 'error', message: response.data.message };
    })
    .catch((error) => {
      dispatch({ type: GET_IB_LIVE_APPS_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

//Get IB Apps
export const getIBDemoApps = (id) => async (dispatch) => {
  dispatch({ type: GET_IB_DEMO_APPS_START });
  let axios = axiosHelper(true);
  const res = await axios
    .get(`/ib/${id}/demo-applications`)
    .then(async (response) => {
      dispatch({ type: GET_IB_DEMO_APPS_DONE, payload: response.data.result });
      if (response.data.isSuccess) {
        return { success: true, messageType: 'success', message: '' };
      }
      return { success: false, messageType: 'error', message: response.data.message };
    })
    .catch((error) => {
      dispatch({ type: GET_IB_DEMO_APPS_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

//Get Open Positions
export const getOpenPositionsIB = (id, limit = 5, offset = 0) => async (dispatch) => {
  dispatch({ type: GET_OPEN_POSITIONS_IB_START });
  let axios = axiosHelper(true);
  const res = await axios
    .get(`/ib/${id}/open-positions?limit=${limit}&offset=${offset}`)
    .then(async (response) => {
      dispatch({ type: GET_OPEN_POSITIONS_IB_DONE, payload: response.data.result });
      if (response.data.isSuccess) {
        return { success: true, messageType: 'success', message: '' };
      }
      return { success: false, messageType: 'error', message: response.data.message };
    })
    .catch((error) => {
      dispatch({ type: GET_OPEN_POSITIONS_IB_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

//Get Close Positions
export const getClosePositionsIB = (id, limit = 5, offset = 0) => async (dispatch) => {
  dispatch({ type: GET_CLOSE_POSITIONS_IB_START });
  let axios = axiosHelper(true);
  const res = await axios
    .get(`/ib/${id}/close-positions?limit=${limit}&offset=${offset}`)
    .then(async (response) => {
      dispatch({ type: GET_CLOSE_POSITIONS_IB_DONE, payload: response.data.result });
      if (response.data.isSuccess) {
        return { success: true, messageType: 'success', message: '' };
      }
      return { success: false, messageType: 'error', message: response.data.message };
    })
    .catch((error) => {
      dispatch({ type: GET_CLOSE_POSITIONS_IB_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

//Get Client Transactions
export const getClientTransactionsIB = async (query) => {
  let axios = axiosHelper(true);
  let url = `/ib/transactions?${qs.stringify(query)}`;
  // if (id) url += `&clientId=${id}`;

  const res = await axios
    .get(url)
    .then(async (response) => {
      if (response.data.isSuccess) {
        return { success: true, messageType: 'success', message: '', data: response.data.result };
      }
      return { success: false, messageType: 'error', message: response.data.message };
    })
    .catch((error) => {
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

//Get Commmission & Rebates
export const getCommissionsRebateIB = (id, fromDate, toDate, limit, newPage, clientType, masterMt5Account) => async (dispatch) => {
  dispatch({ type: GET_COMMISSIONS_IB_START });
  let axios = axiosHelper(true);
  let url = `/ib/statement?`;
  if (id) url += `&clientId=${id}`;
  if (fromDate) url += `&fromDate=${fromDate}`;
  if (toDate) url += `&toDate=${toDate}`;
  if (clientType) url += `&clientType=${clientType}`;
  if (masterMt5Account) url += `&masterMt5Account=${masterMt5Account}`;
  const res = await axios
    .get(url)
    .then(async (response) => {
      dispatch({ type: GET_COMMISSIONS_IB_DONE, payload: response.data.result });
      if (response.data.isSuccess) {
        return { success: true, messageType: 'success', message: '', data: response.data.result };
      }
      return { success: false, messageType: 'error', message: response.data.message };
    })
    .catch((error) => {
      dispatch({ type: GET_COMMISSIONS_IB_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

//Get Commissions By Client
export const getOrderStatementByClientIB = (id, limit, page = 1, fromDate, toDate, masterMt5Account) => async (dispatch) => {
  dispatch({ type: GET_COMMISSIONS_BY_ID_IB_START });
  let axios = axiosHelper(true);
  let url = `/ib/statement/${id}?limit=${limit}&page=${page}`;
  if (fromDate) url += `&fromDate=${fromDate}`;
  if (toDate) url += `&toDate=${toDate}`;
  if (masterMt5Account) url += `&masterMt5Account=${masterMt5Account}`;
  const res = await axios
    .get(url)
    .then(async (response) => {
      dispatch({ type: GET_COMMISSIONS_BY_ID_IB_DONE, payload: response.data.result });
      if (response.data.isSuccess) {
        return { success: true, messageType: 'success', message: '', data: response.data.result };
      }
      return { success: false, messageType: 'error', message: response.data.message };
    })
    .catch((error) => {
      dispatch({ type: GET_COMMISSIONS_BY_ID_IB_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

export default function reducer(
  state = {
    demoAccountLoading: false,
    demoAccounts: [],
    createDemoAccountLoading: false,
    liveAccountLoading: false,
    liveAccounts: [],
    createLiveAccountLoading: false,
    transactionLoading: false,
    tradersLoading: false,
    changeMT5PasswordLoading: false,
    changeLeverageLoading: false,
    withdrawalRequestLoading: false,
    depositLoading: false,
    gateToPayLoading: false,
    activeGatewaysLoading: false,
    transactions: [],
    traders: {},
    activeGateways: [],
    accountTypesLoading: false,
    accountTypes: [],
    demoAccountTypesLoading: false,
    demoAccountTypes: [],
    gateToPayAccounts: [],
    transactionIBLoading: false,
    transactionsIB: {},
    liveAppIBLoading: false,
    liveAppsIB: [],
    demoAppIBLoading: false,
    demoAppsIB: [],
    openPositionLoading: false,
    openPositions: [],
    closePositionLoading: false,
    closePositions: [],
    commissionLoading: false,
    commissions: [],
    commissionByIdLoading: false,
    commissionsById: [],
    rebateById: [],
    ibAccountLoading: false
  },
  action
) {
  switch (action.type) {
    case SET_SELECTED_ACCOUNT:
      return {
        ...state,
        selectedLiveAccount: action.payload
      };
    case SET_SELECTED_ACCOUNT_DEMO:
      return {
        ...state,
        selectedDemoAccount: action.payload
      };
    case GET_DEMO_ACCOUNT_START:
      return {
        ...state,
        demoAccountLoading: true
      };
    case GET_DEMO_ACCOUNT_DONE:
      return {
        ...state,
        demoAccountLoading: false,
        demoAccounts: action.payload
      };
    case CREATE_DEMO_APPLICATION_START:
      return {
        ...state,
        createDemoAccountLoading: true
      };
    case CREATE_DEMO_APPLICATION_DONE:
      return {
        ...state,
        createDemoAccountLoading: false
      };
    case GET_LIVE_ACCOUNT_START:
      return {
        ...state,
        liveAccountLoading: true
      };
    case GET_LIVE_ACCOUNT_DONE:
      return {
        ...state,
        liveAccountLoading: false,
        liveAccounts: action.payload
      };
    case CREATE_LIVE_APPLICATION_START:
      return {
        ...state,
        createLiveAccountLoading: true
      };
    case CREATE_LIVE_APPLICATION_DONE:
      return {
        ...state,
        createLiveAccountLoading: false
      };
    case GET_TRANSACTIONS_START:
      return {
        ...state,
        transactionLoading: true
      };
    case GET_TRANSACTIONS_DONE:
      return {
        ...state,
        transactionLoading: false,
        transactions: action.payload
      };
    case GET_TRADERS_START:
      return {
        ...state,
        tradersLoading: true
      };
    case GET_TRADERS_DONE:
      return {
        ...state,
        tradersLoading: false,
        traders: action.payload
      };

    case GET_CLOSE_POSITIONS_START:
      return {
        ...state,
        closePositionsLoading: true
      };
    case GET_CLOSE_POSITIONS_DONE:
      return {
        ...state,
        closePositionsLoading: false,
        closePositions: action.payload
      };
    case CHANGE_MT5_PASSWORD_START:
      return {
        ...state,
        changeMT5PasswordLoading: true
      };
    case CHANGE_MT5_PASSWORD_DONE:
      return {
        ...state,
        changeMT5PasswordLoading: false
      };
    case CHANGE_LEVERAGE_START:
      return {
        ...state,
        changeLeverageLoading: true
      };
    case CHANGE_LEVERAGE_DONE:
      return {
        ...state,
        changeLeverageLoading: false
      };
    case WITHDRAWAL_REQUEST_START:
      return {
        ...state,
        withdrawalRequestLoading: true
      };
    case WITHDRAWAL_REQUEST_DONE:
      return {
        ...state,
        withdrawalRequestLoading: false
      };
    case DEPOSIT_START:
      return {
        ...state,
        depositLoading: true
      };
    case DEPOSIT_DONE:
      return {
        ...state,
        depositLoading: false
      };
    case GATE_TO_PAY_LIST_START:
      return {
        ...state,
        gateToPayLoading: true
      };
    case GATE_TO_PAY_LIST_DONE:
      return {
        ...state,
        gateToPayLoading: false,
        gateToPayAccounts: action.payload
      };
    case GET_MY_ACTIVE_GATEWAYS_START:
      return {
        ...state,
        activeGatewaysLoading: true
      };

    case GET_MY_ACTIVE_GATEWAYS_END:
      return {
        ...state,
        activeGatewaysLoading: false,
        activeGateways: action.payload
      };
    case ACCOUNT_TYPE_START:
      return {
        ...state,
        accountTypesLoading: true,
      };
    case ACCOUNT_TYPE_DONE:
      return {
        ...state,
        accountTypesLoading: false,
        accountTypes: action.payload,
      };
    case DEMO_ACCOUNT_TYPE_START:
      return {
        ...state,
        demoAccountTypesLoading: true,
      };
    case DEMO_ACCOUNT_TYPE_DONE:
      return {
        ...state,
        demoAccountTypesLoading: false,
        demoAccountTypes: action.payload,
      };
    case GET_IB_TRANSACTIONS_START:
      return {
        ...state,
        transactionIBLoading: true,
      };
    case GET_IB_TRANSACTIONS_DONE:
      return {
        ...state,
        transactionIBLoading: false,
        transactionsIB: action.payload
      };
    case GET_IB_LIVE_APPS_START:
      return {
        ...state,
        liveAppIBLoading: true
      };
    case GET_IB_LIVE_APPS_DONE:
      return {
        ...state,
        liveAppIBLoading: false,
        liveAppsIB: action.payload
      };
    case GET_IB_DEMO_APPS_START:
      return {
        ...state,
        demoAppIBLoading: true
      };
    case GET_IB_DEMO_APPS_DONE:
      return {
        ...state,
        demoAppIBLoading: false,
        demoAppsIB: action.payload
      };
    case GET_OPEN_POSITIONS_IB_START:
      return {
        ...state,
        openPositionLoading: true
      };
    case GET_OPEN_POSITIONS_IB_DONE:
      return {
        ...state,
        openPositionLoading: false,
        openPositions: action.payload
      };
    case GET_CLOSE_POSITIONS_IB_START:
      return {
        ...state,
        closePositionLoading: true
      };
    case GET_CLOSE_POSITIONS_IB_DONE:
      return {
        ...state,
        closePositionLoading: false,
        closePositions: action.payload
      };
    case GET_COMMISSIONS_IB_START:
      return {
        ...state,
        commissionLoading: true
      };
    case GET_COMMISSIONS_IB_DONE:
      return {
        ...state,
        commissionLoading: false,
        commissions: action.payload
      };
    case GET_COMMISSIONS_BY_ID_IB_START:
      return {
        ...state,
        commissionByIdLoading: true
      };
    case GET_COMMISSIONS_BY_ID_IB_DONE:
      return {
        ...state,
        commissionByIdLoading: false,
        commissionsById: action.payload.commission,
        rebateById: action.payload.rebate,
      };
    case GET_IB_ACCOUNT_START:
      return {
        ...state,
        ibAccountLoading: true,
      }
    case GET_IB_ACCOUNT_DONE:
      return {
        ...state,
        ibAccountLoading: false,
        ibAccount: action.payload
      }
    case GET_CLIENTS_ACCOUNT_START:
      return {
        ...state,
        clientAccountsLoading: true
      }
    case GET_CLIENTS_ACCOUNT_DONE:
      return {
        ...state,
        clientAccountsLoading: false,
        clientAccounts: action.payload
      }

    default:
      break;
  }
  return state;
}

