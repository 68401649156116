// export const API_BASE_URL = 'https://clientportal-service-prod.azurewebsites.net/api/v1';
// export const API_BASE_URL = 'http://localhost:3005/api/v1';

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
//
// export const API_BASE_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:3005/api/v1':'https://cp-rest-service.azurewebsites.net/api/v1';

export const DEPOSIT_API_BASE_URL = process.env.REACT_APP_DEPOSIT_API_BASE_URL;


export const REGISTER_URL = process.env.REACT_APP_REGISTER_URL;
export const WEB_REGISTER_URL = process.env.REACT_APP_WEB_REGISTER_URL;
export const FILE_API_BASE_URL =
  'https://crm-rest-service-ex.azurewebsites.net/api/v1';

export const SKRILL_PAY_TO_EMAIL = 'petra.vojtylova@ruizeanmarkets.eu';
export const SKRILL_STATUS_URL = 'mailto:info@gold-era.com';
export const SKRILL_CURRENCY = 'USD';
export const SKRILL_PAYMENT_METHODS = 'WLT, ACC';
export const SKRILL_RECEIPENT_DESCRIPTION = 'Gold Era';
export const SKRIL_LOGO = '#';
export const SKRIL_LANGUAGE = 'EN';
export const SKRIL_DETAIL1_DESCRIPTION = 'Payment for:';
export const SKRIL_DETAIL1_TEXT = 'Deposit via Gold Era';
export const QUBEPAY_CHECKOUT_URL = process.env.REACT_APP_QUBEPAY_CHECKOUT_URL;
export const CASHU_CHECKOUT_URL = process.env.REACT_APP_CASHU_CHECKOUT_URL;

export const SOCKETS_URL = process.env.REACT_APP_SOCKETS_URL;

export const SHOW_GATE_TO_PAY =
  (process.env.REACT_APP_SHOW_GATE_TO_PAY &&
    process.env.REACT_APP_SHOW_GATE_TO_PAY === 'show')
    ? true
    : false;
export const SHOW_PRAXIS =
  (process.env.REACT_APP_SHOW_PRAXIS && process.env.REACT_APP_SHOW_PRAXIS === 'show')
    ? true
    : false;
