import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import configureStore from './config/configureStore';
import { Provider } from 'react-redux';
import Routes from './Routes';
import { PersistGate } from 'redux-persist/integration/react';
import ScrollToTop from './utils/ScrollToTop';
import './assets/base.scss';
import './components/common.scss';
import './i18n';
import { withNamespaces } from 'react-i18next';

const { store, persistor } = configureStore();
const state = store.getState();
class App extends Component {
  render() {
    return (
      <Provider store={store}>
        {/* <PersistGate loading={null} persistor={persistor}> */}
        {/* <BrowserRouter basename="/bamburgh-react-crypto-application-material-ui-pro/"> */}
        <BrowserRouter>
          <ScrollToTop>
            <Routes />
          </ScrollToTop>
        </BrowserRouter>
        {/* </PersistGate> */}
      </Provider>
    );
  }
}

export default App;
