import axiosHelper from '../helpers/axiosHelper';
import * as qs from 'querystring';

export const GET_BANK_ACCOUNTS_START = 'GET_BANK_ACCOUNTS_START';
export const GET_BANK_ACCOUNTS_DONE = 'GET_BANK_ACCOUNTS_DONE';
export const GET_BANK_ACCOUNT_DONE = 'GET_BANK_ACCOUNT_DONE';
export const CREATE_BANK_ACCOUNT_START = 'CREATE_BANK_ACCOUNT_START';
export const CREATE_BANK_ACCOUNT_DONE = 'CREATE_BANK_ACCOUNT_DONE';
export const DELETE_BANK_ACCOUNT_START = 'DELETE_BANK_ACCOUNT_START';
export const DELETE_BANK_ACCOUNT_DONE = 'DELETE_BANK_ACCOUNT_DONE';
export const FUNDS_TRANSFER_START = 'FUNDS_TRANSFER_START';
export const FUNDS_TRANSFER_DONE = 'FUNDS_TRANSFER_DONE';

//Get Bank accounts
export const getBankAccounts = () => async (dispatch) => {
  dispatch({ type: GET_BANK_ACCOUNTS_START });
  let axios = axiosHelper(true);
  const res = await axios
    .get('/common/bank-account/list')
    .then(async (response) => {
      dispatch({ type: GET_BANK_ACCOUNTS_DONE, payload: response.data.result.docs });
      if (response.data.isSuccess) {
        return {
          success: true,
          messageType: 'success',
          message: ''
        };
      }
      return {
        success: false,
        messageType: 'error',
        message: response.data.message
      };
    })
    .catch((error) => {
      dispatch({ type: GET_BANK_ACCOUNTS_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

//Get Bank account by id
export const getBankAccountById = (id) => async (dispatch) => {
  let axios = axiosHelper(true);
  const res = await axios
    .get('/common/bank-account/details/' + id)
    .then((response) => {
      dispatch({ type: GET_BANK_ACCOUNT_DONE, payload: response.data.result });
      if (response.data.isSuccess) {
        return {
          success: true,
          messageType: 'success',
          message: '',
          data: response.data.result
        };
      }
      return {
        success: false,
        messageType: 'error',
        message: response.data.message
      };
    })
    .catch((error) => {
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

//Create Demo Account
export const createUpdateBankAccount = (model, id) => async (dispatch) => {
  dispatch({ type: CREATE_BANK_ACCOUNT_START });
  let axios = axiosHelper(true);
  let response;
  try {
    if (id)
      response = await axios.patch(
        '/common/bank-account/update/' + id,
        qs.stringify(model)
      );
    else
      response = await axios.post(
        '/common/bank-account/create',
        qs.stringify(model)
      );

    dispatch({ type: CREATE_BANK_ACCOUNT_DONE });
    if (response.data.isSuccess) {
      await dispatch(getBankAccounts());
      return {
        success: true,
        messageType: 'success',
        message: 'Bank account created successfully.'
      };
    }
    return {
      success: false,
      messageType: 'error',
      message: response.data.errors
    };
  } catch (error) {
    dispatch({ type: CREATE_BANK_ACCOUNT_DONE });
    let message = 'Invalid request.';
    if (error.response && error.response.data)
      message = error.response.data.message;
    return { success: false, messageType: 'error', message: message };
  }
};

//Delete Bank account by id
export const deleteBankAccount = (id) => async (dispatch) => {
  dispatch({ type: DELETE_BANK_ACCOUNT_START });
  let axios = axiosHelper(true);
  const res = await axios
    .delete('/common/bank-account/remove/' + id)
    .then(async (response) => {
      dispatch({
        type: DELETE_BANK_ACCOUNT_DONE,
        payload: response.data.result
      });
      if (response.data.isSuccess) {
        await dispatch(getBankAccounts());
        return {
          success: true,
          messageType: 'success',
          message: 'Bank account deleted successfully.'
        };
      }
      return {
        success: false,
        messageType: 'error',
        message: response.data.message
      };
    })
    .catch((error) => {
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

//Funds transfer
export const fundsTransfer = (model) => async (dispatch) => {
  dispatch({ type: FUNDS_TRANSFER_START });
  let axios = axiosHelper(true, false, false, true);
  const res = await axios
    .post('transaction', model)
    .then(async (response) => {
      dispatch({ type: FUNDS_TRANSFER_DONE });
      if (response.data.isSuccess) {
        return {
          success: true,
          messageType: 'success',
          message: 'Funds transfered successfully.'
        };
      }
      return {
        success: false,
        messageType: 'error',
        message: response.data.message || response.data.errors
      };
    })
    .catch((error) => {
      dispatch({ type: FUNDS_TRANSFER_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

export const fundsTransferIb = (model) => async (dispatch) => {
  dispatch({ type: FUNDS_TRANSFER_START });
  let axios = axiosHelper(true, false, false, true);
  const res = await axios
    .post('/ib/internal-transfer', model)
    .then(async (response) => {
      dispatch({ type: FUNDS_TRANSFER_DONE });
      if (response.data.isSuccess) {
        return {
          success: true,
          messageType: 'success',
          message: 'Funds transfered successfully.'
        };
      }
      return {
        success: false,
        messageType: 'error',
        message: response.data.message || response.data.errors
      };
    })
    .catch((error) => {
      dispatch({ type: FUNDS_TRANSFER_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

export default function reducer(
  state = {
    bankAccountLoading: false,
    bankAccounts: [],
    bankAccount: {},
    createBankAccountLoading: false,
    deleteBankAccountLoading: false,
    fundsTransferLoading: false
  },
  action
) {
  switch (action.type) {
    case GET_BANK_ACCOUNTS_START:
      return {
        ...state,
        bankAccountLoading: true
      };
    case GET_BANK_ACCOUNTS_DONE:
      return {
        ...state,
        bankAccountLoading: false,
        bankAccounts: action.payload
      };
    case GET_BANK_ACCOUNT_DONE:
      return {
        ...state,
        bankAccount: action.payload
      };
    case CREATE_BANK_ACCOUNT_START:
      return {
        ...state,
        createBankAccountLoading: true
      };
    case CREATE_BANK_ACCOUNT_DONE:
      return {
        ...state,
        createBankAccountLoading: false,
        bankAccount: {}
      };
    case DELETE_BANK_ACCOUNT_START:
      return {
        ...state,
        deleteBankAccountLoading: true
      };
    case DELETE_BANK_ACCOUNT_DONE:
      return {
        ...state,
        deleteBankAccountLoading: false
      };
    case FUNDS_TRANSFER_START:
      return {
        ...state,
        fundsTransferLoading: true
      };
    case FUNDS_TRANSFER_DONE:
      return {
        ...state,
        fundsTransferLoading: false
      };
    default:
      break;
  }
  return state;
}
