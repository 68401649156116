import axiosHelper2 from '../helpers/axiosHelper2';

// journey loading
export const LOAD_IB_JOURNEY_START = 'LOAD_IB_JOURNEY_START';
export const LOAD_IB_JOURNEY_END = 'LOAD_IB_JOURNEY_END';

// journey steps
export const SET_ACTIVE_IB_STEP = 'SET_ACTIVE_IB_STEP';
export const SET_JOURNEY_IB_STEP = 'SET_JOURNEY_IB_STEP';

// modals 
export const HIDE_IB_COMPLETE_PROFILE_MODAL = 'HIDE_IB_COMPLETE_PROFILE_MODAL';
export const SHOW_IB_COMPLETE_PROFILE_MODAL = 'SHOW_IB_COMPLETE_PROFILE_MODAL';

export const HIDE_IB_QUESTIONNAIRE_MODAL = 'HIDE_IB_QUESTIONNAIRE_MODAL';
export const SHOW_IB_QUESTIONNAIRE_MODAL = 'SHOW_IB_QUESTIONNAIRE_MODAL';

export const SET_PENDING_STRUCTURES = 'SET_PENDING_STRUCTURES';
export const SET_JOURNEY_DICT = 'SET_JOURNEY_DICT';


// journey hide
export const HIDE_IB_JOURNEY = 'HIDE_IB_JOURNEY';

const initalState = {
  loadingIbJourney: false,
  activeIbStep: 0,
  skip: true,
  hideIbCompleteProfileModal: false,
  hideIbQuestionnaireModal: false,
  hideQustionnaireModal: false,
  hideJourneyBar: false
};

const journeyArrToObj = (arr=[]) => {
  const dic = {
    PERSONAL_INFO: false,
    QUESTIONNAIRE: false,
    KYC_UPLOAD: false,
    KYC_APPROVAL: false,
    KYC_REJECTION: false,
    AGREEMENT_REQUEST: false,
    AGREEMENT_GRANTED: false,
    IB_REQUEST: false
  }
  arr.forEach(node => {
    dic[node.type] = node.status;
  });

  return dic;
}

export const getIBJourney = (user) => async (dispatch) => {
  dispatch({ type: LOAD_IB_JOURNEY_START });
  let axios = axiosHelper2(true);
  axios
    .get(`/ib/journey`)
    .then(async (response) => {
      const ibJourney = response.data.result;
      dispatch({
        type: LOAD_IB_JOURNEY_END,
        payload: ibJourney.journeys
      });
      //    console.log('ibJourney ', ibJourney)
      


      /**
       * revaming joiurneuy ui
       */
      const pendingStructures = ibJourney.pendingStructures;
      const journeyDict = journeyArrToObj(ibJourney.journeys);
      const journeyPhase = {
        step: 1,
        message: '',
        type: 'yellow', // orange, red
        clickable: true,
      }
      if(!journeyDict.PERSONAL_INFO){
        journeyPhase.step = 1;
        journeyPhase.message = 'Update Profile Information';
      }
      else if(!journeyDict.QUESTIONNAIRE){
        journeyPhase.step = 2;
        journeyPhase.message = 'Fill IB Questionaire';
      }
      else if(!journeyDict.KYC_UPLOAD || !journeyDict.KYC_APPROVAL || journeyDict.KYC_REJECTION){
        journeyPhase.step = 3;
        if(!journeyDict.KYC_UPLOAD) {
          journeyPhase.message = 'Upload the documents';
        }
        else if(journeyDict.KYC_REJECTION) {
          journeyPhase.message = 'Documents rejected';
          journeyPhase.type = 'red';
        }
        else if(!journeyDict.KYC_APPROVAL) {
          journeyPhase.message = 'Documents pending for Approval';
          journeyPhase.type = 'orange';
        }
      }
      else if(!journeyDict.AGREEMENT_REQUEST){
        journeyPhase.step = 4;
        if(!journeyDict.IB_REQUEST){
          journeyPhase.message = 'Waiting for Approval of IB Request';
          journeyPhase.clickable = false;
        }
        else{
          journeyPhase.message = 'Submit the Agreement';
        }
        
      }
      else if(!journeyDict.AGREEMENT_GRANTED){
        journeyPhase.step = 5;
        journeyPhase.message = 'Waiting for Approval of Agreement';
        journeyPhase.type = 'orange';
      }
      else{
        journeyPhase.step = 6;
        journeyPhase.message = 'Approved';
      }
      
      dispatch({
        type: SET_JOURNEY_IB_STEP,
        payload: journeyPhase,
      });
      dispatch(setActiveStep(journeyPhase.step, journeyPhase.step));
      dispatch({
        type: SET_PENDING_STRUCTURES,
        payload: pendingStructures,
      });
      dispatch({
        type: SET_JOURNEY_DICT,
        payload: journeyDict,
      });
      

       /**
        * revamp end
        */
    })
    .catch((error) => {
      dispatch({ type: LOAD_IB_JOURNEY_END, payload: [] });
    });
};

export const setActiveStep = (index, journeyStep) => async (dispatch) => {
  if (journeyStep > index) {
    localStorage.setItem('activeIbStep', journeyStep);
    dispatch({ type: SET_ACTIVE_IB_STEP, payload: {journeyStep} });
  } else {
    localStorage.setItem('activeIbStep', index);
    dispatch({ type: SET_ACTIVE_IB_STEP, payload: index });
  }
  if (index > journeyStep) {
    dispatch({ type: SET_JOURNEY_IB_STEP, payload: { step: index, message: '', type: 'yellow' } });
  }
};


export const hideCompleteProfileIbModal = () => async (dispatch) => {
  dispatch({ type: HIDE_IB_COMPLETE_PROFILE_MODAL })
}

export const showCompleteProfileIbModal = () => async (dispatch) => {
  dispatch({ type: SHOW_IB_COMPLETE_PROFILE_MODAL })
}

export const hideQuestionnaireIbModal = () => async (dispatch) => {
  dispatch({ type: HIDE_IB_QUESTIONNAIRE_MODAL })
}

export const showQuestionnaireIbModal = () => async (dispatch) => {
  dispatch({ type: SHOW_IB_QUESTIONNAIRE_MODAL })
}

export default function reducer(state = initalState, action) {
  switch (action.type) {
    case LOAD_IB_JOURNEY_START:
      return {
        ...state,
        loadingIbJourney: true
      };
    case LOAD_IB_JOURNEY_END:
      return {
        ...state,
        loadingIbJourney: false,
        journeys: action.payload
      };
    case SET_ACTIVE_IB_STEP:
      return {
        ...state,
        activeIbStep: action.payload
      };
    case SET_JOURNEY_IB_STEP:
      return {
        ...state,
        journeyStep: action.payload.step,
        message: action.payload.message,
        type: action.payload.type,
      };
    case HIDE_IB_COMPLETE_PROFILE_MODAL:
      return {
        ...state,
        hideIbCompleteProfileModal: true
      };
    case SHOW_IB_COMPLETE_PROFILE_MODAL:
      return {
        ...state,
        hideIbCompleteProfileModal: false
      };
    case HIDE_IB_QUESTIONNAIRE_MODAL:
      return {
        ...state,
        hideIbQuestionnaireModal: true
      };
    case SHOW_IB_QUESTIONNAIRE_MODAL:
      return {
        ...state,
        hideIbQuestionnaireModal: false
      };
    case SET_PENDING_STRUCTURES:
      return {
        ...state,
        pendingStructures: action.payload
      };
    case SET_JOURNEY_DICT:
      return {
        ...state,
        journeyDict: action.payload
      };
    case HIDE_IB_JOURNEY:
      return {
        ...state,
        hideJourneyBar: true
      };
    default:
      break;
  }
  return state;
}
