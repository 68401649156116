import axios from 'axios';
import AuthHelper from '../helpers/authHelper';
import * as settings from '../settings';

// axios global configuration
const axiosInstance = (isAuth, isFile, isDeposit, isWire=false) => {
  // if (isFile) {
  //   return axios.create({
  //     baseURL: settings.API_BASE_URL,
  //     headers: { 'Content-Type': 'multipart/form-data' }
  //   });
  // }

  let headers;
  if (isDeposit || isWire) headers = { 'Content-Type': 'application/json' };
  else headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
  if(isFile){
    headers = { 'Content-Type': 'multipart/form-data' };
  }

  if (isAuth) {
    const token = AuthHelper.token;
    if (token) headers.Authorization = token;
  }

  let api = axios.create({
    baseURL: isDeposit ? settings.DEPOSIT_API_BASE_URL : settings.API_BASE_URL,
    headers: headers
  }); 

  api.interceptors.response.use((response) => response, (error) => {
    if(error.response && error.response.status === 403){
      AuthHelper.logout();
    }
    throw error;
  });

  return  api;
};
export default axiosInstance;


