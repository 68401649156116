import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { SvgIcon } from '@material-ui/core';

import CYSEC from '../../assets/images/custom/CYSEC.webp';
import FSC from '../../assets/images/custom/FSC.webp';

import GoldEraLogo from '../../assets/images/custom/gold-era-full.png';

import { withNamespaces } from 'react-i18next';

import { ReactComponent as FbIcon } from './icons/fb.svg';
import { ReactComponent as InIcon } from './icons/in.svg';
import { ReactComponent as LnIcon } from './icons/ln.svg';
import { ReactComponent as TwIcon } from './icons/tw.svg';
import { ReactComponent as YtIcon } from './icons/yt.svg';

const Footer = (props) => {
  const t = props.t;
  const { footerShadow, footerBgTransparent } = props;

  return (
    <>
      <Grid container spacing={0} justify="center" alignItems="center">
        <Grid item lg={10} md={12}>
          <div
            className={clsx('app-footer text-black-50 p-3 px-md-5 py-md-4', {
              'app-footer--shadow': footerShadow,
              'app-footer--opacity-bg': footerBgTransparent
            })}>
            <div className="app-footer--first text-dark">
              <p className="font-size-xs">{t('footer_long_description')}</p>
            </div>
            <div className="divider"></div>
            <div className="border-top pt-3 pb-3 d-md-flex justify-content-between copy-right text-blue-dark">
              {/* <p className="text-primary font-size-md">
                { ' '}
              </p> */}
              <p className="text-primary mb-0" style={{ fontSize: '0.7rem' }}>
                <span>
                  © {t('Copyright')} 2021{' '}
                  <a href="https://gold-era.com/" target="_blank">
                    <img
                      src={GoldEraLogo}
                      alt="Gold Era"
                      style={{
                        width: '16px',
                        height: '23px',
                        paddingBottom: '7px'
                      }}
                    />
                  </a>
                </span>
                {' , '}
                {t('footer_rights_reserved')}
              </p>
              {/* <div style={{ fontSize: "0.9rem" }}>
                <a
                  href="https://www.linkedin.com/company/#"
                  target="_blank"
                  rel="noreferrer"
                  className="ml-4 text-blue-dark">
                  <SvgIcon fontSize="inherit">
                    <LnIcon />
                  </SvgIcon>
                </a>
                <a
                  href="https://www.facebook.com/#/"
                  target="_blank"
                  rel="noreferrer"
                  className="ml-4 text-blue-dark">
                  <SvgIcon fontSize="inherit">
                    <FbIcon />
                  </SvgIcon>
                </a>
                <a
                  href="https://twitter.com/#"
                  target="_blank"
                  rel="noreferrer"
                  className="ml-4 text-blue-dark">
                  <SvgIcon fontSize="inherit">
                    <TwIcon />
                  </SvgIcon>
                </a>
                <a
                  href="https://www.instagram.com/#/"
                  target="_blank"
                  rel="noreferrer"
                  className="ml-4 text-blue-dark">
                  <SvgIcon fontSize="inherit">
                    <InIcon />
                  </SvgIcon>
                </a>
                <a
                  href="https://www.youtube.com/channel/#"
                  target="_blank"
                  rel="noreferrer"
                  className="ml-4 text-blue-dark">
                  <SvgIcon fontSize="inherit">
                    <YtIcon />
                  </SvgIcon>
                </a>
              </div> */}
            </div>
            <div className="divider"></div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

const mapStateToProps = (state) => ({
  footerFixed: state.ThemeOptions.footerFixed,
  footerShadow: state.ThemeOptions.footerShadow,
  footerBgTransparent: state.ThemeOptions.footerBgTransparent
});

export default withNamespaces()(connect(mapStateToProps)(Footer));
