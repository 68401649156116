import ClientCookies from 'js-cookie';

class Cookies {
  construct() {
    this.cookie = {};
    this.cookies = {};
  }

  init(req, res) {}

  get(name) {
    return this.cleanVal(ClientCookies.get(name));
  }

  set(index, value, options = null) {
    if (options) ClientCookies.set(index, value, options);
    else ClientCookies.set(index, value);
  }

  cleanVal(val) {
    if (val === null || typeof val === 'undefined') return '';
    else return val;
  }
}

export default new Cookies();
