import socketIOClient from "socket.io-client";
import { SOCKETS_URL } from '../settings'

let socket;
let notificationEmitter;
export const initSocket = () => {
    socket = socketIOClient(SOCKETS_URL, {transports: ['websocket']}); // websocket, polling
    // start();
    return socket;
}

export const setNotificationEmitter = (notificationEmitter) => {
    notificationEmitter = notificationEmitter
}

export const readNotifications = (customerId) => {
    socket.emit('readNotifications', { customerId });
}

export const startFunctions = (gotNewNotification, addAll) => {
    socket.on('message', (message, callback) => {
        gotNewNotification(message)
    });
    socket.on('notification', (message, callback) => {
        console.log('got notification ', message)
        gotNewNotification(message)
    });
    socket.on('allNotifications', (message, callback) => {
        console.log('got allNotifications ', message)
        addAll(message.notifications)
    });
}
