import { combineReducers } from 'redux';
import ThemeOptions from './ThemeOptions';
import Configs from './Configs';
import Auth from './Auth';
import Accounts from './Accounts';
import BankAccounts from './BankAccounts';
import User from './User';
import Documents from './Documents';
import Partnership from './Partnership';
import AccountJourney from './AccountJourney';
import IBJourney from './IBJourney';
import Activities from './Activities';
import Dashboard from './Dashboard';
import Notification from './Notification';

const reducers =  {
  ThemeOptions,
  Configs,
  Auth,
  Accounts,
  BankAccounts,
  User,
  Documents,
  Partnership,
  AccountJourney,
  IBJourney,
  Activities,
  Dashboard,
  Notification,
};


export default combineReducers({...reducers});
