import React, { useState } from 'react';
import Avatar from 'react-avatar';
import { withRouter } from 'react-router-dom';
import AuthHelper from '../../helpers/authHelper';
import { Link } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import { Menu, Button, List, ListItem } from '@material-ui/core';

// import avatar3 from '../../assets/images/avatars/avatar3.jpg';
import avatar from '../../assets/images/custom/user.png';
import CountUp from 'react-countup';

import BusinessCenterTwoToneIcon from '@material-ui/icons/BusinessCenterTwoTone';
import SettingsTwoToneIcon from '@material-ui/icons/SettingsTwoTone';
import VerifiedUserTwoToneIcon from '@material-ui/icons/VerifiedUserTwoTone';
import ExitToAppTwoToneIcon from '@material-ui/icons/ExitToAppTwoTone';
import { withNamespaces } from 'react-i18next';

const HeaderUserbox = (props) => {
  const { t } = props;
  const { Accounts } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logOut = (e) => {
    e.preventDefault();
    AuthHelper.logout(props.history);
  };

  const user = AuthHelper.user;

  const getTotalBalance = () => {
    let balance = 0;
    if (Accounts && Accounts.liveAccounts) {
      for (let index = 0; index < Accounts.liveAccounts.length; index++) {
        const element = Accounts.liveAccounts[index];
        if (element.mt5) {
          if (element.mt5.Balance) {
            balance += parseFloat(element.mt5.Balance);
          }
        }
      }
    }
    return balance;
  };

  return (
    <>
      <Button
        variant="text"
        onClick={handleClick}
        className="btn-transition-none text-left ml-2 p-0 bg-transparent d-flex align-items-center"
        disableRipple>
        <div className="d-block p-0 avatar-icon-wrapper">
          {/* <StyledBadge
            overlap="circle"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            badgeContent=" "
            classes={{ badge: 'bg-success badge-circle border-0' }}
            variant="dot"> */}
          <div className="d-flex align-items-center cursor-pointer">
            <Avatar
              color="#1e64b4"
              size={44}
              round="22px"
              name={user.firstName + ' ' + user.lastName}
              maxInitials={2}
            />
            {/* </StyledBadge> */}
            <span className="d-none d-md-inline-block mx-2">
              {user.firstName + ' ' + user.lastName}
            </span>
          </div>
        </div>
        <div className="d-none pl-2">
          {/* <div className="d-none d-xl-block pl-2"> */}
          <span className="text-success">
            <small>Account verified</small>
          </span>
          <div className="font-weight-bold">
            {user.firstName + ' ' + user.lastName}
          </div>
        </div>
        <span className="px-1">
          <KeyboardArrowDownIcon className="opacity-5" />
        </span>
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        open={Boolean(anchorEl)}
        classes={{ list: 'p-0' }}
        onClose={handleClose}>
        <div className="dropdown-menu-xl overflow-hidden p-0">
          <div className="d-flex p-4">
            <Avatar
              color="#1e64b4"
              size={44}
              round="22px"
              name={user.firstName + ' ' + user.lastName}
              maxInitials={2}
              textSizeRatio={1.5}
            />
            <div className="mx-3">
              <h6 className="font-weight-bold mb-1 text-black">
                {user.firstName + ' ' + user.lastName}
              </h6>
              <p className="text-black-50 mb-0">{user.email}</p>
            </div>
          </div>
          <div className="divider" />
          <div className="divider" />
          <div className="bg-secondary d-flex align-items-center flex-column py-4">
            <div className="display-3 mb-0 text-center font-weight-bold">
              <small className="opacity-6">$</small>
              <span className="pl-1">
                <CountUp
                  start={0}
                  end={getTotalBalance()}
                  duration={3}
                  separator=""
                  delay={1}
                  decimals={2}
                  decimal="."
                  prefix=""
                  suffix=""
                />
                {/* <small>
                  <sup>.65</sup>
                </small> */}
              </span>
            </div>
            <small className="text-center font-weight-bold opacity-6 text-uppercase">
              {t('Total balance')}
            </small>
          </div>
          <div className="divider" />
          <List
            component="div"
            className="nav-neutral-first nav-pills-rounded flex-column p-3">
            {/* <ListItem
              component="a"
              button
              href="#/"
              onClick={(e) => e.preventDefault()}>
              <div className="mr-2">
                <SettingsTwoToneIcon />
              </div>
              <span className="font-size-md">Settings</span>
            </ListItem> */}
            <ListItem component={Link} to="/User-Profile">
              <div className="mr-2">
                <VerifiedUserTwoToneIcon />
              </div>
              <span className="font-size-md">{t('Profile')}</span>
            </ListItem>
            {/* <ListItem
              component="a"
              button
              href="#/"
              onClick={(e) => e.preventDefault()}>
              <div className="mr-2">
                <BusinessCenterTwoToneIcon />
              </div>
              <span className="font-size-md">Wallets</span>
            </ListItem> */}
          </List>
          <div className="divider" />
          <List
            component="div"
            className="nav-neutral-danger nav-pills-rounded flex-column p-3">
            <ListItem component="a" button href="#/" onClick={(e) => logOut(e)}>
              <div className="mr-2">
                <ExitToAppTwoToneIcon />
              </div>
              <span>{t('Log out')}</span>
            </ListItem>
          </List>
        </div>
      </Menu>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    Accounts: state.Accounts,
    Configs: state.Configs
  };
};
// const HeaderUserboxWithRouter = withRouter(HeaderUserbox);
// export default HeaderUserboxWithRouter;

export default withNamespaces()(connect(mapStateToProps, {})(HeaderUserbox));
