import loginLogo from "assets/images/custom/gold-era-full.png";
import sidebarLogo from "assets/images/custom/gold-era-full.png";
import customLogo from "assets/images/custom/gold-era-full.png";
import loginBg from "assets/images/login/wave-6.e1fad8d9.png";
export const app_config = {
  clientName: "Gold Era",
  loginLogo: loginLogo,
  loginBg: loginBg,
  sidebarLogo: sidebarLogo,
  customLogo: customLogo,
  sidebarTitle: "Gold Era Client Portal",
  webTraderServers: '["GoldEraTrading-Demo", "GoldEraTrading-Live"]',
  webTraderServer: "AccuindexLimited-Demo",
  webTraderVersion: 5,
  webTraderUtmSource: "www.gold-era.com",
  contactUs: "https://goldera-cloud.web.app/#/contact",
  linkDesktop:
    "https://download.mql5.com/cdn/web/18942/mt5/golderatrading5setup.exe",
  linkAndroid:
    "https://download.mql5.com/cdn/mobile/mt5/android?server=GoldEraTrading-Demo,GoldEraTrading-Live",
  linkIos:
    "https://download.mql5.com/cdn/mobile/mt5/ios?server=GoldEraTrading-Demo,GoldEraTrading-Live",
};
