import axiosHelper2 from '../helpers/axiosHelper2';

// journey loadingds
export const LOAD_ACCOUNT_JOURNEY_START = 'LOAD_ACCOUNT_JOURNEY_START';
export const LOAD_ACCOUNT_JOURNEY_END = 'LOAD_ACCOUNT_JOURNEY_END';

// platform downloaded
export const POST_PLATFORM_DOWNLOADED_START = 'POST_PLATFORM_DOWNLOADED_START';
export const POST_PLATFORM_DOWNLOADED_END = 'POST_PLATFORM_DOWNLOADED_END';

// journey steps
export const SET_ACTIVE_COMPLETE_PROFILE = 'SET_ACTIVE_COMPLETE_PROFILE';
export const SET_ACTIVE_OPEN_ACCOUNT = 'SET_ACTIVE_OPEN_ACCOUNT';
export const SET_ACTIVE_KYC_APPROVAL = 'SET_ACTIVE_KYC_APPROVAL';
export const SET_ACTIVE_DEPOSIT_FUNDS = 'SET_ACTIVE_DEPOSIT_FUNDS';
export const SET_ACTIVE_START_TRADING = 'SET_ACTIVE_START_TRADING';

export const SET_ACTIVE_STEP = 'SET_ACTIVE_STEP';
export const SET_JOURNEY_STEP = 'SET_JOURNEY_STEP';
export const SET_SHOW_ACCOUNT_CREATE = 'SET_SHOW_ACCOUNT_CREATE';

export const HIDE_COMPLETE_PROFILE_MODAL = 'HIDE_COMPLETE_PROFILE_MODAL';
export const HIDE_OPEN_ACCOUNT_MODAL = 'HIDE_OPEN_ACCOUNT_MODAL';

export const SHOW_COMPLETE_PROFILE_MODAL = 'SHOW_COMPLETE_PROFILE_MODAL';
export const SHOW_OPEN_ACCOUNT_MODAL = 'SHOW_OPEN_ACCOUNT_MODAL';

export const HIDE_JOURNEY_BAR = 'HIDE_JOURNEY_BAR';


export const getDefaultJourney = (user) => async (dispatch) => {
  dispatch({ type: LOAD_ACCOUNT_JOURNEY_START });
  let axios = axiosHelper2(true);
  axios
    .get(`/my/journey-status`)
    .then(async (response) => {
     const customerJourney = response.data.result;
       dispatch({ type: LOAD_ACCOUNT_JOURNEY_END, payload: customerJourney.journeys });
    //    console.log('customerJourney ', customerJourney)
       const falseJourneys = customerJourney.journeys.filter(obj => obj.status === false);
       const trueJourneys = customerJourney.journeys.filter(obj => obj.status === true);
        if(trueJourneys.length > 0 ){

            const isProfileInfoAdded = customerJourney.journeys.filter(obj => obj.status === true && obj.type === 'PERSONAL_INFO');
            const isTradeAccountOpen = customerJourney.journeys.filter(obj => obj.status === true && obj.type === 'TRADING_ACCOUNT');
            let activeIndex = trueJourneys[trueJourneys.length-1].order;
            let activeStep = localStorage.getItem('activeStep')? parseInt(localStorage.getItem('activeStep')): 0;

            if(isProfileInfoAdded.length> 0 &&isTradeAccountOpen.length> 0 ){
                activeIndex = activeIndex + 1;
            }
            else{
                if(isProfileInfoAdded.length === 0){
                    activeIndex = 1;
                    activeStep = 1;
                }
                else{
                    activeIndex = 2;
                    activeStep = 2;
                }
            }



            dispatch({ type: SET_JOURNEY_STEP, payload: activeStep > activeIndex? activeStep:  activeIndex})
        }
        else{
            dispatch({ type: SET_JOURNEY_STEP, payload: 1})
        }
        if(falseJourneys && falseJourneys.length > 0){
            dispatch({ type: SET_ACTIVE_STEP, payload: falseJourneys[0].order })
        }

        if(falseJourneys && falseJourneys.length === 0){
            dispatch({ type: SET_ACTIVE_STEP, payload: 0 })
            dispatch({ type: HIDE_JOURNEY_BAR })
        }



    })
    .catch((error) => {
        dispatch({ type: LOAD_ACCOUNT_JOURNEY_END, payload: [] });
    });
};

export const platformDownloaded = () => async (dispatch) => {
    dispatch({ type: POST_PLATFORM_DOWNLOADED_START });
    let axios = axiosHelper2(true);
    axios
      .post(`/common/platform-download`,{})
      .then(async (response) => {
        dispatch({ type: POST_PLATFORM_DOWNLOADED_END });
        dispatch(getDefaultJourney())
      })
      .catch((error) => {
        dispatch({ type: POST_PLATFORM_DOWNLOADED_END });
      });
  };

export const setActiveStep = (index, journeyStep) => async (dispatch) => {
    if(journeyStep> index){
        localStorage.setItem('activeStep', journeyStep)
        dispatch({ type: SET_ACTIVE_STEP, payload: journeyStep });

    }
    else{
        localStorage.setItem('activeStep', index)
        dispatch({ type: SET_ACTIVE_STEP, payload: index });

    }
    if(index> journeyStep){
        dispatch({ type: SET_JOURNEY_STEP, payload: index });
    }

}

export const setShowCreateAccount = () => async (dispatch) => {
    localStorage.setItem('showCreateAccount', true)
    dispatch({ type: SET_SHOW_ACCOUNT_CREATE, payload: true })
}

export const hideCompleteProfileModal = () => async (dispatch) => {
    dispatch({ type: HIDE_COMPLETE_PROFILE_MODAL })
}

export const hideOpenAccountModal = () => async (dispatch) => {
    dispatch({ type: HIDE_OPEN_ACCOUNT_MODAL })
    localStorage.setItem('showCreateAccount', false)
    dispatch({ type: SET_SHOW_ACCOUNT_CREATE, payload: false })
}

export const showCompleteProfileModal = () => async (dispatch) => {
    dispatch({ type: SHOW_COMPLETE_PROFILE_MODAL })
}

export const showOpenAccountModal = () => async (dispatch) => {
    dispatch({ type: SHOW_OPEN_ACCOUNT_MODAL })
}

export const openNewAccount = (journeys, activeIndex) => async(dispatch) =>{
    if(!journeys || journeys.length === 0 ||  journeys[0].status === false ){
        // dispatch()
        dispatch(setActiveStep(1))
        dispatch(showCompleteProfileModal())
    }
    else{
        if(activeIndex> 2){
            dispatch(setShowCreateAccount())
        }
        else{
            dispatch(setActiveStep(2))
        }

        dispatch(showOpenAccountModal())
    }

    // if(AccountJourney){
    //     if(!AccountJourney.journeys || AccountJourney.journeys.length === 0){

    //     }
    // }
}

// export const gotoCompleteProfile = () => async (dispatch) => {
//     dispatch({ type: SET_ACTIVE_COMPLETE_PROFILE });
// };

// export const gotoOpenAccount = () => async (dispatch) => {
//     dispatch({ type: SET_ACTIVE_OPEN_ACCOUNT });
// };

// export const gotoKYCApproval = () => async (dispatch) => {
//     dispatch({ type: SET_ACTIVE_KYC_APPROVAL });
// };

// export const gotoDepositFunds = () => async (dispatch) => {
//     dispatch({ type: SET_ACTIVE_DEPOSIT_FUNDS });
// };

// export const gotoStartTrading = () => async (dispatch) => {
//     dispatch({ type: SET_ACTIVE_START_TRADING });
// };


//-----------------------------------------

export default function reducer(
  state = {
    accountJourneyLoading: false,
    activeStep: 0,
    skip: true,
    hideCompleProfileModal: false,
    hideCreateAccountModal: false,
    hideJourneyBar: false,
  },
  action
) {
  switch (action.type) {
    // Sidebar

    case LOAD_ACCOUNT_JOURNEY_START:
      return {
        ...state,
        accountJourneyLoading: true
      };
    case LOAD_ACCOUNT_JOURNEY_END:
      return {
        ...state,
        accountJourneyLoading: false,
        journeys: action.payload
      };
    case SET_ACTIVE_STEP:
        return {
            ...state,
            activeStep: action.payload
          };
    case HIDE_JOURNEY_BAR:
        return {
            ...state,
            hideJourneyBar: true,
        };


    case SET_SHOW_ACCOUNT_CREATE:
        return {
            ...state,
            showCreateAccount: action.payload
            };

    case SET_JOURNEY_STEP:
        return {
            ...state,
            journeyStep: action.payload
            };
    case HIDE_COMPLETE_PROFILE_MODAL:
        return {
            ...state,
            hideCompleProfileModal: true
        }
    case HIDE_OPEN_ACCOUNT_MODAL:
        return {
            ...state,
            hideCreateAccountModal: true
        }
    case SHOW_COMPLETE_PROFILE_MODAL:
        return {
            ...state,
            hideCompleProfileModal: false
        }
    case SHOW_OPEN_ACCOUNT_MODAL:
        return {
            ...state,
            hideCreateAccountModal: false,
            showCreateAccount: true
        }
    default:
      break;
  }
  return state;
}
