import axiosHelper from '../helpers/axiosHelper';

export const ACTIVITIES_START = 'ACTIVITIES_START';
export const ACTIVITIES_DONE = 'ACTIVITIES_DONE';

export const getActivities = (page) => async (dispatch) => {
  dispatch({ type: ACTIVITIES_START });
  let axios = axiosHelper(true);
  const res = await axios
    .get(`/my/activities?page=${page}&isStatus=false`)
    .then(async (response) => {
      dispatch({ type: ACTIVITIES_DONE, payload: response.data.result });
      if (response.data.isSuccess) {
        return {
          success: true,
          messageType: 'success',
          message: ''
        };
      }
      return {
        success: false,
        messageType: 'error',
        message: response.data.message
      };
    })
    .catch((error) => {
      dispatch({ type: ACTIVITIES_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

export default function reducer(
  state = {
    loading: false,
    activities: [],
    page: 1,
    totalPages: 1,
    limit: 10
  },
  action
) {
  switch (action.type) {
    case ACTIVITIES_START:
      return {
        ...state,
        loading: true
      };
    case ACTIVITIES_DONE:
      return {
        ...state,
        loading: false,
        activities: action.payload.docs,
        page: action.payload.page,
        totalPages: action.payload.totalPages,
        limit: action.payload.limit
      };
    default:
      break;
  }
  return state;
}
