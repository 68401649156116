import axiosHelper from '../helpers/axiosHelper';

export const GET_CONFIG_START = 'GET_CONFIG_START';
export const GET_CONFIG_DONE = 'GET_CONFIG_DONE';
export const GET_ACCOUNT_TYPE_DONE = 'GET_ACCOUNT_TYPE_DONE';
export const GET_APPLICATION_TYPE_DONE = 'GET_APPLICATION_TYPE_DONE';
export const GET_DOCUMENT_TYPE_DONE = 'GET_DOCUMENT_TYPE_DONE';
export const GET_KYC_TYPE_DONE = 'GET_KYC_TYPE_DONE';
export const GET_COUNTRIES_DONE = 'GET_COUNTRIES_DONE';
export const GET_CURRENCIES_DONE = 'GET_CURRENCIES_DONE';
export const SET_MOBILE = 'SET_MOBILE';

//Get Configs
export const getConfigs = (data) => async (dispatch, getState) => {
  dispatch({ type: GET_CONFIG_START });
  let axios = axiosHelper(false);
  
  const res = await axios
    .get('/common/settings?data=' + data)
    .then(async (response) => {
      
      if (data === 'accountTypes') {
        dispatch({
          type: GET_ACCOUNT_TYPE_DONE,
          payload: response.data.result
        });
      } else if (data === 'applicationTypes') {
        dispatch({
          type: GET_APPLICATION_TYPE_DONE,
          payload: response.data.result
        });
      } else if (data === 'documentTypes') {
        dispatch({
          type: GET_DOCUMENT_TYPE_DONE,
          payload: response.data.result
        });
      } else if (data === 'kycRequirements') {
        let clientType = getState().User.profile.clientType
        let profile = getState().User.profile
        let kycReqArr = response.data.result.filter(v => v.clientTypes.includes(clientType))

        if(clientType === 8){

          if(profile.shareHolders.length === 0 && !profile.authorizedPerson){
            let removeItems = ["ID_PROOF","ADDRESS_PROOF"]
            kycReqArr = kycReqArr.filter((v)=> !removeItems.includes(v.type))
          }
        }

        dispatch({
          type: GET_KYC_TYPE_DONE,
          payload: kycReqArr || response.data.result
        });
      } else if (data === 'countries') {
        dispatch({
          type: GET_COUNTRIES_DONE,
          payload: response.data.result
        });
      }
      if (response.data.isSuccess) {
        return {
          success: true,
          messageType: 'success',
          message: '',
          data: response.data.result
        };
      }
      return {
        success: false,
        messageType: 'error',
        message: response.data.message
      };
    })
    .catch((error) => {
      dispatch({ type: GET_CONFIG_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

export const getCurrencies = () => async (dispatch) => {
  dispatch({ type: GET_CONFIG_START });
  let axios = axiosHelper(false);
  const res = await axios
    .get('/common/get-currencies')
    .then(async (response) => {
      dispatch({
        type: GET_CURRENCIES_DONE,
        payload: response.data.result
      });
      return {
        success: false,
        messageType: 'error',
        message: response.data.message
      };
    })
    .catch((error) => {
      dispatch({ type: GET_CONFIG_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

export const setMobile = () => async (dispatch) => {
  dispatch({ type: SET_MOBILE });
}


export default function reducer(
  state = {
    configLoading: false,
    applicationTypes: [],
    accountTypes: [],
    documentTypes: [],
    leverages: ['1:400'],
    currencies: [],
    countries: [],
    mobile: false,
  },
  action
) {
  switch (action.type) {
    case GET_CONFIG_START:
      return {
        ...state,
        configLoading: true
      };
    case GET_CONFIG_DONE:
      return {
        ...state,
        configLoading: false
      };
    case GET_APPLICATION_TYPE_DONE:
      return {
        ...state,
        configLoading: false,
        applicationTypes: action.payload
      };
    case GET_ACCOUNT_TYPE_DONE:
      return {
        ...state,
        configLoading: false,
        accountTypes: action.payload
      };
    case GET_DOCUMENT_TYPE_DONE:
      return {
        ...state,
        configLoading: false,
        documentTypes: action.payload
      };
    case GET_COUNTRIES_DONE:
      return {
        ...state,
        configLoading: false,
        countries: action.payload
      };
    case GET_KYC_TYPE_DONE:
      return {
        ...state,
        configLoading: false,
        kycRequirements: action.payload
      };
    case GET_CURRENCIES_DONE:
      return {
        ...state,
        configLoading: false,
        currencies: action.payload
      };
    case SET_MOBILE:
      return {
        ...state,
        mobile: true,
      }
    default:
      break;
  }
  return state;
}
