import { compose, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import reducers from '../reducers';


//const initialState = {};
//Useing redux thunk middleware
const middlewares = [thunk];
const composer = process.env.NODE_ENV === 'development' ? (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose) : compose;

const persistConfig = {
  key: 'cp_root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, reducers)

export default () => {
  let store = createStore(
    reducers,
    composer(
        applyMiddleware(...middlewares),
        // other store enhancers if any
    ),
    
  );

  let persistor = persistStore(store)

  return { store, persistor }
}
