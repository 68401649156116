import moment from 'moment';

export const formatDate = (str) => {
  return moment(str).format('llll');
};

export const formatTime = (str) => {
  if (!str) return '';
  return moment.unix(str).format('hh:mm A');
};

export const formatNumber = (number, fixDecimal = false, decimalSize = 2) => {
  if (isNaN(number)) return fixDecimal ? '--.--' : '--';
  let num = number;
  if (fixDecimal) {
    num = parseFloat(num);
    num = num.toFixed(decimalSize);
  }
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

export const getCustomerType = (value = 0) => {
  let rightsNum = value;
  const output = {
    isClient: false,
    isIb: false,
    isJoint: false,
    isCorporate: false,
    isCorporateIb: false
  };
  if (rightsNum === 0) return output;
  if (rightsNum > 0 && Math.floor(rightsNum / 16) >= 1)
    output.isCorporateIb = true;
  rightsNum %= 16;
  if (rightsNum > 0 && Math.floor(rightsNum / 8) >= 1)
    output.isCorporate = true;
  rightsNum %= 8;
  if (rightsNum > 0 && Math.floor(rightsNum / 4) >= 1) output.isJoint = true;
  rightsNum %= 4;
  if (rightsNum > 0 && Math.floor(rightsNum / 2) >= 1) output.isIb = true;
  rightsNum %= 2;
  if (rightsNum > 0 && Math.floor(rightsNum / 1) >= 1) output.isClient = true;
  return output;
};

export const validEmail = (email) => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const phoneRegExp = /(\+|00)(297|93|244|1264|358|355|376|971|54|374|1684|1268|61|43|994|257|32|229|226|880|359|973|1242|387|590|375|501|1441|591|55|1246|673|975|267|236|1|61|41|56|86|225|237|243|242|682|57|269|238|506|53|5999|61|1345|357|420|49|253|1767|45|1809|1829|1849|213|593|20|291|212|34|372|251|358|679|500|33|298|691|241|44|995|44|233|350|224|590|220|245|240|30|1473|299|502|594|1671|592|852|504|385|509|36|62|44|91|246|353|98|964|354|972|39|1876|44|962|81|76|77|254|996|855|686|1869|82|383|965|856|961|231|218|1758|423|94|266|370|352|371|853|590|212|377|373|261|960|52|692|389|223|356|95|382|976|1670|258|222|1664|596|230|265|60|262|264|687|227|672|234|505|683|31|47|977|674|64|968|92|507|64|51|63|680|675|48|1787|1939|850|351|595|970|689|974|262|40|7|250|966|249|221|65|500|4779|677|232|503|378|252|508|381|211|239|597|421|386|46|268|1721|248|963|1649|235|228|66|992|690|993|670|676|1868|216|90|688|886|255|256|380|598|1|998|3906698|379|1784|58|1284|1340|84|678|681|685|967|27|260|263)(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{4,20}$/im;
export const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z!@#$%^&*()_,.?":{}|<>\d]{8,20}$/;

export const validPortal = (client, state) => {
  if (client && (client.isClient || client.isCorporate) && client.isIb) {
    return !state.portal
      ? localStorage.getItem('portal') || 'CLIENT'
      : state.portal;
  }
  if (client && client.isClient) return 'CLIENT';
  if (client && client.isIb) return 'IB';
  return 'CLIENT';
};

export const clearJourneyAll = () => {
  localStorage.removeItem('kyc');
  localStorage.removeItem('deposit');
  localStorage.removeItem('trading');
  localStorage.removeItem('bonus');
  localStorage.removeItem('kdt');
  localStorage.removeItem('skip');
  localStorage.removeItem('activeStep');
  localStorage.removeItem('showCreateAccount');
  localStorage.removeItem('agreement');
}

export const languages = [
  {
    title: 'English',
    titleCode: 'EN',
    key: 'en-gb'
  },
  {
    title: 'عربي',
    titleCode: 'AR',
    key: 'ar-ae'
  },
  // {
  //   title: 'Japnese',
  //   titleCode: 'JP',
  //   key: 'jp'
  //   // key: 'ja-jp'
  // },
  // {
  //   title: 'Russian',
  //   titleCode: 'RU',
  //   key: 'ru'
  // },
  // {
  //   title: 'Spanish',
  //   titleCode: 'ES',
  //   key: 'es'
  //   // key: 'es-es'
  // },
  // {
  //   title: 'Farsi',
  //   titleCode: 'FA',
  //   key: 'fa'
  //   // key: 'fa-ir'
  // },
  // {
  //   title: 'Chinese',
  //   titleCode: 'CN',
  //   key: 'zh'
  //   // key: 'zh-cn'
  // },
  // {
  //   title: 'Sorani',
  //   titleCode: 'SO',
  //   key: 'so'
  //   // key: 'so-kr'
  // }
];

export const countryKey = (lang) => {
  let key = 'countryEn';
  if (lang === 'ar-ae') key = 'countryAr';
  if (lang === 'jp') key = 'countryJp';
  if (lang === 'ru') key = 'countryRu';
  if (lang === 'es') key = 'countryEs';
  if (lang === 'fa') key = 'countryFa';
  if (lang === 'zh') key = 'countryZh';
  if (lang === 'so') key = 'countrySo';
  return key;
};

export const declarationKey = (lang) => {
  let key = 'description';
  if (lang === 'ar-ae') key = 'description_ar';
  if (lang === 'jp') key = 'description_jp';
  if (lang === 'ru') key = 'description_ru';
  if (lang === 'es') key = 'description_es';
  if (lang === 'fa') key = 'description_fa';
  if (lang === 'zh') key = 'description_zh';
  if (lang === 'so') key = 'description_so';
  return key;
};
