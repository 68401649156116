import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Box,
  Typography,
  Popover,
  Badge,
  Button,
  Divider
} from '@material-ui/core';


import NotificationsActiveTwoToneIcon from '@material-ui/icons/NotificationsActiveTwoTone';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { readNotifications } from '../../websockets';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const HeaderDots = (props) => {

  const [anchorEl1, setAnchorEl1] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [anchorEl3, setAnchorEl3] = useState(null);

  const handleClick1 = (event, notifications) => {
    console.log('ddd ', notifications)
    setAnchorEl1(event.currentTarget);
    readNotifications(props.customerId, notifications)
    props.markNotificationsRead(notifications)
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };



  const open1 = Boolean(anchorEl1);

  const [value, setValue] = useState(0);


  const getbellColor = (notifications=[]) => {
    let classes = 'font-size-lg p-0 d-inline-block shadow-none border-0 text-center d-44 rounded position-relative'
    if(notifications.filter(obj => obj.isRead === false).length === 0){
      return classes+' btn-transition-none  text-primary  ';  
    }
    if(notifications.filter(obj => obj.isRead === false && obj.data && obj.data.status === 'REJECTED').length >0){
      return classes+'  btn-transition-none bg-neutral-danger text-danger ';  
    };
    return classes+'  btn-transition-none bg-neutral-success text-success ';
    
  }
  const notifications = props.notifications;

  const [belIcon, setBelIcon] = React.useState(['font-size-lg p-0 d-inline-block shadow-none border-0 text-center d-44 rounded position-relative btn-transition-none text-primary'])

  React.useEffect(()=>{
    let classes = 'font-size-lg p-0 d-inline-block shadow-none border-0 text-center d-44 rounded position-relative'
    if(notifications.filter(obj => obj.isRead === false).length === 0){
      setBelIcon(classes+' btn-transition-none  text-primary  ')
    }
    if(notifications.filter(obj => obj.isRead === false && obj.data && obj.data.status === 'REJECTED').length >0){
      setBelIcon(classes+'  btn-transition-none bg-neutral-danger text-danger ')
    };
    if(notifications.filter(obj => obj.isRead === false && obj.data && obj.data.status === 'APPROVED').length >0){
      return setBelIcon(classes+'  btn-transition-none bg-neutral-success text-success ')
    }
    
  },[notifications])

  return (
    <>
      <div className="d-flex align-items-center popover-header-wrapper">
        <span className="pr-2">
          <Badge
            // variant="dot"
            // anchorOrigin={{
            //   vertical: 'top',
            //   horizontal: 'left'
            // }}
            // badgeContent=" "
            // classes={{ badge: getbellColor(props.notifications) }}
            >
            <Button
              onClick={(e)=>{
                handleClick1(e, props.notifications)
              }}
              className={belIcon}>
              <span>
                <NotificationsActiveTwoToneIcon />
              </span>
            </Button>
          </Badge>

          <Popover
            open={open1}
            anchorEl={anchorEl1}
            onClose={handleClose1}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            classes={{
              paper: 'app-header-dots'
            }}
            >
            <div className="popover-custom-md-new overflow-hidden">
              <div className="bg-composed-wrapper bg-midnight-bloom border-0 text-center rounded-sm m-2 mb-0">
                <div className="bg-composed-img-3 bg-composed-wrapper--image" />
                <div className="bg-composed-wrapper--content text-white px-2 py-2">
                  <h4 className="font-size-xl font-weight-bold mb-2">
                    Notifications
                  </h4>
                  <p className="opacity-8 mb-0">
                    You have <b className="text-success">{props.notifications.length}</b> notificatoins
                  </p>
                </div>
              </div>
              {/* <div className="mx-2">
                <Tabs
                  className="nav-tabs-primary"
                  value={value}
                  variant="fullWidth"
                  onChange={handleChange}>
                  <Tab label="Timeline" />
                </Tabs>
              </div> */}
              <div className="height-280">
                <PerfectScrollbar>
                  <TabPanel value={value} index={0}>
                    {props.notifications.map(obj=> {
                      return (
                        <div className="timeline-list  timeline-list-offset-dot" style={{paddingTop: '0px'}}>
                      <div className="timeline-item" style={{margin: '0px'}}>
                        {/* <div className="timeline-item-offset">9:25</div> */}
                        <div className="timeline-item--content">
                          <div className="timeline-item--icon timeline-item--icon-reject"/>
                          {obj.data && <>
                            {obj.data.status === 'APPROVED' &&
                              <div className="timeline-item--icon timeline-item--icon-approve"/>
                            }
                              
                            {obj.data.status === 'REJECTED' &&
                              <div className="timeline-item--icon timeline-item--icon-reject"/>
                            }
                              
                          </>}
                          <h4 className="timeline-item--label mb-2 font-weight-bold">
                            {obj.data && (obj.data.transactionId || obj.data.recordId)}
                          </h4>
                          <p>
                            {obj.message}
                          </p>
                        </div>
                      </div>
                    </div>
                      )
                    }
                      )}
                    
                  </TabPanel>
                </PerfectScrollbar>
              </div>
              <Divider />
              <div className="text-center py-3">
                <Badge
                  color="error"
                  // variant="dot"
                  // anchorOrigin={{
                  //   vertical: 'top',
                  //   horizontal: 'right'
                  // }}
                  >
                  <Link
                    to={'/Activities'}
                    >

                    <Button
                      className="btn-gradient bg-midnight-bloom px-4"
                      variant="contained">
                      <span className="btn-wrapper--label">See All Activities</span>
                      <span className="btn-wrapper--icon">
                        {/* <FontAwesomeIcon icon={['fas', 'arrow-right']} /> */}
                      </span>
                    </Button>
                  </Link>
                </Badge>
              </div>
            </div>
          </Popover>
        </span>
       
      
      </div>
    </>
  );
};



const mapStateToProps = (state) => ({
  notification: state.Notification,
});

export default withNamespaces()(
  connect(mapStateToProps)(HeaderDots)
);
