export const PaymentGateways = [
  { name: 'Neteller', value: 'NETELLER' },
  { name: 'Praxis', value: 'PRAXIS' },
  { name: 'Visa/Master', value: 'Credit Card' },
  { name: 'Perfect Money', value: 'perfectm' },
  { name: 'BitPay - Crypto', value: 'bitpay' },
  { name: 'Finrax - Crypto', value: 'altcrypto' },
  { name: 'ThunderXPay', value: 'thunderxpay' },
  { name: 'Cashu', value: 'CASHU' },
  { name: 'Skrill', value: 'SKRILL' },
  { name: 'Wire Transfer', value: 'WIRE_TRANSFER' },
  { name: 'Visa / Master Card', value: 'QUBEPAY' },
  { name: 'JCC (Cyprus)', value: 'JCC' },
  { name: 'Safecharge', value: 'SAFECHARGE' },
  { name: 'Bitwallet', value: 'BITWALLET' },
  { name: 'Paypal', value: 'PAYPAL' },
  { name: 'Perfect Money', value: 'PERFECT_MONEY' },
  { name: 'My Fatoorah', value: 'MY_FATOORAH' },
  { name: 'Crypto', value: 'USDT' },
  { name: 'Gate To Pay', value: 'GATETOPAY' }
];
