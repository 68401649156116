import axiosHelper from '../helpers/axiosHelper';
import axiosHelper2 from '../helpers/axiosHelper2';

export const GET_CAMPAIGNS_START = 'GET_CAMPAIGNS_START';
export const GET_CAMPAIGNS_DONE = 'GET_CAMPAIGNS_DONE';

export const SUBMIT_CAMPAIGNS_START = 'SUBMIT_CAMPAIGNS_START';
export const SUBMIT_CAMPAIGNS_DONE = 'SUBMIT_CAMPAIGNS_DONE';
export const RESET_RESPONSE_MESSAGE = 'RESET_RESPONSE_MESSAGE';

const initialState = {
  campaigns: [],
  campaignsLoading: false
};

export const getCampaigns = () => async (dispatch) => {
  dispatch({ type: GET_CAMPAIGNS_START });
  let axios = axiosHelper(false);
  const res = await axios
    .get('/common/campaigns')
    .then(async (response) => {
      dispatch({
        type: GET_CAMPAIGNS_DONE,
        payload: response.data.result //? [response.data.result[0]] : []
      });
      return {
        success: false,
        messageType: 'error',
        message: response.data.message
      };
    })
    .catch((error) => {
      dispatch({ type: GET_CAMPAIGNS_DONE, payload: [] });
    });
  return res;
};

export const submitCampaign = (data) => async (dispatch) => {
  dispatch({ type: SUBMIT_CAMPAIGNS_START });
  let axios = axiosHelper2(true);
  const res = await axios
    .post('/common/campaigns/submit', data)
    .then(async (response) => {
      console.log('----', response);

      dispatch({
        type: SUBMIT_CAMPAIGNS_DONE,
        payload: response.data.result,
        message: response.data.message || 'Form submitted successfully',
        messageType: response.data.isSuccess ? 'success' : 'error'
      });
      setTimeout(() => {
        dispatch({ type: RESET_RESPONSE_MESSAGE });
      }, 2000);
      if (response.data.isSuccess) {
        return {
          success: true,
          messageType: 'success',
          message: response.data.message || 'Form submitted successfully'
        };
      } else {
        return {
          success: false,
          messageType: 'error',
          message:
            response.data.message || 'There is a problem requesting promotion'
        };
      }
    })
    .catch((error) => {
      console.log('ERR', error);
      dispatch({
        type: SUBMIT_CAMPAIGNS_DONE,
        payload: [],
        message: error.message,
        messageType: 'error'
      });
      setTimeout(() => {
        dispatch({ type: RESET_RESPONSE_MESSAGE });
      }, 2000);
      return {
        success: false,
        messageType: 'error',
        message: error.message
      };
    });
  return res;
};

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CAMPAIGNS_START:
      return { ...state, campaignsLoading: true };
    case GET_CAMPAIGNS_DONE:
      return { ...state, campaignsLoading: true, campaigns: action.payload };

    case SUBMIT_CAMPAIGNS_START:
      return { ...state, submitCampaignLoading: true };
    case SUBMIT_CAMPAIGNS_DONE:
      return {
        ...state,
        submitCampaignLoading: false,
        submitCampaignResult: action.payload,
        message: action.message,
        messageType: action.messageType
      };
    case RESET_RESPONSE_MESSAGE:
      return {
        ...state,
        message: ''
      };
    default:
      return state;
  }
};

export default dashboardReducer;
