import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Grid, Card, Tabs, Tab, SvgIcon } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

// import { createDemoCustomer } from '../../../store/actions/authActions';
import RegisterLive from '../../../components/Forms/RegisterIb';
import LoginForm from '../../../components/Forms/LoginForm';
import i18n from '../../../i18n';
import { withNamespaces } from 'react-i18next';

import { ReactComponent as FbIcon } from '../../../layout-components/Footer/icons/fb.svg';
import { ReactComponent as InIcon } from '../../../layout-components/Footer/icons/in.svg';
import { ReactComponent as LnIcon } from '../../../layout-components/Footer/icons/ln.svg';
import { ReactComponent as TwIcon } from '../../../layout-components/Footer/icons/tw.svg';
import { ReactComponent as YtIcon } from '../../../layout-components/Footer/icons/yt.svg';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      className="p-3"
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}>
      {value === index && <div>{children}</div>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

export const Login = (props) => {
  const urlParams = new URLSearchParams(window.location.search);
  const referral = urlParams.get('ref');
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    window.parent.postMessage({ tab: newValue }, '*');
    setValue(newValue);
  };

  // useEffect(() => {
  //   if ('geolocation' in navigator) {
  //     navigator.geolocation.getCurrentPosition(function (position) {});
  //     navigator.geolocation.getCurrentPosition(function (position) {});
  //   }
  // }, []);
  const rtl =
    localStorage.getItem('lang') === 'ar-ae' ||
    localStorage.getItem('lang') === 'fa' ||
    localStorage.getItem('lang') === 'so'
      ? true
      : false;

  const t = props.t;
  return (
    <>
      <div className={`app-wrapper min-vh-100 bg-white ${rtl && 'dir-ar-ae'}`}>
        <div className="app-main min-vh-100">
          <div className="app-content p-0">
            <div className="app-inner-content-layout--main">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content">
                  <Grid container spacing={0} className="min-vh-100">
                    <Grid item lg={12} xl={12} className="d-flex">
                      <div className="hero-wrapper w-100 bg-composed-wrapper min-vh-lg-100">
                        <div className="flex-grow-1 w-100 d-flex align-items-center">
                          <div className="loginbg" />
                          <div className="bg-composed-wrapper--content text-center">
                            <div className="registration-widget registerForm">
                              <Card elevation={2}>
                                <div className="px-4 pt-4">
                                  <Tabs
                                    className="nav-tabs-second"
                                    centered
                                    value={value}
                                    onChange={handleChange}>
                                    <Tab label={t('Register')} />
                                    <Tab label={t('Log In')} />
                                  </Tabs>
                                </div>

                                <TabPanel value={value} index={0}>
                                  <RegisterLive
                                    {...props}
                                    referral={referral}
                                  />
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                  <LoginForm {...props} />
                                </TabPanel>
                              </Card>
                            </div>
                          </div>
                        </div>
                        {/* <div className="hero-footer pb-4">
                          <div style={{ fontSize: '1.4rem' }}>
                            <a
                              // href="https://www.linkedin.com/company/goldera"
                              target="_blank"
                              rel="noreferrer"
                              className="ml-4 text-white-50">
                              <SvgIcon fontSize="inherit">
                                <LnIcon className="text-primary" />
                              </SvgIcon>
                            </a>
                            <a
                              // href="https://www.facebook.com/goldera/"
                              target="_blank"
                              rel="noreferrer"
                              className="ml-4 text-white-50">
                              <SvgIcon fontSize="inherit">
                                <FbIcon className="text-primary" />
                              </SvgIcon>
                            </a>
                            <a
                              // href="https://twitter.com/goldera"
                              target="_blank"
                              rel="noreferrer"
                              className="ml-4 text-white-50">
                              <SvgIcon fontSize="inherit">
                                <TwIcon className="text-primary" />
                              </SvgIcon>
                            </a>
                            <a
                              // href="https://www.instagram.com/goldera/"
                              target="_blank"
                              rel="noreferrer"
                              className="ml-4 text-white-50">
                              <SvgIcon fontSize="inherit">
                                <InIcon className="text-primary" />
                              </SvgIcon>
                            </a>
                            <a
                              // href="https://www.youtube.com/"
                              target="_blank"
                              rel="noreferrer"
                              className="ml-4 text-white-50">
                              <SvgIcon fontSize="inherit">
                                <YtIcon className="text-primary" />
                              </SvgIcon>
                            </a>
                          </div>
                        </div> */}
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    loading: state.apiCallsInProgress > 0
  };
};

const mapDispatchToProps = {
  //loginUser: createDemoCustomer
};

export default withRouter(
  withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(Login))
);
