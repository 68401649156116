import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import '../../rtl.scss';
import { connect } from 'react-redux';

import { Sidebar, Header, Footer } from '../../layout-components';

const LeftSidebar = (props) => {
  const {
    children,
    sidebarToggle,
    sidebarToggleMobile,
    sidebarFixed,
    headerFixed,
    headerSearchHover,
    headerDrawerToggle,
    footerFixed,
    contentBackground
  } = props;
  const lang = props.themeOptions.language

  const rtl = lang === 'fa' || lang === 'ar-ae' || lang === 'so' ? 'ar-ae' : 'en-gb'
  return (
    <>
      <div className={`dir-${rtl}`}>
        <div
          className={clsx('app-wrapper', contentBackground, {
            'header-drawer-open': headerDrawerToggle,
            'app-sidebar-collapsed': sidebarToggle,
            'app-sidebar-mobile-open': sidebarToggleMobile,
            'app-sidebar-fixed': sidebarFixed,
            'app-header-fixed': headerFixed,
            'app-footer-fixed': footerFixed,
            'search-wrapper-open': headerSearchHover
          })}>
          <div>
            <Sidebar />
          </div>
          <div className="app-main">
            <Header />
            <div className="app-content">
              <div className="app-content--inner">
                <div className="app-content--inner__wrapper mh-100-vh">
                  {children}
                </div>
              </div>
              <div className="px-md-5">
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

LeftSidebar.propTypes = {
  children: PropTypes.node
};

const mapStateToProps = (state) => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  sidebarFixed: state.ThemeOptions.sidebarFixed,
  headerFixed: state.ThemeOptions.headerFixed,
  headerSearchHover: state.ThemeOptions.headerSearchHover,
  headerDrawerToggle: state.ThemeOptions.headerDrawerToggle,

  footerFixed: state.ThemeOptions.footerFixed,

  contentBackground: state.ThemeOptions.contentBackground,
  language: state.ThemeOptions.language,
  themeOptions: state.ThemeOptions
});

export default connect(mapStateToProps)(LeftSidebar);
