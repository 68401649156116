import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';

import clsx from 'clsx';
import moment from 'moment';

import { makeStyles } from '@material-ui/core/styles';
import { getKYCDate, updateLanguage } from '../../reducers/User';
import {
  setSidebarToggleMobile,
  setLanguage
} from '../../reducers/ThemeOptions';
import i18n from '../../i18n';
import { withNamespaces } from 'react-i18next';
import HeaderUserbox from '../../layout-components/HeaderUserbox';
// import HeaderSearch from '../../layout-components/HeaderSearch';
// import HeaderWidget from '../../layout-components/HeaderWidget';
import {
  FormControl,
  Badge,
  Select,
  Menu,
  MenuItem,
  IconButton,
  InputLabel
} from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import EN from '../../assets/images/custom/united-states-of-america.png';
import AR from '../../assets/images/custom/united-arab-emirates.png';
import { markNotificationsRead } from '../../reducers/Notification'

import { readNotifications } from '../../websockets';
import HeaderDots from 'layout-components/HeaderDots';
import { languages } from '../../utils/utilities';


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 80
  }
}));

const Header = (props) => {
  const t = props.t;
  const classes = useStyles();

  const [country, setCountry] = useState('en');
  const [lang, setLang] = useState(localStorage.getItem('lang') || 'en-gb');

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const {
    headerShadow,
    headerBgTransparent,
    sidebarToggleMobile,
    setSidebarToggleMobile
  } = props;

  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile);
  };

  const setZendeskLang =  (lang) => {
    if (typeof window !== 'undefined' && window.zE && window.zE.setLocale) {
      window.zE.setLocale(lang);
    } else {
      setTimeout(() => setZendeskLang(lang), 1000)
    }
  }

  const handleChange = (e) => {
    setCountry(e.target.value);
  };
  const changeLang = (e) => {
    setLang(e.target.value);
    props.setLanguage(e.target.value);
    i18n.changeLanguage(e.target.value);
    localStorage.setItem('lang', e.target.value);
    props.updateLanguage(e.target.value);
    if (e.target.value === 'ar-ae' || e.target.value === 'fa' || e.target.value === 'so') {
      document.body.setAttribute('dir', 'rtl');
      document.body.style.textAlign = 'right';
    } else {
      document.body.setAttribute('dir', 'ltr');
      document.body.style.textAlign = 'left';
    }
  };

  const handleClick = (event, notifications) => {
    readNotifications(props.customerId, notifications)
    setAnchorEl(event.currentTarget);
    props.markNotificationsRead(notifications)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  async function fetchKYCDate() {
    await props.getKYCDate();
  }

  useEffect(() => {
    fetchKYCDate();
  }, []);

  useEffect(() => {
    let locale;
    switch (lang) {
      case 'ar-ae':
        locale = 'ar';
        break;
      case 'ja-jp':
        locale = 'ja';
        break;
      case 'zh-cn':
        locale = 'zh-tw';
        break;
      case 'es-es':
        locale = 'es';
        break;
      case 'so-kr':
        locale = 'so';
        break;
      case 'fa-ir':
        locale = 'fa';
        break;
      case 'ru':
        locale = 'ru';
        break;
      case 'en-us':
      default:
        locale = 'en';
    }
    setZendeskLang(locale);
  }, [lang]);

  const options = [
    'None',
    'Atria',
    'Callisto',
    'Dione',
    'Ganymede',
    'Hangouts Call',
    'Luna',
    'Oberon',
    'Phobos',
    'Pyxis',
    'Sedna',
    'Titania',
    'Triton',
    'Umbriel'
  ];

  return (
    <>
      <div
        className={clsx('app-header', {
          'app-header--shadow': headerShadow,
          'app-header--opacity-bg': headerBgTransparent
        })}>
        <div className="app-header--pane">
          <button
            className={clsx(
              'navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn',
              { 'is-active': sidebarToggleMobile }
            )}
            onClick={toggleSidebarMobile}>
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>
          {/* <button
            className={clsx(
              'navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn',
              { 'is-active': sidebarToggleMobile }
            )}
            onClick={toggleSidebarMobile}>
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>
          <HeaderSearch />
          <HeaderWidget /> */}
          {/* <FormControl variant="outlined" className={classes.formControl}>
            <Select value={lang} onChange={changeLang}>
              <MenuItem value={'en-gb'}>EN</MenuItem>
              <MenuItem value={'ar-ae'}>AR</MenuItem>
            </Select>
          </FormControl> */}
          <FormControl variant="outlined" className={classes.formControl}>
            <Select value={lang} onChange={changeLang}>
              {languages.map((v, k) => (
                v.key !== 'so' &&
                <MenuItem key={k} value={v.key}>{v.titleCode}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="app-header--pane kyc-notice">
          <div>
            {props.kycDate && (
              // `KYC deadline: ${moment(props.kycDate).fromNow()}`}

              <strong
                style={
                  props.kycUpload ? { color: 'orange' } : { color: 'red' }
                }>
                {moment(props.kycDate).diff(moment(), 'days') + 1 > 0 ? (
                  <>
                    {t('Remaining')}{' '}
                    {moment(props.kycDate).diff(moment(), 'days') + 1}{' '}
                    {t('days to')}{' '}
                    {props.kycUpload
                      ? t('verify your account')
                      : t('upload concerned documents and verify')}
                  </>
                ) : (
                  <>
                    {t('Your account(s) has been disabled. Please')}{' '}
                    {props.kycUpload
                      ? t('verify your account')
                      : t('upload concerned documents and verify')}
                  </>
                )}
              </strong>
            )}
          </div>
        </div>
        <div className="app-header--pane">
          <div>
            {/* {props.notification.notifications.length> 0 &&
            <IconButton
              color="secondary"
              className="mx-2"
              onClick={(e)=>{
                handleClick(e, props.notification.notifications)
              }}
            >
              <Badge badgeContent={props.notification.notifications.filter(obj => obj.isRead === false).length} color="primary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          } */}
            {/*{props.notification.notifications.length> 0 &&*/}
            {/*  <HeaderDots customerId={props.customerId} notifications={props.notification.notifications} markNotificationsRead={props.markNotificationsRead} />*/}
            {/*}*/}

            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              open={open}
              onClose={handleClose}
            // PaperProps={{
            //   style: {
            //     maxHeight: ITEM_HEIGHT * 4.5,
            //     width: '20ch',
            //   },
            // }}
            >
              {props.notification.notifications.map((option, index) => (
                <MenuItem
                  key={index}
                  onClick={handleClose}>
                  {option.message}
                </MenuItem>
              ))}
            </Menu>
          </div>
          <HeaderUserbox />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  headerShadow: state.ThemeOptions.headerShadow,
  headerBgTransparent: state.ThemeOptions.headerBgTransparent,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  kycDate: state.User.kycDate,
  kycUpload: state.User.kycUpload,
  customerId: state.User.profile && state.User.profile._id,
  notification: state.Notification,
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable)),
  setLanguage: (lang) => dispatch(setLanguage(lang)),
  getKYCDate: () => dispatch(getKYCDate()),
  updateLanguage: (lang) => dispatch(updateLanguage(lang)),
  markNotificationsRead: (data) => dispatch(markNotificationsRead(data)),

});

export default withNamespaces()(
  connect(mapStateToProps, mapDispatchToProps)(Header)
);
