import * as qs from 'querystring';
import axiosHelper from '../helpers/axiosHelper2';
import { GET_KYC_TYPE_DONE } from './Configs';

import { getCustomerType, validPortal } from 'utils/utilities';
// import * as qs from 'querystring';

export const GET_PROFILE_START = 'GET_PROFILE_START';
export const GET_PROFILE_DONE = 'GET_PROFILE_DONE';

export const UPDATE_PROFILE_START = 'UPDATE_PROFILE_START';
export const UPDATE_PROFILE_DONE = 'UPDATE_PROFILE_DONE';

export const ADD_QUESTIONNAIRE_START = 'ADD_QUESTIONNAIRE_START';
export const ADD_QUESTIONNAIRE_DONE = 'ADD_QUESTIONNAIRE_DONE';

export const GET_DEFAULT_JOURNEY_START = 'GET_DEFAULT_JOURNEY_START';
export const GET_DEFAULT_JOURNEY_DONE = 'GET_DEFAULT_JOURNEY_DONE';

export const GET_KYC_DATE_START = 'GET_KYC_DATE_START';
export const GET_KYC_DATE_DONE = 'GET_KYC_DATE_DONE';

export const GET_PROFILE_DECLARATIONS_START = 'GET_PROFILE_DECLARATIONS_START';
export const GET_PROFILE_DECLARATIONS_DONE = 'GET_PROFILE_DECLARATIONS_DONE';

export const SET_PORTAL = 'SET_PORTAL';
export const SET_SELECTED_CLIENT = 'SET_SELECTED_CLIENT';

export const GET_SUMMARY_IB_START = 'GET_SUMMARY_IB_START';
export const GET_SUMMARY_IB_DONE = 'GET_SUMMARY_IB_DONE';

export const GET_RECENT_LIVE_CLIENT_START = 'GET_RECENT_LIVE_CLIENT_START';
export const GET_RECENT_LIVE_CLIENT_DONE = 'GET_RECENT_LIVE_CLIENT_DONE';

export const GET_RECENT_DEMO_CLIENT_START = 'GET_RECENT_DEMO_CLIENT_START';
export const GET_RECENT_DEMO_CLIENT_DONE = 'GET_RECENT_DEMO_CLIENT_DONE';

export const GET_IB_LIVE_CLIENT_START = 'GET_IB_LIVE_CLIENT_START';
export const GET_IB_LIVE_CLIENT_DONE = 'GET_IB_LIVE_CLIENT_DONE';

export const GET_IB_DEMO_CLIENT_START = 'GET_IB_DEMO_CLIENT_START';
export const GET_IB_DEMO_CLIENT_DONE = 'GET_IB_DEMO_CLIENT_DONE';
export const GET_IB_DECLARATIONS_START = 'GET_IB_DECLARATIONS_START';
export const GET_IB_DECLARATIONS_DONE = 'GET_IB_DECLARATIONS_DONE';

export const SET_EXTRA_DETAILS = 'SET_EXTRA_DETAILS';
export const GET_REQUESTS_START = 'GET_REQUESTS_START';
export const GET_REQUESTS_DONE = 'GET_REQUESTS_DONE';

//Get user profile
export const getProfile = () => async (dispatch) => {
  dispatch({ type: GET_PROFILE_START });
  let axios = axiosHelper(true);
  const res = await axios
    .get('/my/profile')
    .then(async (response) => {
      dispatch({ type: GET_PROFILE_DONE, payload: response.data.result.data });
      if (response.data.isSuccess) {
        return { success: true, messageType: 'success', message: '' };
      }
      return {
        success: false,
        messageType: 'error',
        message: response.data.message
      };
    })
    .catch((error) => {
      dispatch({ type: GET_PROFILE_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

export const getProfileDeclarations = () => async (dispatch) => {
  dispatch({ type: GET_PROFILE_DECLARATIONS_START });
  let axios = axiosHelper(true);
  const res = await axios
    .get('/common/profile-declarations')
    .then(async (response) => {
      dispatch({
        type: GET_PROFILE_DECLARATIONS_DONE,
        payload: response.data.result
      });
      if (response.data.isSuccess) {
        return { success: true, messageType: 'success', message: '' };
      }
      return {
        success: false,
        messageType: 'error',
        message: response.data.message
      };
    })
    .catch((error) => {
      dispatch({ type: GET_PROFILE_DECLARATIONS_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};
export const getIbDeclarations = () => async (dispatch) => {
  dispatch({ type: GET_IB_DECLARATIONS_START });
  let axios = axiosHelper(true);
  const res = await axios
    .get('/common/ib-declarations')
    .then(async (response) => {
      dispatch({
        type: GET_IB_DECLARATIONS_DONE,
        payload: response.data.result
      });
      if (response.data.isSuccess) {
        return {
          success: true,
          messageType: 'success',
          message: ''
        };
      }
      return {
        success: false,
        messageType: 'error',
        message: response.data.message
      };
    })
    .catch((error) => {
      dispatch({ type: GET_IB_DECLARATIONS_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

export const getBonusRequests = (
  requestType = 'campaignSubmit',
  campaignId = '6107ca0d19c45d4fbc2f0549'
) => async (dispatch) => {
  dispatch({ type: GET_REQUESTS_START });
  let axios = axiosHelper(true);
  const res = await axios
    .get(`/my/requests?type=${requestType}&campaign=${campaignId}`)
    .then(async (response) => {
      dispatch({ type: GET_REQUESTS_DONE, payload: response.data.result.data });
      if (response.data.isSuccess) {
        return { success: true, messageType: 'success', message: '' };
      }
      return {
        success: false,
        messageType: 'error',
        message: response.data.message
      };
    })
    .catch((error) => {
      dispatch({ type: GET_REQUESTS_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

//update my profile
export const updateProfile = (data) => async (dispatch) => {
  dispatch({ type: UPDATE_PROFILE_START });
  let axios = axiosHelper(true);
  const res = await axios
    .patch('/my/profile', data)
    .then(async (response) => {
      dispatch({ type: UPDATE_PROFILE_DONE });
      if (response.data.isSuccess) {
        return { success: true, messageType: 'success', message: '' };
      }
      return {
        success: false,
        messageType: 'error',
        message: response.data.message
      };
    })
    .catch((error) => {
      dispatch({ type: UPDATE_PROFILE_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};
//update corp profile
export const updateProfileCorp = (data) => async (dispatch) => {
  dispatch({ type: UPDATE_PROFILE_START });
  let axios = axiosHelper(true);
  const res = await axios
    .patch('/my/corp-profile', data)
    .then(async (response) => {
      dispatch({ type: UPDATE_PROFILE_DONE });
      if (response.data.isSuccess) {
        return { success: true, messageType: 'success', message: '' };
      }
      return {
        success: false,
        messageType: 'error',
        message: response.data.message
      };
    })
    .catch((error) => {
      dispatch({ type: UPDATE_PROFILE_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};
//update ib profile
export const updateProfileIb = (data) => async (dispatch) => {
  dispatch({ type: UPDATE_PROFILE_START });
  let axios = axiosHelper(true);
  const res = await axios
    .patch('/ib/profile', data)
    .then(async (response) => {
      dispatch({ type: UPDATE_PROFILE_DONE, payload: response.data.result });
      if (response.data.isSuccess) {
        return {
          success: true,
          messageType: 'success',
          message: ''
        };
      }
      return {
        success: false,
        messageType: 'error',
        message: response.data.message
      };
    })
    .catch((error) => {
      dispatch({ type: UPDATE_PROFILE_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};
export const addQuestionaire = (data) => async (dispatch) => {
  dispatch({ type: ADD_QUESTIONNAIRE_START });
  let axios = axiosHelper(true);
  const res = await axios
    .post('/ib/questionnaire', data)
    .then(async (response) => {
      dispatch({ type: ADD_QUESTIONNAIRE_DONE });
      if (response.data.isSuccess) {
        return {
          success: true,
          messageType: 'success',
          message: ''
        };
      }
      return {
        success: false,
        messageType: 'error',
        message: response.data.message
      };
    })
    .catch((error) => {
      dispatch({ type: ADD_QUESTIONNAIRE_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

//Get user profile
export const getKYCDate = () => async (dispatch) => {
  dispatch({ type: GET_KYC_DATE_START });
  let axios = axiosHelper(true);
  const res = await axios
    .get('/my/kyc-date')
    .then(async (response) => {
      dispatch({ type: GET_KYC_DATE_DONE, payload: response.data.result });
      if (response.data.isSuccess) {
        return { success: true, messageType: 'success', message: '' };
      }
      return {
        success: false,
        messageType: 'error',
        message: response.data.message
      };
    })
    .catch((error) => {
      dispatch({ type: GET_KYC_DATE_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

// Update user language
export const updateLanguage = (lang) => async (dispatch) => {
  let axios = axiosHelper(true);
  const res = await axios
    .post('/my/update-language', { language: lang })
    .then(async (response) => {
      if (response.data.isSuccess) {
        return { success: true, messageType: 'success', message: '' };
      }
      return {
        success: false,
        messageType: 'error',
        message: response.data.message
      };
    })
    .catch((error) => {
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

// SET PORTAL
export const setPortal = (portal) => async (dispatch) => {
  localStorage.setItem('portal', portal)
  dispatch({ type: SET_PORTAL, payload: portal });
};

// SET SELECTED CLIENT
export const setSelectedClient = (id) => async (dispatch) => {
  dispatch({ type: SET_SELECTED_CLIENT, payload: id })
}

//Get user summary for IB
export const getSummaryIB = () => async (dispatch) => {
  dispatch({ type: GET_SUMMARY_IB_START });
  let axios = axiosHelper(true);
  const res = await axios
    .get('/ib/summary')
    .then(async (response) => {
      dispatch({ type: GET_SUMMARY_IB_DONE, payload: response.data.result });
      if (response.data.isSuccess) {
        return { success: true, messageType: 'success', message: '' };
      }
      return {
        success: false,
        messageType: 'error',
        message: response.data.message
      };
    })
    .catch((error) => {
      dispatch({ type: GET_SUMMARY_IB_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

//Get demo recent client for IB
export const getRercentDemoClientIB = (limit, page = 1) => async (dispatch) => {
  dispatch({ type: GET_RECENT_DEMO_CLIENT_START });
  let axios = axiosHelper(true);
  const res = await axios
    .get(`/ib/recent-demo-clients?limit=${limit}&page=${page}`)
    .then(async (response) => {
      dispatch({
        type: GET_RECENT_DEMO_CLIENT_DONE,
        payload: response.data.result
      });
      if (response.data.isSuccess) {
        return { success: true, messageType: 'success', message: '' };
      }
      return {
        success: false,
        messageType: 'error',
        message: response.data.message
      };
    })
    .catch((error) => {
      dispatch({ type: GET_RECENT_DEMO_CLIENT_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

//Get live recent client for IB
export const getRercentLiveClientIB = (limit, page = 1) => async (dispatch) => {
  dispatch({ type: GET_RECENT_LIVE_CLIENT_START });
  let axios = axiosHelper(true);
  const res = await axios
    .get(`/ib/recent-live-clients?limit=${limit}&page=${page}`)
    .then(async (response) => {
      dispatch({
        type: GET_RECENT_LIVE_CLIENT_DONE,
        payload: response.data.result
      });
      if (response.data.isSuccess) {
        return { success: true, messageType: 'success', message: '' };
      }
      return {
        success: false,
        messageType: 'error',
        message: response.data.message
      };
    })
    .catch((error) => {
      dispatch({ type: GET_RECENT_LIVE_CLIENT_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

//Get live recent client for IB
export const getLiveClientIB = (query) => async (dispatch) => {
  dispatch({ type: GET_IB_LIVE_CLIENT_START });
  let axios = axiosHelper(true);
  const res = await axios
    .get(`/ib/live-clients?${qs.stringify(query)}`)
    .then(async (response) => {
      dispatch({
        type: GET_IB_LIVE_CLIENT_DONE,
        payload: response.data.result
      });
      if (response.data.isSuccess) {
        return { success: true, messageType: 'success', message: '' };
      }
      return {
        success: false,
        messageType: 'error',
        message: response.data.message
      };
    })
    .catch((error) => {
      dispatch({ type: GET_IB_LIVE_CLIENT_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

//Get demo recent client for IB
export const getDemoClientIB = (query) => async (dispatch) => {
  dispatch({ type: GET_IB_DEMO_CLIENT_START });
  let axios = axiosHelper(true);
  const res = await axios
    .get(`/ib/demo-clients?${qs.stringify(query)}`)
    .then(async (response) => {
      dispatch({
        type: GET_IB_DEMO_CLIENT_DONE,
        payload: response.data.result
      });
      if (response.data.isSuccess) {
        return { success: true, messageType: 'success', message: '' };
      }
      return {
        success: false,
        messageType: 'error',
        message: response.data.message
      };
    })
    .catch((error) => {
      dispatch({ type: GET_IB_DEMO_CLIENT_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

export const setExtraDetails = (payload) => async (dispatch) => {
  dispatch({ type: SET_EXTRA_DETAILS, payload: payload});
};

export default function reducer(
  state = {
    kycDate: null,
    kycUpload: null,
    profileLoading: false,
    updateProfileLoading: false,
    profile: {},
    profileDeclarations: [],
    showSwitch: false,
    portal: null,
    selectedClient: null,
    userSummaryIBLoading: false,
    userSummaryIB: {},
    recentLiveClientLoading: false,
    recentLiveClient: {},
    recentDemoClientLoading: false,
    recentDemoClient: {},
    liveIBClientLoading: false,
    liveIBClients: {},
    demoIBClientLoading: false,
    demoIBClients: {},
    ibDeclarations: [],
    extraDetails:[],
    userBonusRequest: [],
  },
  action
) {
  switch (action.type) {
    case GET_PROFILE_START:
      return {
        ...state,
        profileLoading: true
      };
    case GET_PROFILE_DONE:
      const client =
        action.payload &&
        action.payload.clientType &&
        getCustomerType(action.payload.clientType);
      const showSwitch =
        (client && client.isIb && client.isClient) ||
        (client && client.isIb && client.isCorporate)
          ? true
          : false;
      return {
        ...state,
        profileLoading: false,
        profile: action.payload,
        portal: validPortal(client, state),
        showSwitch: showSwitch
      };
    case UPDATE_PROFILE_START:
    case ADD_QUESTIONNAIRE_START:
      return {
        ...state,
        updateProfileLoading: true
      };
    case UPDATE_PROFILE_DONE:
    case ADD_QUESTIONNAIRE_DONE:
      return {
        ...state,
        updateProfileLoading: false
        //profile: action.payload
      };
    case GET_KYC_DATE_START:
      return {
        ...state,
        profileLoading: true
      };
    case GET_KYC_DATE_DONE:
      return {
        ...state,
        profileLoading: false,
        ...action.payload
      };
    case GET_PROFILE_DECLARATIONS_START:
      return {
        ...state,
        profileLoading: true
      };
    case GET_PROFILE_DECLARATIONS_DONE:
      return {
        ...state,
        profileLoading: false,
        profileDeclarations: action.payload
      };
    case SET_PORTAL:
      return {
        ...state,
        portal: action.payload
      };
    case SET_SELECTED_CLIENT:
      return {
        ...state,
        selectedClient: action.payload
      };
    case GET_SUMMARY_IB_START:
      return {
        ...state,
        userSummaryIBLoading: true
      };
    case GET_SUMMARY_IB_DONE:
      return {
        ...state,
        userSummaryIBLoading: false,
        userSummaryIB: action.payload
      };
    case GET_RECENT_LIVE_CLIENT_START:
      return {
        ...state,
        recentLiveClientLoading: true
      };
    case GET_RECENT_LIVE_CLIENT_DONE:
      return {
        ...state,
        recentLiveClientLoading: false,
        recentLiveClient: action.payload
      };
    case GET_RECENT_DEMO_CLIENT_START:
      return {
        ...state,
        recentDemoClientLoading: true
      };
    case GET_RECENT_DEMO_CLIENT_DONE:
      return {
        ...state,
        recentDemoClientLoading: false,
        recentDemoClient: action.payload
      };
    case GET_IB_LIVE_CLIENT_START:
      return {
        ...state,
        liveIBClientLoading: true
      };
    case GET_IB_LIVE_CLIENT_DONE:
      return {
        ...state,
        liveIBClientLoading: false,
        liveIBClients: action.payload
      };
    case GET_IB_DEMO_CLIENT_START:
      return {
        ...state,
        demoIBClientLoading: true
      };
    case GET_IB_DEMO_CLIENT_DONE:
      return {
        ...state,
        demoIBClientLoading: false,
        demoIBClients: action.payload
      };
    case GET_IB_DECLARATIONS_START:
      return {
        ...state,
        profileLoading: true
      };
    case GET_IB_DECLARATIONS_DONE:
      return {
        ...state,
        profileLoading: false,
        ibDeclarations: action.payload
      };
      case SET_EXTRA_DETAILS:
        return {
          ...state,
          extraDetails: action.payload
        };
    case GET_REQUESTS_START:
      return {
        ...state,
        profileLoading: true
      };
    case GET_REQUESTS_DONE:
      const requests =
        action.payload 
      return {
        ...state,
        profileLoading: false,
        requests: action.payload,
      };
    default:
      break;
  }
  return state;
}
