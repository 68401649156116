import React from 'react';
import { Dialog, Button, DialogContent, Grid } from '@material-ui/core';
import crossIcon from 'assets/images/custom/delete.png';
import { Link } from 'react-router-dom';
import AuthHelper from '../../helpers/authHelper';
import  { app_config }  from 'config/app-config'

const DemoAccountCreateModal = (props) => {
  return (
    <Dialog
      open={props.open}
      maxWidth="xs"
      fullWidth={true}
      onClose={props.handleClose}
      aria-labelledby="form-dialog-title">
      <DialogContent>
        <div className="px-md-3 mt-3 mb-4 text-center d-flex align-items-center justify-content-center">
          <h5 className="text-info font-weight-bold mb-0">
            Your partnership request has been rejected
          </h5>
          {/* <img
            src="https://www.flaticon.com/svg/static/icons/svg/190/190411.svg"
            alt="verified phone number"
            className="mr-2"
            style={{ width: '40px' }}
          /> */}
        </div>
        <div className="px-md-3 mb-3 text-center">
          <img
            src={crossIcon}
            alt="Windows Logo"
            className="ml-3"
            style={{ width: '35px' }}
          />
        </div>
        <div className="px-md-3 mb-5 text-center">
          <a href={`${app_config.contactUs}`}>
                <Button  variant="contained" color="primary" className="btn-round">
                    Contact support to discuss further
                </Button>
            </a>
          
        </div>
        {/* <div className="px-md-3 text-info font-weight-bold mb-5 d-flex align-items-center justify-content-center">
          <a href="" className="mr-4">
            <img
              src={playStoreButton}
              alt="PlayStore Download App MT5 Platforms"
              className="image-responsive"
            />
          </a>
          <a href="">
            <img
              src={appStoreButton}
              alt="AppStore Download App MT5 Platforms"
              className="image-responsive"
            />
          </a>
        </div> */}
        <div className="text-right">
          <a className="cursor-pointer text-info" onClick={()=>{AuthHelper.logout()}}>
            Logout
          </a>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DemoAccountCreateModal;
