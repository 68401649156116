import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';

function IdleDialog(props) {
  const { show, title, content, buttonLabel, handleStayLoggedIn } = props;
  const [count, setCount] = useState(10);

  useEffect(() => {
    if (count > 0) {
      setTimeout(() => setCount(count - 1), 1000);
    }
  });

  useEffect(() => {
    if (show === true) {
      setCount(10);
    }
  }, [show]);
  return (
    <Dialog open={show}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button
          onClick={handleStayLoggedIn}
          variant="contained"
          color="primary">
          {buttonLabel} ({count})
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default IdleDialog;
