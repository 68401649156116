import axiosHelper from '../helpers/axiosHelper';
import axiosHelper2 from '../helpers/axiosHelper2';

export const GET_DOCUMENTS_START = 'GET_DOCUMENTS_START';
export const GET_DOCUMENTS_DONE = 'GET_DOCUMENTS_DONE';
export const CREATE_DOCUMENT_START = 'CREATE_DOCUMENT_START';
export const CREATE_DOCUMENT_DONE = 'CREATE_DOCUMENT_DONE';
export const CREATE_SINGLE_DOCUMENT_START = 'CREATE_SINGLE_DOCUMENT_START';
export const CREATE_SINGLE_DOCUMENT_DONE = 'CREATE_SINGLE_DOCUMENT_DONE';

//Get Bank accounts
export const getDocuments = () => async (dispatch) => {
  dispatch({ type: GET_DOCUMENTS_START });
  let axios = axiosHelper(true);
  const res = await axios
    .get('/common/document/list')
    .then((response) => {
      dispatch({ type: GET_DOCUMENTS_DONE, payload: response.data.result });
      if (response.data.isSuccess) {
        return {
          success: true,
          messageType: 'success',
          message: ''
        };
      }
      return {
        success: false,
        messageType: 'error',
        message: response.data.message
      };
    })
    .catch((error) => {
      dispatch({ type: GET_DOCUMENTS_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

//Upload document
export const uploadSingleDocument = (
  params,
  title,
  documentType,
  saveToDocs = true
) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_SINGLE_DOCUMENT_START });
    let axios = axiosHelper(true, true);

    
   const query = `documentType=${documentType}`

    const response = await axios.post(`/my/upload-document?${query}`, params);
    if (response.data.isSuccess) {
      const { url, fileName, journeyUpdate } = response.data.result;
      let res = {};
      if (saveToDocs) {
        res = await dispatch(
          saveDocument({
            title: title,
            filePath: url,
            fileName: fileName
          })
        );
      } else {
        res = {
          messageType: 'success',
          message: 'Successfully submitted request!',
          success: true,
          title: title,
          filePath: url,
          fileName: fileName
        };
      }
      return res;
    }
    dispatch({ type: CREATE_SINGLE_DOCUMENT_DONE });
    return {
      success: false,
      messageType: 'error',
      message: response.data.message
    };
  } catch (error) {
    dispatch({ type: CREATE_SINGLE_DOCUMENT_DONE });
    let message = 'Invalid request.';
    if (error.response && error.response.data)
      message = error.response.data.message;
    return { success: false, messageType: 'error', message: message };
  }
};

//Upload document
export const uploadDocument = (document) => async (
  dispatch
) => {
  try {

    let axios = axiosHelper(true, true);
    dispatch({ type: CREATE_DOCUMENT_START });
    let filesList = [];
    const docDtls = document.documentDetails
    
    const query = docDtls.isCorporate ? 
                          `documentType=${document.title ? document.title : ''}&isCorp=true&corpId=${docDtls.corpId ? docDtls.corpId : ''}&corpType=${docDtls.corpType ? docDtls.corpType : ''}` 
                          : `documentType=${document.title ? document.title : ''}`
    for (let file of document.files) {
      let formData = new FormData();
      formData.append('file', file.data);

      const res = await axios.post(
        `/my/upload-document?${query}`,
        formData
      );
      const { url } = res.data.result;
      const updatedFile = {
        type: file.type,
        path: url,
        name: file.name
      };
      filesList.push(updatedFile);
    }
    document.files = filesList;
    console.log('DONE ', document);
    const res = await dispatch(saveDocument(document));

    return res;
  } catch (error) {
    dispatch({ type: CREATE_DOCUMENT_DONE });
    let message = 'Invalid request.';
    if (error.response && error.response.data)
      message = error.response.data.message;
    return { success: false, messageType: 'error', message: message };
  }
};

//Save uploaded document
export const saveDocument = (document) => async (dispatch) => {
  let axios = axiosHelper2(true);
  let response;
  try {
    response = await axios.post('/common/document/add', document);
    dispatch({ type: CREATE_DOCUMENT_DONE });
    if (response.data.isSuccess) {
      await dispatch(getDocuments());
      return {
        success: true,
        messageType: 'success',
        message: 'Document uploaded successfully.',
        ...document
      };
    }
    return {
      success: false,
      messageType: 'error',
      message: response.data.errors
    };
  } catch (error) {
    dispatch({ type: CREATE_DOCUMENT_DONE });
    let message = 'Invalid request.';
    if (error.response && error.response.data)
      message = error.response.data.message;
    return { success: false, messageType: 'error', message: message };
  }
};

export const getDocumentUrl = (url) => {
  let axios = axiosHelper2(true);
  return axios.get(`common/document/download?id=${url}`);
};

export default function reducer(
  state = {
    documentLoading: false,
    documents: [],
    createDocumentLoading: false
  },
  action
) {

  switch (action.type) {
    case GET_DOCUMENTS_START:
      return {
        ...state,
        documentLoading: true
      };
    case GET_DOCUMENTS_DONE:

      return {
        ...state,
        documentLoading: false,
        documents: action.payload
      };
    case CREATE_DOCUMENT_START:

      return {
        ...state,
        createDocumentLoading: true
      };
    case CREATE_DOCUMENT_DONE:

      return {
        ...state,
        createDocumentLoading: false
      };
    default:
      break;
  }
  return state;
}
