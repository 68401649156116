import axiosHelper from '../helpers/axiosHelper';
import axiosHelper2 from '../helpers/axiosHelper2';
import * as qs from 'querystring';
import { stat } from 'fs';

export const PARTNERSHIP_REQUEST_START = 'PARTNERSHIP_REQUEST_START';
export const PARTNERSHIP_REQUEST_DONE = 'PARTNERSHIP_REQUEST_DONE';

export const FETCH_STRUCTURES_START = 'FETCH_STRUCTURES_START';
export const FETCH_STRUCTURES_DONE = 'FETCH_STRUCTURES_DONE';

export const FETCH_IB_ACCOUNT_START = 'FETCH_IB_ACCOUNT_START';
export const FETCH_IB_ACCOUNT_DONE = 'FETCH_IB_ACCOUNT_DONE';

export const FETCH_IB_CLIENT_ACCOUNT_START = 'FETCH_IB_CLIENT_ACCOUNT_START';
export const FETCH_IB_CLIENT_ACCOUNT_DONE = 'FETCH_IB_CLIENT_ACCOUNT_DONE';

export const PARTNERSHIP_REQUEST_FETCH_START = 'PARTNERSHIP_REQUEST_FETCH_START';
export const PARTNERSHIP_REQUEST_FETCH_DONE = 'PARTNERSHIP_REQUEST_FETCH_DONE';

export const SUBMIT_AGREEMENT_START = 'SUBMIT_AGREEMENT_START';
export const SUBMIT_AGREEMENT_DONE = 'SUBMIT_AGREEMENT_DONE';

//Create partnership request

export const createPartnershipRequest = (model) => async (dispatch) => {
  dispatch({ type: PARTNERSHIP_REQUEST_START });
  let axios = axiosHelper(true);
  const res = await axios
    .post('/request/partnership', qs.stringify(model))
    .then(async (response) => {
      dispatch({ type: PARTNERSHIP_REQUEST_DONE });
      if (response.data.isSuccess) {
        return {
          success: true,
          messageType: 'success',
          message: 'Partnership request done successfully.'
        };
      }
      return {
        success: false,
        messageType: 'error',
        message: response.data.errors
      };
    })
    .catch((error) => {
      dispatch({ type: PARTNERSHIP_REQUEST_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

export const getStructure = () => async (dispatch) => {
  dispatch({ type: FETCH_STRUCTURES_START });
  let axios = axiosHelper(true);
  axios
    .get(`/ib/structures`)
    .then(async (response) => {
      const structure = response.data.result;
      dispatch({
        type: FETCH_STRUCTURES_DONE,
        payload: structure,
      });
    })
    .catch((error) => {
      dispatch({ type: FETCH_STRUCTURES_DONE, payload: [] });
    });
};

export const getPartnershipRequests = (model) => async (dispatch) => {
  dispatch({ type: PARTNERSHIP_REQUEST_FETCH_START });
  let axios = axiosHelper(true);
  const res = await axios
    .get('/request/partnership')
    .then(async (response) => {
      dispatch({ type: PARTNERSHIP_REQUEST_FETCH_DONE });
      if (response.data.isSuccess) {
        dispatch({ type: PARTNERSHIP_REQUEST_FETCH_DONE, partnershipRequests: response.data.result?response.data.result.data : []});
        return {
          success: true,
          messageType: 'success',
          message: 'Partnership request fetch successfully.',
          data: response.data.result?response.data.result.data : []
        };
      }
      return {
        success: false,
        messageType: 'error',
        message: response.data.errors
      };
    })
    .catch((error) => {
      dispatch({ type: PARTNERSHIP_REQUEST_FETCH_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};


export const getIbAccount = () => async (dispatch) => {
  dispatch({ type: FETCH_IB_ACCOUNT_START });
  let axios = axiosHelper(true);
  axios
    .get(`/ib/ib-application`)
    .then(async (response) => {
      const ibAccount = response.data.result;
      dispatch({
        type: FETCH_IB_ACCOUNT_DONE,
        payload: ibAccount,
      });
    })
    .catch((error) => {
      dispatch({ type: FETCH_IB_ACCOUNT_DONE, payload: [] });
    });
};

export const getIbClientAccounts = () => async (dispatch) => {
  dispatch({ type: FETCH_IB_CLIENT_ACCOUNT_START });
  let axios = axiosHelper(true);
  axios
    .get(`/ib/live-applications`)
    .then(async (response) => {
      const ibClientAccount = response.data.result;
      dispatch({
        type: FETCH_IB_CLIENT_ACCOUNT_DONE,
        payload: ibClientAccount,
      });
    })
    .catch((error) => {
      dispatch({ type: FETCH_IB_CLIENT_ACCOUNT_DONE, payload: [] });
    });
};

export const submitClientAgreement = (structureId, body) => async (dispatch) => {
  dispatch({ type: SUBMIT_AGREEMENT_START });
  let axios = axiosHelper2(true);
  return axios
    .post(`/ib/agreement/${structureId}`, body)
    .then(async (response) => {
      const { result, errors } = response.data;
      if(errors) {
        dispatch({
          type: SUBMIT_AGREEMENT_DONE,
          payload: result,
        });
        return {
          isError: true,
          msg: errors,
        };
      }
      dispatch({
        type: SUBMIT_AGREEMENT_DONE,
        payload: result,
      });
      return {
        isError: false,
        msg: 'Agrement submit Successfull.',
      };
    })
    .catch((error) => {
      dispatch({ type: SUBMIT_AGREEMENT_DONE, payload: false });
      return {
        isError: true,
        msg: 'Failed to Submit'
      };
    });
};

export default function reducer(
  state = {
    partnershipRequestLoading: false,
    partnershipRequests: [],
    structuresLoading: false,
    structures:[],
    accountsLoading: false,
    ibAccount: {},
    clientAccounts: [],
  },
  action
) {
  switch (action.type) {
    case PARTNERSHIP_REQUEST_START:
      return {
        ...state,
        partnershipRequestsLoading: true
      };
    case PARTNERSHIP_REQUEST_DONE:
      return {
        ...state,
        partnershipRequestsLoading: false
      };
    case PARTNERSHIP_REQUEST_FETCH_START:
      return {
        ...state,
        partnershipRequests: action.partnershipRequests || [],
        partnershipRequestsLoading: true,
      };
    case PARTNERSHIP_REQUEST_FETCH_DONE:
      return {
        ...state,
        partnershipRequests: action.partnershipRequests || [],
        partnershipRequestsLoading: false,
      };
    case FETCH_STRUCTURES_START:
      return {
        ...state,
        structuresLoading: true,
      };
    case FETCH_STRUCTURES_DONE:
      return {
        ...state,
        structuresLoading: false,
        structures: action.payload,
      };
    case FETCH_IB_ACCOUNT_START:
    case FETCH_IB_CLIENT_ACCOUNT_START:
      return {
        ...state,
        accountsLoading: true,
      };
    case FETCH_IB_ACCOUNT_DONE:
      return {
        ...state,
        accountsLoading: false,
        ibAccount: action.payload,
      };
    case FETCH_IB_CLIENT_ACCOUNT_DONE:
      return {
        ...state,
        accountsLoading: false,
        clientAccounts: action.payload,
      };
    case SUBMIT_AGREEMENT_START:
      return {
        ...state,
        agreementSubmiting: true,
      };
    case SUBMIT_AGREEMENT_DONE:
      return {
        ...state,
        agreementSubmiting: false,
        agreementSubmitResponse: action.payload,
      };
    default:
      break;
  }
  return state;
}
