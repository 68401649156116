import Cookies from 'helpers/cookies';
import { GoldEraAuthToken, GoldEraUser } from './constants';

export default class AuthHelper {
  static isLoggedIn() {
    let token = AuthHelper.token;
    if (!token || token === '') return false;
    return true;
  }

  static logout(history) {
    AuthHelper.token = '';
    AuthHelper.user = '';
    // history.push('/');
    window.location='/'
    
  }

  static getUser() {
    return AuthHelper.user;
  }

  static get token() {
    return localStorage.getItem('userToken')
    return Cookies.get(GoldEraAuthToken);
  }

  static set token(token) {
    // if (!token || token === '') {
    //   Cookies.set(GoldEraAuthToken, token, { expires: new Date() });
    // } else {
    //   var oneHr = new Date(new Date().getTime() + 60 * 60 * 1000);
    //   Cookies.set(GoldEraAuthToken, token, { expires: oneHr });
    // }
    localStorage.setItem('userToken', token)
    return true;
  }

  static get user() {
    const user = localStorage.getItem('currentUser') || "{}";
    return JSON.parse(user)
    // let user = Cookies.get(GoldEraUser);
    // if (user === '') return {};
    // else return JSON.parse(user);
  }

  static set user(user) {
    localStorage.setItem('currentUser', JSON.stringify(user))
    // if (!user || user === '') {
    //   Cookies.set(GoldEraUser, user, { expires: new Date() });
    // } else {
    //   var oneHr = new Date(new Date().getTime() + 60 * 60 * 1000);
    //   Cookies.set(GoldEraUser, user, { expires: oneHr });
    //   return true;
    // }
    return false;
  }
}
