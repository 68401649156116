import axiosHelper from '../helpers/axiosHelper';
import axiosHelper2 from '../helpers/axiosHelper2';
import AuthHelper from '../helpers/authHelper';
import * as qs from 'querystring';

// Login
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_DONE = 'LOGIN_DONE';
export const CHANGE_PASSWORD_START = 'CHANGE_PASSWORD_START';
export const CHANGE_PASSWORD_DONE = 'CHANGE_PASSWORD_DONE';
export const LOAD_AUTH_USER = 'LOAD_AUTH_USER';

export const FORGOTPASS_START = 'FORGOTPASS_START';
export const FORGOTPASS_DONE = 'FORGOTPASS_DONE';

export const LOCATE_ME_START = 'LOCATE_ME_START';
export const LOCATE_ME_DONE = 'LOCATE_ME_DONE';

export const GET_CLIENTS_START = 'GET_CLIENTS_START';
export const GET_CLIENTS_DONE = 'GET_CLIENTS_DONE';
export const AUTH_START = 'AUTH_START';
export const AUTH_DONE = 'AUTH_DONE';

//Login user
export const authUser = (model) => async (dispatch) => {
  dispatch({ type: LOGIN_START });
  let axios = axiosHelper(false);
  let user = {};
  let customerJourney = [];

  const res = await axios
    .post('/auth/login', qs.stringify(model))
    .then(async (response) => {
      dispatch({ type: LOGIN_DONE });
      if (response.data.isSuccess) {
        AuthHelper.token = response.data.result.token;
        axios = axiosHelper(true);
        await axios.get('/auth/profile').then((result) => {
          user = result.data.result.data;
          AuthHelper.user = user;

          axios.get(`/my/journey-status/${user._id}`).then((result) => {
            customerJourney = result.data.result;
            dispatch({
              type: LOAD_AUTH_USER,
              payload: { journey_status: customerJourney }
            });
          });

          dispatch({ type: LOAD_AUTH_USER, payload: { user: user } });
        });

        return {
          success: true,
          messageType: 'success',
          message: 'Logged in successfully.'
        };
      }
      return {
        success: false,
        messageType: 'error',
        message: response.data.message
      };
    })
    .catch((error) => {
      dispatch({ type: LOGIN_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

//Authorize token
export const authToken = (model) => async (dispatch) => {
  dispatch({ type: AUTH_START });
  let axios = axiosHelper(false);
  let user = {};
  let customerJourney = [];

  const res = await axios
    .post('/gate/auth', qs.stringify(model))
    .then(async (response) => {
      dispatch({ type: AUTH_DONE });
      if (response.data.isSuccess) {
        AuthHelper.token = response.data.result.token;
        axios = axiosHelper(true);
        await axios.get('/auth/profile').then((result) => {
          user = result.data.result.data;
          AuthHelper.user = user;

          axios.get(`/my/journey-status/${user._id}`).then((result) => {
            customerJourney = result.data.result;
            dispatch({
              type: LOAD_AUTH_USER,
              payload: { journey_status: customerJourney }
            });
          });

          dispatch({ type: LOAD_AUTH_USER, payload: { user: user } });
        });

        return {
          success: true,
          messageType: 'success',
          message: 'Logged in successfully.'
        };
      }
      return {
        success: false,
        messageType: 'error',
        message: response.data.message
      };
    })
    .catch((error) => {
      dispatch({ type: AUTH_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

export const getDefaultJourney = (user) => async (dispatch) => {
  dispatch({ type: LOAD_AUTH_USER });
  let axios = axiosHelper(true);
  const res = await axios
    .get(`/my/journey-status`)
    .then(async (response) => {
      const customerJourney = response.data.result;
      dispatch({
        type: LOAD_AUTH_USER,
        payload: { journey_status: customerJourney }
      });
      if (response.data.isSuccess) {
        return {
          success: true,
          messageType: 'success',
          message: ''
        };
      }
      return {
        success: false,
        messageType: 'error',
        message: response.data.message
      };
    })
    .catch((error) => {
      dispatch({ type: LOAD_AUTH_USER });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

export const getRefreshToken = () => async (dispatch) => {
  const axios = axiosHelper(true);
  const res = await axios.post('/auth/refresh').then(async (response) => {
    if (response.data.isSuccess) {
      AuthHelper.token = response.data.result.token;
    }
  });
  return res;
};

export const logOut = (history) => {
  AuthHelper.token = '';
  AuthHelper.user = '';
  history.push('/');
};

//Forgot password
export const forgotPassword = (model) => async (dispatch) => {
  dispatch({ type: FORGOTPASS_START });
  let axios = axiosHelper(true);
  const res = await axios
    .post('/customers/forgot-password', qs.stringify(model))
    .then(async (response) => {
      dispatch({ type: FORGOTPASS_DONE });
      if (response.data.isSuccess && !response.data.errors) {
        return {
          success: true,
          messageType: 'success',
          message: 'Password reset link sent to your email.'
        };
      }
      return {
        success: false,
        messageType: 'error',
        message: response.data.errors
      };
    })
    .catch((error) => {
      dispatch({ type: FORGOTPASS_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

//Change password
export const changePassword = (model) => async (dispatch) => {
  dispatch({ type: CHANGE_PASSWORD_START });
  let axios = axiosHelper(true);
  const res = await axios
    .post('/my/change-password', qs.stringify(model))
    .then(async (response) => {
      dispatch({ type: CHANGE_PASSWORD_DONE });
      if (response.data.isSuccess && !response.data.errors) {
        return {
          success: true,
          messageType: 'success',
          message: 'Password changed successfully.'
        };
      }
      return {
        success: false,
        messageType: 'error',
        message: response.data.errors
      };
    })
    .catch((error) => {
      dispatch({ type: CHANGE_PASSWORD_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

export const getClients = (id, limit, page = 1) => async (dispatch) => {
  dispatch({ type: GET_CLIENTS_START });
  let axios = axiosHelper(true);
  const res = await axios
    .get(`/customers/my-clients/${id}?limit=${limit}&page=${page}`)
    .then(async (response) => {
      dispatch({ type: GET_CLIENTS_DONE, payload: response.data.result });
      if (response.data.isSuccess) {
        return {
          success: true,
          messageType: 'success',
          message: ''
        };
      }
      return {
        success: false,
        messageType: 'error',
        message: response.data.message
      };
    })
    .catch((error) => {
      dispatch({ type: GET_CLIENTS_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

//----------------------------------------------
export const generatePin = async (params) => {
  let axios = axiosHelper(true);

  try {
    const resp = await axios.post(
      '/customers/create-pin',
      qs.stringify(params)
    );

    return resp.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const verifyPin = async (params) => {
  let axios = axiosHelper(true);
  try {
    const resp = await axios.post(
      '/customers/verify-register-pin',
      qs.stringify(params)
    );

    return resp.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const registerUser = async (params) => {
  let axios = axiosHelper2(false);
  try {
    const resp = await axios.post('/customers/demo', params);

    return resp.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getSales = async (params) => {
  let axios = axiosHelper2(false);
  try {
    const resp = await axios.get('/customers/sales', params);
    return resp.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const registerLead = async (params) => {
  let axios = axiosHelper2(false);
  try {
    const resp = await axios.post('/customers/lead', params);
    return resp.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const registerWebinar = async (params) => {
  let axios = axiosHelper2(false);
  try {
    const resp = await axios.post('/customers/webinar', params);
    return resp.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const registerLiveUser = async (params) => {
  let axios = axiosHelper2(false, '');
  try {
    const resp = await axios.post('/customers/live', params);

    return resp.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const registerIb = async (params) => {
  params.type = 'individual';
  let axios = axiosHelper2(false, '');
  try {
    const resp = await axios.post('/customers/ib', params);

    return resp.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const isEmailAvailable = async (params) => {
  let axios = axiosHelper(true);
  try {
    const resp = await axios.post(
      '/customers/email-availability',
      qs.stringify(params)
    );
    return resp.data;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const locateMe = () => async (dispatch) => {
  dispatch({ type: LOCATE_ME_START });
  let axios = axiosHelper(false);
  let user = {};

  const res = await axios
    .get('/auth/locate')
    .then(async (response) => {
      dispatch({ type: LOCATE_ME_DONE });
      if (response.data.isSuccess) {
        return response.data.result;
      }
      return {
        success: false,
        messageType: 'error',
        message: response.data.message
      };
    })
    .catch((error) => {
      dispatch({ type: LOGIN_DONE });
      let message = 'Invalid request.';
      if (error.response && error.response.data)
        message = error.response.data.message;
      return { success: false, messageType: 'error', message: message };
    });
  return res;
};

export const getMyProfile = () => async (dispatch) => {
  const axios = axiosHelper(true);
  await axios.get('/auth/profile').then((result) => {
    const user = result.data.result.data;
    AuthHelper.user = user;
    dispatch({ type: LOAD_AUTH_USER, payload: { user: user } });
  });
};

//-----------------------------------------

export default function reducer(
  state = {
    loginLoading: false,
    changePassLoading: false,
    user: {},
    journey_status: [],
    clients: [],
    clientsLoading: false
  },
  action
) {
  switch (action.type) {
    // Sidebar

    case LOGIN_START:
    case FORGOTPASS_START:
    case AUTH_START:
      return {
        ...state,
        loginLoading: true
      };
    case LOGIN_DONE:
    case FORGOTPASS_DONE:
    case AUTH_DONE:
      return {
        ...state,
        loginLoading: false
      };
    case LOCATE_ME_START:
      return {
        ...state,
        locateMeLoading: true
      };
    case LOCATE_ME_DONE:
      return {
        ...state,
        locateMeLoading: false
      };
    case LOAD_AUTH_USER:
      return {
        ...state,
        ...action.payload
      };
    case CHANGE_PASSWORD_START:
      return {
        ...state,
        changePassLoading: true
      };
    case CHANGE_PASSWORD_DONE:
      return {
        ...state,
        changePassLoading: false
      };

    case GET_CLIENTS_START:
      return {
        ...state,
        getClientsLoading: true
      };
    case GET_CLIENTS_DONE:
      return {
        ...state,
        getClientsLoading: false,
        clients: action.payload
      };
    default:
      break;
  }
  return state;
}
