import React, { lazy, Suspense, useState, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { useDispatch, connect } from 'react-redux';
import { AnimatePresence, motion } from 'framer-motion';
import { ClimbingBoxLoader } from 'react-spinners';
import { ThemeProvider } from '@material-ui/styles';
import MuiTheme from './theme';
import AuthHelper from './helpers/authHelper';
import { Grid } from '@material-ui/core';
import { Helmet } from 'react-helmet';
import { NamespacesConsumer } from 'react-i18next';
import IdleTimer from 'react-idle-timer';
// Layout Blueprints
import { setMobile } from 'reducers/Configs';

import { LeftSidebar, MinimalLayout } from './layout-blueprints';

// Session Timeout
import IdleDialog from 'components/IdleDialog';
import { getRefreshToken } from 'reducers/Auth';
// Example Pages

import Gate from './pages/Gate';
import Login from './pages/Login';
import RegisterDemo from './pages/Register/Demo';
import RegisterLive from './pages/Register/Live';
import RegisterIb from './pages/Register/Ib';

const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const RegisterLead = lazy(() => import('./pages/Register/Lead'));
const RegisterLeadInd = lazy(() => import('./pages/Register/LeadInd'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const LiveAccounts = lazy(() => import('./pages/LiveAccounts'));
const DemoAccounts = lazy(() => import('./pages/DemoAccounts'));
const BankAccounts = lazy(() => import('./pages/BankAccounts'));
const Activities = lazy(() => import('./pages/Activities'));
const UserProfile = lazy(() => import('./pages/UserProfile'));
const InternalTransfer = lazy(() => import('./pages/InternalTransfer'));
const Reports = lazy(() => import('./pages/Reports'));
const MyDocuments = lazy(() => import('./pages/MyDocuments'));
const MyApplications = lazy(() => import('./pages/MyApplications'));
const Platforms = lazy(() => import('./pages/Platforms'));
const OpenNewAccount = lazy(() => import('./pages/OpenNewAccount'));
const MT5ChangePassword = lazy(() => import('./pages/MT5ChangePassword'));
const Partnership = lazy(() => import('./pages/Partnership'));
const OpenTradingAccount = lazy(() => import('./pages/OpenTradingAccount'));
const Withdrawal = lazy(() => import('./pages/Withdrawal'));
const Deposit = lazy(() => import('./pages/Deposit'));
const VerifyAccount = lazy(() => import('./pages/VerifyAccount'));
const ExtraComponents = lazy(() => import('./pages/ExtraComponents'));
const AccountStatement = lazy(() => import('./pages/AccountStatement'));
const Page404 = lazy(() => import('./pages/Page404'));
const WebTrader = lazy(() => import('./pages/WebTrader'));
const DashboardButtons = lazy(() =>
  import('./components/DashboardButtons/DashboardButtons')
);
const PortalSwitch = lazy(() =>
  import('./components/PortalSwitch/PortalSwitch')
);
const ResetPassword = lazy(() => import('pages/Reset-password/ResetPassword'));
const CampaignUnsubscribe = lazy(() => import('pages/campaignUnsubscribe'));

const Routes = (props) => {
  const location = useLocation();

  const pageVariants = {
    initial: {
      opacity: 0
    },
    in: {
      opacity: 1
    },
    out: {
      opacity: 0
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'linear',
    duration: 0.3
  };

  const PublicRoute = ({ component: Component, restricted, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) =>
          AuthHelper.isLoggedIn() && restricted ? (
            <Redirect to="/Dashboard" />
          ) : (
            <Component {...props} />
          )
        }
      />
    );
  };

  const PrivateRoute = ({ component: Component, ...rest }) => {
    return (
      <Route
        {...rest}
        render={(props) =>
          AuthHelper.isLoggedIn() ? (
            <Grid container spacing={0}>
              <Grid item sm={12}>
                <PortalSwitch {...props}/>
              </Grid>
              <Grid item xl={1}></Grid>
              <Grid item xl={10} lg={12} md={12}>
                <DashboardButtons {...props} />
                <Component {...props} />
              </Grid>
            </Grid>
          ) : (
            <Redirect to="/Login" />
          )
        }
      />
    );
  };

  const SuspenseLoading = () => {
    const [show, setShow] = useState(false);
    useEffect(() => {
      let timeout = setTimeout(() => setShow(true), 300);
      return () => {
        clearTimeout(timeout);
      };
    }, []);

    return (
      <>
        <AnimatePresence>
          {show && (
            <motion.div
              key="loading"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.1 }}>
              <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
                <div className="d-flex align-items-center flex-column px-4">
                  <ClimbingBoxLoader color={'#3c44b1'} loading={true} />
                </div>
                <div className="text-muted font-size-xl text-center pt-3">
                  Please wait while we load the preview.
                  {/* <span className="font-size-lg d-block text-dark">
                    This live preview instance can be slower than a real
                    production build!
                  </span> */}
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </>
    );
  };

  const [show, setShow] = useState(false);
  const [logoutTimer, setLogoutTimer] = useState(null);
  const [idleTimer, setIdleTimer] = useState(null);
  const [activeCalls, setActiveCalls] = useState(null);
  const dispatch = useDispatch();
  const onIdle = () => {
    setShow(true);
    const lt = setTimeout(() => {
      handleLogout();
    }, 1000 * 11 * 1); // 10 seconds
    setLogoutTimer(lt);
  };

  const onActive = () => {
    if (AuthHelper.isLoggedIn()) {
      dispatch(getRefreshToken());
    } else {
      if (activeCalls) clearInterval(activeCalls);
      setActiveCalls(null);
    }
  };

  const onTabFocus = () => {
    if (idleTimer) {
      idleTimer.reset();
    }
    onActive();
    setShow(false);
  }

  const handleStayLoggedIn = () => {
    if (logoutTimer) {
      clearTimeout(logoutTimer);
      setLogoutTimer(null);
    }
    idleTimer.reset();
    onActive();
    setShow(false);
  };

  const handleLogout = () => {
    AuthHelper.logout();
    setShow(false);
  };

  useEffect(() => {
    if (!show) {
      const ac = setInterval(() => onActive(), 1000 * 60 * 2);
      setActiveCalls(ac);
    } else {
      if (activeCalls) clearInterval(activeCalls);
      setActiveCalls(null);
    }
  }, [show]);

  useEffect(() => {
    window.addEventListener('focus', onTabFocus);
    return () => {
      window.removeEventListener('focus', onTabFocus);
    }
  });

  // useEffect(() => {
  //   window.addEventListener('message', (message) => {
  //     console.log('testing modile date', message)
  //     if (message.data === 'mobile') props.setMobile();
  //   });
  // }, [])

  return (
    <ThemeProvider theme={MuiTheme}>
      {AuthHelper.isLoggedIn() && (
        <NamespacesConsumer>
          {(t) => (
            <>
              <IdleTimer
                ref={(ref) => setIdleTimer(ref)}
                element={document}
                stopOnIdle={true}
                onIdle={onIdle}
                timeout={1000 * 60 * 2} // 2 minutes
                crossTab={{
                  emitOnAllTabs: true
                }}
              />
              <IdleDialog
                show={show}
                title={t('Session Timeout')}
                content={t(
                  'Your session is about to expire in 10 seconds due to inactivity. You will be redirected to the login page'
                )}
                buttonLabel={t('Stay Logged In')}
                handleStayLoggedIn={handleStayLoggedIn}
              />
            </>
          )}
        </NamespacesConsumer>
      )}
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading />}>
          <Switch>
            <Redirect exact from="/" to="/Dashboard" />
            <Route
              path={[
                '/Dashboard',
                '/Live-Accounts',
                '/Demo-Accounts',
                '/Bank-Accounts',
                '/Activities',
                '/User-Profile',
                '/Internal-Transfer',
                '/Reports',
                '/My-Applications',
                '/My-Documents',
                '/Platforms',
                '/Web-Trader',
                '/Open-New-Account',
                '/Change-Password',
                '/Partnership',
                '/OpenTradingAccount',
                '/Withdrawal',
                '/Deposit',
                '/Extra-Components',
                '/Verify-Account',
                '/Account-Statement'
              ]}>
              <LeftSidebar>
                <Helmet>
                  <script src="https://trade.mql5.com/trade/widget.js"></script>
                </Helmet>

                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <PrivateRoute path="/Dashboard" component={Dashboard} />
                    <PrivateRoute
                      path="/Live-Accounts"
                      component={LiveAccounts}
                    />
                    <PrivateRoute
                      path="/Demo-Accounts"
                      component={DemoAccounts}
                    />
                    <PrivateRoute
                      path="/Bank-Accounts"
                      component={BankAccounts}
                    />
                    <PrivateRoute path="/Activities" component={Activities} />
                    <PrivateRoute
                      path="/User-Profile"
                      component={UserProfile}
                    />
                    <PrivateRoute
                      path="/Internal-Transfer"
                      component={InternalTransfer}
                    />
                    <PrivateRoute path="/Reports" component={Reports} />
                    <PrivateRoute
                      path="/My-Documents"
                      component={MyDocuments}
                    />
                    <PrivateRoute
                      path="/My-Applications"
                      component={MyApplications}
                    />
                    <PrivateRoute
                      path="/Open-New-Account"
                      component={OpenNewAccount}
                    />
                    <PrivateRoute
                      path="/Change-Password"
                      component={MT5ChangePassword}
                    />
                    <PrivateRoute
                      path="/Extra-Components"
                      component={ExtraComponents}
                    />
                    <PrivateRoute
                      path="/Verify-Account"
                      component={VerifyAccount}
                    />
                    <PrivateRoute
                      path="/Account-Statement"
                      component={AccountStatement}
                    />
                    <PrivateRoute path="/Web-Trader" component={WebTrader} />
                    <PrivateRoute path="/Partnership" component={Partnership} />
                    <PrivateRoute
                      path="/OpenTradingAccount"
                      component={OpenTradingAccount}
                    />
                    <PrivateRoute path="/Withdrawal" component={Withdrawal} />
                    <PrivateRoute path="/Deposit" component={Deposit} />
                    <PrivateRoute path="/Platforms" component={Platforms} />
                  </motion.div>
                </Switch>
              </LeftSidebar>
            </Route>

            <Route
              path={[
                '/Gate',
                '/Login',
                '/ForgotPassword',
                '/reset-password',
                '/RegisterDemo',
                '/RegisterLive',
                '/RegisterIb',
                '/RegisterLead',
                '/RegisterLeadInd',
                '/Page404',
                '/unsubscribe-email/:emailId'
              ]}>
              <MinimalLayout>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <PublicRoute
                      path="/Gate"
                      restricted={true}
                      component={Gate}
                    />
                    <PublicRoute
                      path="/Login"
                      restricted={true}
                      component={Login}
                    />
                    <PublicRoute
                      path="/ForgotPassword"
                      restricted={true}
                      component={ForgotPassword}
                    />
                    <PublicRoute
                      path="/reset-password"
                      component={ResetPassword}
                    />
                    <Route
                      path="/RegisterDemo"
                      restricted={true}
                      component={RegisterDemo}
                    />
                    <Route
                      path="/RegisterLive"
                      restricted={true}
                      component={RegisterLive}
                    />
                    <Route
                      path="/RegisterIb"
                      restricted={true}
                      component={RegisterIb}
                    />
                    <Route
                      path="/RegisterLead"
                      restricted={true}
                      component={RegisterLead}
                    />
                    <Route
                      path="/RegisterLeadInd"
                      restricted={true}
                      component={RegisterLeadInd}
                    />
                    <Route path="/Page404" component={Page404} />

                    <PublicRoute
                      path="/unsubscribe-email/:emailId"
                      component={CampaignUnsubscribe}
                    />
                  </motion.div>
                </Switch>
              </MinimalLayout>
            </Route>
          </Switch>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  );
};

export default connect(null, {
  setMobile,
})(Routes);