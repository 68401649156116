import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

const lang = localStorage.getItem('lang')
const direction = lang === 'ar-ae' || lang === 'fa' || lang === 'so' ? 'rtl' : 'ltr';
const textAlign = direction === 'rtl' ? 'right' : 'left';
document.body.setAttribute('dir', direction);
document.body.style.textAlign = textAlign;
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
