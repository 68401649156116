import React, { useState, useEffect } from 'react';

import clsx from 'clsx';

import { Collapse } from '@material-ui/core';

import { connect, useSelector } from 'react-redux';

import { NavLink } from 'react-router-dom';
import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';
import {
  gotNewNotification,
  addNotifications
} from '../../reducers/Notification';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { withNamespaces } from 'react-i18next';

import { CircularProgress } from '@material-ui/core';
import ChevronRightTwoToneIcon from '@material-ui/icons/ChevronRightTwoTone';

// import { SidebarWidget } from '../../layout-components';
import { getCustomerType } from 'utils/utilities'

import { ReactComponent as AccountsSvg } from '../../assets/images/menu-icons/ACCOUNTS.svg';
import { ReactComponent as DashboardSvg } from '../../assets/images/menu-icons/DASHBOARD.svg';
import { ReactComponent as FundsSvg } from '../../assets/images/menu-icons/FUNDS.svg';
import { ReactComponent as WithdrawSvg } from '../../assets/images/menu-icons/WITHDRAW.svg';
import { ReactComponent as TransferSvg } from '../../assets/images/menu-icons/TRANSFERS.svg';
import { ReactComponent as MyProfileSVG } from '../../assets/images/menu-icons/MY PROFILE.svg';
import { ReactComponent as PartnershipSVG } from '../../assets/images/menu-icons/PARTNERSHIP.svg';
import { ReactComponent as PlatformsSVG } from '../../assets/images/menu-icons/PLATFORMS.svg';
import { ReactComponent as WebTraderSVG } from '../../assets/images/menu-icons/WEBTRADER.svg';
import { ReactComponent as ReportsSVG } from '../../assets/images/menu-icons/REPORTS.svg';
// import { Settings } from 'example-pages';
import { getProfile, getBonusRequests } from '../../reducers/User';
import { getPartnershipRequests } from '../../reducers/Partnership';
import { initSocket, startFunctions } from '../../websockets';

import AccountDisabled from './AccountDisabled'

function useAsyncState(initialValue) {
  const [value, setValue] = useState(initialValue);
  const setter = (x) =>
    new Promise((resolve) => {
      setValue(x);
      resolve(x);
    });
  return [value, setter];
}

const SidebarMenu = (props) => {
  const [isPartner, setIsPartner] = React.useState(false)
  const [clientTypeVal, setClientTypeVal] = React.useState({})
  const t = props.t;
  const { setSidebarToggleMobile } = props;
  const currentUser = useSelector((state) => state.User.profile);
  const currentPortal = useSelector((state) => state.User.portal);
  const isIBPortal = currentPortal === 'IB';
  let socket = initSocket();

  useEffect(() => {
    (async () => {
      await props.getProfile();
      await props.getBonusRequests();
    })();
  }, []);
  useEffect(() => {
    if (currentUser && currentUser._id) {
      if (currentUser.clientType === 2) {
        props.getPartnershipRequests();
      }
      socket.emit('join', { customerId: currentUser._id });
      startFunctions(props.gotNewNotification, props.addNotifications);
      const cType = getCustomerType(currentUser.clientType);
      setClientTypeVal(cType)
      setIsPartner(cType.isIb);
    }
  }, [currentUser]);

  const [disabledPortal, setDisablePortal] = useState(false);
  useEffect(() => {
    if (props.partnershipRequests && props.partnershipRequests.length > 0) {
      const rejectedReq = props.partnershipRequests.filter(obj => obj.status === 'Rejected');
      const approvedReq = props.partnershipRequests.filter(obj => obj.status === 'Approved')
      if (rejectedReq.length > 0 && approvedReq.length === 0 && currentUser.clientType === 2) {
        setDisablePortal(true);
      }
    }
  }, [props.partnershipRequests])

  const toggleSidebarMobile = () => setSidebarToggleMobile(false);
  const [state, setState] = useAsyncState({
    pages: false,
    accounts: false,
    funds: false,
    profile: false,
    partnership: false
  });

  const togglePages = async (event, name) => {
    event.preventDefault();

    await setState({
      ...state,
      pages: false,
      accounts: false,
      funds: false,
      profile: false,
      partnership: false
    });
    state[name] = !state[name];
    await setState({ ...state });
  };

  return currentPortal ? (
    <>
      <PerfectScrollbar>
        <AccountDisabled open={disabledPortal} />
        <div className="sidebar-navigation">
          {/* <SidebarWidget /> */}

          <ul className="pt-5">
            <li>
              <NavLink
                onClick={toggleSidebarMobile}
                activeClassName="active"
                className="nav-link-simple"
                to="/Dashboard">
                <span className="sidebar-icon">
                  <DashboardSvg />
                </span>
                {t('Dashboard')}
              </NavLink>
            </li>
            {!isIBPortal && (
              <li>
                <a
                  href="#/"
                  onClick={(e) => togglePages(e, 'accounts')}
                  className={clsx({ active: state.accounts })}>
                  <span className="sidebar-icon">
                    <AccountsSvg />
                  </span>
                  <span className="sidebar-item-label">{t('Accounts')}</span>
                  <span className="sidebar-icon-indicator">
                    <ChevronRightTwoToneIcon />
                  </span>
                </a>
                <Collapse in={state.accounts}>
                  <ul>
                    <li>
                      <NavLink
                        onClick={toggleSidebarMobile}
                        to="/Live-Accounts">
                        {t('Live Accounts')}
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        onClick={toggleSidebarMobile}
                        to="/Demo-Accounts">
                        {t('Demo Accounts')}
                      </NavLink>
                    </li>
                    {/* <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Open-New-Account">
                      Open New Account
                    </NavLink>
                  </li> */}
                    <li>
                      <NavLink
                        onClick={toggleSidebarMobile}
                        to="/Change-Password">
                        {t('Change Password')}
                      </NavLink>
                    </li>
                    {/* <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/PageRecoverCover">
                      Partnership Request
                    </NavLink>
                  </li> */}
                    {/* <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Account-Statement">
                      {t('Account Statement')}
                    </NavLink>
                  </li> */}
                  </ul>
                </Collapse>
              </li>
            )}
            {/* <li>
              <a
                href="#/"
                onClick={(e) => togglePages(e, 'funds')}
                className={clsx({ active: state.funds })}>
                <span className="sidebar-icon">
                  <FundsSvg />
                </span>
                <span className="sidebar-item-label">Funds</span>
                <span className="sidebar-icon-indicator">
                  <ChevronRightTwoToneIcon />
                </span>
              </a>
              <Collapse in={state.funds}>
                <ul>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} to="/Deposit">
                      Deposit
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} to="/Withdrawal">
                      Withdrawal
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/Internal-Funds-Transfer">
                      Internet Funds Transfer
                    </NavLink>
                  </li>

                </ul>
              </Collapse>
            </li> */}
            {!isIBPortal && (
              <li>
                <NavLink
                  onClick={toggleSidebarMobile}
                  activeClassName="active"
                  className="nav-link-simple"
                  to="/Deposit">
                  <span className="sidebar-icon">
                    <FundsSvg />
                  </span>
                  {t('Deposit')}
                </NavLink>
              </li>
            )}
            <li>
              <NavLink
                onClick={toggleSidebarMobile}
                activeClassName="active"
                className="nav-link-simple"
                to="/Withdrawal">
                <span className="sidebar-icon">
                  <WithdrawSvg />
                </span>
                {t('Withdrawal')}
              </NavLink>
            </li>
            {/* <li>
              <NavLink
                onClick={toggleSidebarMobile}
                activeClassName="active"
                className="nav-link-simple"
                to="/Internal-Transfer">
                <span className="sidebar-icon">
                  <TransferSvg />
                </span>
                {t('Internal Transfer')}
              </NavLink>
            </li> */}
            {!isIBPortal && (
              <>
                <li>
                  <NavLink
                    onClick={toggleSidebarMobile}
                    activeClassName="active"
                    className="nav-link-simple"
                    to="/Reports">
                    <span className="sidebar-icon">
                      <ReportsSVG />
                    </span>
                    {t('Reports')}
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    onClick={toggleSidebarMobile}
                    activeClassName="active"
                    className="nav-link-simple"
                    to="/Platforms">
                    <span className="sidebar-icon">
                      <PlatformsSVG />
                    </span>
                    {t('Platforms')}
                  </NavLink>
                </li>
              </>
            )}
            {isPartner && isIBPortal &&
              <>
                <li>
                  <NavLink
                    to="/Partnership"
                    onClick={(e) => {
                      if (isPartner) return togglePages(e, 'partnership');
                      return toggleSidebarMobile;
                    }}
                    className={clsx({
                      active: state.partnership && isPartner
                    })}>
                    <span className="sidebar-icon">
                      <PartnershipSVG />
                    </span>
                    <span className="sidebar-item-label">{t('Partnership')}</span>
                    {currentUser && isPartner && (
                      <span className="sidebar-icon-indicator">
                        <ChevronRightTwoToneIcon />
                      </span>
                    )}
                  </NavLink>
                  <Collapse in={state.partnership}>
                    <ul>
                      <li>
                        <NavLink
                          onClick={toggleSidebarMobile}
                          to="/Partnership/my-live-clients">
                          {t('My Live Clients')}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={toggleSidebarMobile}
                          to="/Partnership/my-demo-clients">
                          {t('My Demo Clients')}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={toggleSidebarMobile}
                          to="/Partnership/my-dedicated-links">
                          {t('My Dedicated links')}
                        </NavLink>
                      </li>
                      <li>
                        <NavLink
                          onClick={toggleSidebarMobile}
                          to="/Partnership/statement">
                          {t('Statement')}
                        </NavLink>
                      </li>
                    </ul>
                  </Collapse>
                </li>
                {!clientTypeVal.isClient &&
                  <li>
                    <NavLink
                      to="/OpenTradingAccount"
                      onClick={(e) => {
                        return toggleSidebarMobile;
                      }}
                      className={clsx({
                        active: state.partnership && isPartner
                      })}>
                      <span className="sidebar-icon">
                        <MyProfileSVG />
                      </span>
                      <span className="sidebar-item-label">{t('Trading Account')}</span>
                    </NavLink>
                  </li>
                }
                
              </>
            }
            {!isPartner &&
              <li>
                <NavLink
                  to="/Partnership"
                  onClick={(e) => {
                    return toggleSidebarMobile;
                  }}
                  className={clsx({
                    active: state.partnership && isPartner
                  })}>
                  <span className="sidebar-icon">
                    <PartnershipSVG />
                  </span>
                  <span className="sidebar-item-label">{t('Partnership')}</span>

                </NavLink>
              </li>
            }

            {!isIBPortal && (
              <li>
                <NavLink
                  onClick={toggleSidebarMobile}
                  activeClassName="active"
                  className="nav-link-simple"
                  to="/Web-Trader">
                  <span className="sidebar-icon">
                    <WebTraderSVG />
                  </span>
                  {t('Web Trader')}
                </NavLink>
              </li>
            )}
            <li>
              <a
                href="#/"
                onClick={(e) => togglePages(e, 'profile')}
                className={clsx({ active: state.profile })}>
                <span className="sidebar-icon">
                  <MyProfileSVG />
                </span>
                <span className="sidebar-item-label">{t('My Profile')}</span>
                <span className="sidebar-icon-indicator">
                  <ChevronRightTwoToneIcon />
                </span>
              </a>
              <Collapse in={state.profile}>
                <ul>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} to="/User-Profile">
                      {t('User Profile')}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} to="/My-Documents">
                      {t('My Documents')}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/My-Applications">
                      {t('My Applications')}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} to="/Bank-Accounts">
                      {t('Bank Accounts')}
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} to="/Activities">
                      {t('Activities')}
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </li>
            {/* <li>
              <NavLink
                onClick={toggleSidebarMobile}
                activeClassName="active"
                className="nav-link-simple"
                to="/Settings">
                <span className="sidebar-icon">
                  <SettingsSVG />
                </span>
                Settings
              </NavLink>
            </li> */}
            {/* <li>
              <NavLink
                onClick={toggleSidebarMobile}
                activeClassName="active"
                className="nav-link-simple"
                to="/Homepage">
                <span className="sidebar-icon">
                  <EmojiEventsTwoToneIcon />
                </span>
                Homepage
                <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                  <ChevronRightTwoToneIcon />
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={toggleSidebarMobile}
                activeClassName="active"
                className="nav-link-simple"
                to="/Overview">
                <span className="sidebar-icon">
                  <AccountBalanceTwoToneIcon />
                </span>
                Overview
                <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                  <ChevronRightTwoToneIcon />
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={toggleSidebarMobile}
                activeClassName="active"
                className="nav-link-simple"
                to="/Wallets">
                <span className="sidebar-icon">
                  <BusinessCenterTwoToneIcon />
                </span>
                Wallets
                <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                  <ChevronRightTwoToneIcon />
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={toggleSidebarMobile}
                activeClassName="active"
                className="nav-link-simple"
                to="/BuySell">
                <span className="sidebar-icon">
                  <AttachMoneyTwoToneIcon />
                </span>
                Buy / Sell
                <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                  <ChevronRightTwoToneIcon />
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={toggleSidebarMobile}
                activeClassName="active"
                className="nav-link-simple"
                to="/Transactions">
                <span className="sidebar-icon">
                  <FilterListTwoToneIcon />
                </span>
                Transactions
                <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                  <ChevronRightTwoToneIcon />
                </span>
              </NavLink>
            </li>
            <li>
              <NavLink
                onClick={toggleSidebarMobile}
                activeClassName="active"
                className="nav-link-simple"
                to="/Settings">
                <span className="sidebar-icon">
                  <SettingsTwoToneIcon />
                </span>
                Settings
                <span className="sidebar-icon-indicator sidebar-icon-indicator-right">
                  <ChevronRightTwoToneIcon />
                </span>
              </NavLink>
            </li> */}
          </ul>
          {/* <div className="sidebar-header">
            <span>Others</span>
          </div>
          <ul>
            <li>
              <a
                href="#/"
                onClick={(e) => togglePages(e, 'pages')}
                className={clsx({ active: state.pages })}>
                <span className="sidebar-icon">
                  <ViewColumnTwoToneIcon />
                </span>
                <span className="sidebar-item-label">User Pages</span>
                <span className="sidebar-icon-indicator">
                  <ChevronRightTwoToneIcon />
                </span>
              </a>
              <Collapse in={state.pages}>
                <ul>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} to="/Login">
                      Login
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} to="/PageLoginCover">
                      Login
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/PageRegisterCover">
                      Register
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      onClick={toggleSidebarMobile}
                      to="/PageRecoverCover">
                      Recover Password
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} to="/PageError404">
                      Error 404
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </li>
          </ul> */}
        </div>
      </PerfectScrollbar>
    </>
  ) : (
    <div className="d-flex justify-content-center w-100 mt-4">
      <CircularProgress className="journey-loader" />
    </div>
  );
};

const mapStateToProps = (state) => ({
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
  profile: state.User.profile,
  partnershipRequests: state.Partnership.partnershipRequests
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable)),
  gotNewNotification: (data) => dispatch(gotNewNotification(data)),
  addNotifications: (data) => dispatch(addNotifications(data)),
  getProfile: () => dispatch(getProfile()),
  getPartnershipRequests: () => dispatch(getPartnershipRequests()),
  getBonusRequests: () => dispatch(getBonusRequests()),
});

export default withNamespaces()(
  connect(mapStateToProps, mapDispatchToProps)(SidebarMenu)
);
