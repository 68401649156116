import i18n from 'i18next';
import { reactI18nextModule } from 'react-i18next';
import translationEN from './locales/en.json';
import translationAR from './locales/ar.json';
import translationJP from './locales/jp.json';
import translationRU from './locales/ru.json';
import translationES from './locales/es.json';
import translationFA from './locales/fa.json';
import translationZH from './locales/zh.json';
import translationSO from './locales/so.json';

// the translations
const resources = {
  en: {
    translation: translationEN
  },
  ar: {
    translation: translationAR
  },
  jp: {
    translation: translationJP
  },
  ru: {
    translation: translationRU
  },
  es: {
    translation: translationES
  },
  fa: {
    translation: translationFA
  },
  zh: {
    translation: translationZH
  },
  so: {
    translation: translationSO
  },
};

i18n
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources,
    lng: localStorage.getItem('lang') || 'en',
    fallbackLng: 'en',
    // keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;
