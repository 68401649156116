import React from 'react';
import { connect } from 'react-redux';
import { authUser } from '../../reducers/Auth';

import {
  Grid,
  InputAdornment,
  Button,
  TextField,
  CircularProgress,
  SvgIcon,
  fade,
  ThemeProvider,
  withStyles,
  makeStyles,
  createMuiTheme
} from '@material-ui/core';

import Alert from '@material-ui/lab/Alert';
import MailOutlineTwoToneIcon from '@material-ui/icons/MailOutlineTwoTone';
import LockTwoToneIcon from '@material-ui/icons/LockTwoTone';
import { Formik } from 'formik';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';

// import logo from '../../assets/images/login/logo.png';
// import loginBackground from '../../assets/images/login/wave-6.e1fad8d9.png';

import { withNamespaces } from 'react-i18next';

import { WEB_REGISTER_URL } from '../../settings';

import { ReactComponent as FbIcon } from '../../layout-components/Footer/icons/fb.svg';
import { ReactComponent as InIcon } from '../../layout-components/Footer/icons/in.svg';
import { ReactComponent as LnIcon } from '../../layout-components/Footer/icons/ln.svg';
import { ReactComponent as TwIcon } from '../../layout-components/Footer/icons/tw.svg';
import { ReactComponent as YtIcon } from '../../layout-components/Footer/icons/yt.svg';
import { clearJourneyAll } from 'utils/utilities'
import './style.scss';
import  { app_config }  from 'config/app-config'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#1d5b76',
    },
    secondary: {
      main: '#1a76a8',
    },
  },
  overrides: {
    MuiInputBase: {
      input: {
        '&:-webkit-autofill': {
          transitionDelay: '9999s',
          transitionProperty: 'background-color, color'
        }
      }
    }
  }
});
const Login = (props) => {
  const t = props.t;
  const [state, setState] = React.useState({
    message: '',
    showMessage: false,
    messageType: ''
  });

  const authUser = async (model) => {
    setState({
      ...state,
      showMessage: false
    });

    const res = await props.authUser(model);

    setState({
      ...state,
      message: res.message,
      messageType: res.messageType,
      showMessage: true
    });
    if (res.success) {
      clearJourneyAll();
      props.history.push('/dashboard');
    }
  };

  const rtl =
    localStorage.getItem('lang') === 'ar-ae' ||
    localStorage.getItem('lang') === 'fa' ||
    localStorage.getItem('lang') === 'so'
      ? true
      : false;
  return (
    <>
     <div className="app-wrapper min-vh-100 background" style={{ background: `url(${app_config.loginBg})` }}>
        <div className="app-main min-vh-100">
          <div className="app-content p-0">
            <div className="app-content--inner d-flex align-items-center">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content py-5">
                  <Grid item md={10} lg={8} xl={5} className="mx-auto card pt-5 pl-4 pr-4">
                    <div className="text-center">
                      <img alt="..." className="logo" src={app_config.loginLogo} />
                    </div>
                    <div>
                    <div className="card1">
                                <Formik
                                  initialValues={{
                                    email: '',
                                    password: ''
                                  }}
                                  validationSchema={Yup.object({
                                    password: Yup.string()
                                      .min(
                                        6,
                                        t('Must be at least 6 characters')
                                      )
                                      .required(t('Required')),
                                    email: Yup.string()
                                      .email('Invalid email address')
                                      .required(t('Required'))
                                  })}
                                  onSubmit={async (
                                    values,
                                    { setSubmitting }
                                  ) => {
                                    authUser({
                                      email: values.email,
                                      password: values.password
                                    });
                                    setSubmitting(false);
                                  }}>
                                  {({
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit
                                  }) => {
                                    return (
                                      <div className="p-3">
                                        <form onSubmit={handleSubmit}>
                                          <ThemeProvider theme={theme}>
                                            <div className="my-4">
                                              {/* <TextField
                                                id="email"
                                                label="Email"
                                                className={styles.textField}
                                                margin="normal"
                                                InputProps={{
                                                  className: styles.input,
                                                }}
                                              /> */}
                                              <TextField
                                                fullWidth
                                                // variant="outlined"
                                                id="email"
                                                name="email"
                                                label={t('Email address')}
                                                InputProps={{
                                                  style: { color: '#1a76a8' },
                                                  // startAdornment: (
                                                  //   <InputAdornment position="start">
                                                  //     <MailOutlineTwoToneIcon />
                                                  //   </InputAdornment>
                                                  // )
                                                }}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.email}
                                                error={
                                                  touched.email && errors.email
                                                }
                                                helperText={
                                                  touched.email && errors.email
                                                }
                                              />
                                            </div>
                                            <div className="mb-3">
                                              <TextField
                                                fullWidth
                                                // variant="outlined"
                                                id="password"
                                                name="password"
                                                label={t('Password')}
                                                type="password"
                                                InputProps={{
                                                  style: { color: '#1a76a8' },
                                                  // startAdornment: (
                                                  //   <InputAdornment position="start">
                                                  //     <LockTwoToneIcon />
                                                  //   </InputAdornment>
                                                  // )
                                                }}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.password}
                                                error={
                                                  touched.password &&
                                                  errors.password
                                                }
                                                helperText={
                                                  touched.password &&
                                                  errors.password
                                                }
                                              />
                                            </div>
                                          </ThemeProvider>
                                          {state.showMessage && (
                                            <div className="text-center py-4">
                                              <Alert
                                                severity={state.messageType}>
                                                {state.message}
                                              </Alert>
                                            </div>
                                          )}
                                          <div className="text-right pb-4">
                                            <Link to="/ForgotPassword" className="">
                                              {t('Forgot Password')} ?
                                            </Link>
                                          </div>
                                          <div className="text-center w-50 mx-auto">
                                            <Button
                                              disabled={props.Auth.loginLoading}
                                              type="submit"
                                              className="btn-second font-weight-bold my-2 dddd">
                                              {props.Auth.loginLoading ? (
                                                <CircularProgress size={20} />
                                              ) : (
                                                t('Log In')
                                              )}
                                            </Button>
                                          </div>
                                          <div className="text-right mt-3">
                                            <span style={{ color: '#CACACA' }}>
                                              {t('Dont have an account')}
                                            </span>{' '}
                                            <a
                                              href={`/registerlive`}
                                              className="">
                                              {t('Apply for a live account')}
                                            </a>
                                          </div>
                                        </form>
                                      </div>
                                    );
                                  }}
                                </Formik>
                              </div>
                      </div>
                    </Grid>
                </div>
              </div>
            </div>
            {/* <div className="hero-footer pb-4" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                          <div style={{ fontSize: '1.4rem' }}>
                            <a
                              // href="https://www.linkedin.com/company/goldera"
                              href="#"
                              target="_blank"
                              rel="noreferrer"
                              className="text-white-50">
                              <SvgIcon fontSize="inherit">
                                <LnIcon className="text-primary" />
                              </SvgIcon>
                            </a>
                            <a
                              // href="https://www.facebook.com/goldera/"
                              href="#"
                              target="_blank"
                              rel="noreferrer"
                              className="ml-4 text-white-50">
                              <SvgIcon fontSize="inherit">
                                <FbIcon className="text-primary" />
                              </SvgIcon>
                            </a>
                            <a
                              // href="https://twitter.com/goldera"
                              href="#"
                              target="_blank"
                              rel="noreferrer"
                              className="ml-4 text-white-50">
                              <SvgIcon fontSize="inherit">
                                <TwIcon className="text-primary" />
                              </SvgIcon>
                            </a>
                            <a
                              // href="https://www.instagram.com/goldera/"
                              href="#"
                              target="_blank"
                              rel="noreferrer"
                              className="ml-4 text-white-50">
                              <SvgIcon fontSize="inherit">
                                <InIcon className="text-primary" />
                              </SvgIcon>
                            </a>
                            <a
                              // href="https://www.youtube.com/"
                              href="#"
                              target="_blank"
                              rel="noreferrer"
                              className="ml-4 text-white-50">
                              <SvgIcon fontSize="inherit">
                                <YtIcon className="text-primary" />
                              </SvgIcon>
                            </a>
                          </div>
                        </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    Auth: state.Auth
  };
};

//connect with redux and give the access to defined method
export default withNamespaces()(connect(mapStateToProps, { authUser })(Login));
